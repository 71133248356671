import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    input: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#263238'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#263238'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            borderWidth: 1,
            borderColor: '#263238'
        }
    }
});

function Wearable(props) {
    const classes = useStyles();

    return (
        <Grid classes={classes.item} item md={4} sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
                Wearable
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.values.wearableEnable}
                                onChange={() => props.setFieldValue('wearableEnable', !props.values.wearableEnable)}
                            />
                        }
                        label="Enable"
                    />
                    <Box mt={2}>
                        <TextField
                            disabled={!props.values.wearableEnable}
                            label="MAC address"
                            name="mac"
                            type="text"
                            value={props.values.wearableMac}
                            variant="outlined"
                            size="small"
                            classes={classes.input}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </Box>
                </>
            )}
        </Grid>
    );
}

Wearable.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default Wearable;
