import React from 'react';
import UserModel from '../../dataaccess/models/user';
import AccountViewModel from '../../presentation/viewModels/AccountViewModel';
import AccountController from '../../presentation/controllers/AccountController';

/**
 * Account Provider - Connects all de MVVM logic together
 * @returns Account Controller
 */
function AccountProvider() {
    // instantiate account view model with user model
    const accountViewModel = new AccountViewModel(new UserModel());

    return <AccountController viewModel={accountViewModel} />;
}

export default AccountProvider;
