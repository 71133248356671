import React, { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
    Button,
    IconButton,
    Tooltip,
    Dialog,
    Typography,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import { Memory, Send } from '@mui/icons-material';

const defaultToolbarSelectStyles = {
    iconContainer: {
        marginRight: '24px'
    }
};

function CustomToolbarSelect(props) {
    // get props
    const { classes, isAdmin, onChangeFirmwareTarget, getAvailableCommands, sendCommand } = props;

    const [command, setCommand] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [commandsAvailable, setCommandsAvailable] = useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.iconContainer}>
            {isAdmin && (
                <Tooltip title="Update Firmware">
                    <IconButton onClick={onChangeFirmwareTarget}>
                        <Memory />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title="Send Management Commands">
                <IconButton
                    onClick={async (e) => {
                        handleClick(e);
                        // if array empty
                        if (!commandsAvailable.length) {
                            const commands = await getAvailableCommands();
                            setCommandsAvailable(commands);
                        }
                    }}
                >
                    <Send />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 48 * 4.5,
                        width: '25ch'
                    }
                }}
            >
                {commandsAvailable.map((c) => (
                    <MenuItem
                        onClick={(e) => {
                            setOpenDialog(true);
                            handleClose();
                            setCommand(e.target.innerText);
                        }}
                        key={c}
                    >
                        {c}
                    </MenuItem>
                ))}
            </Menu>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4">Send Gateway Command</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to send command?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        No
                    </Button>
                    <Button
                        onClick={async () => {
                            sendCommand(command).then(() => {
                                setOpenDialog(false);
                            });
                        }}
                        color="primary"
                        autoFocus
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CustomToolbarSelect.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    onChangeFirmwareTarget: PropTypes.func.isRequired,
    getAvailableCommands: PropTypes.func.isRequired,
    sendCommand: PropTypes.func.isRequired,
    classes: PropTypes.object
};

export default withStyles(defaultToolbarSelectStyles, { name: 'CustomToolbarSelect' })(CustomToolbarSelect);
