import React from 'react';
import WearableModel from '../../dataaccess/models/wearable';
import ClientModel from '../../dataaccess/models/client';
import WearableClientViewModel from '../../presentation/viewModels/wearable/WearableClientViewModel';
import ClientWearableController from '../../presentation/controllers/wearable/ClientWearableController';

/**
 * Wearable Client Set Provider - Connects all de MVVM logic together
 * @returns Wearable Client Set Controller
 */
function WearableClientProvider() {
    // instantiate wearable client set view model with wearable and client model
    const wearableClientViewModel = new WearableClientViewModel(new WearableModel(), new ClientModel());

    return <ClientWearableController viewModel={wearableClientViewModel} />;
}

export default WearableClientProvider;
