import React from 'react';
import WearableModel from '../../dataaccess/models/wearable';
import AddWearableViewModel from '../../presentation/viewModels/wearable/AddWearableViewModel';
import AddWearableController from '../../presentation/controllers/wearable/AddWearableController';

/**
 * Add Wearable Provider - Connects all de MVVM logic together
 * @returns Add Wearable Controller
 */
function AddWearableProvider() {
    // instantiate add wearable view model with wearable model
    const addWearableViewModel = new AddWearableViewModel(new WearableModel());

    return <AddWearableController viewModel={addWearableViewModel} />;
}

export default AddWearableProvider;
