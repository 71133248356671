/**
 * Wearable List View Model - Producer
 */
class WearableListViewModel {
    constructor(wearableModel) {
        // owns wearable model
        this.wearableModel = wearableModel;
    }

    /**
     * Get wearables
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nWearablesPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Wearable table data
     */
    getWearablesTable(token, user, nWearablesPerCall, pagToken) {
        return this.wearableModel.getWearablesTable(token, user, nWearablesPerCall, pagToken);
    }

    /**
     * Unset the client a wearable belongs to
     * @param {*} wearable Wearable UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    unsetClientWearable(wearable, owner) {
        return this.wearableModel.unsetClientWearable(wearable, owner);
    }
}

export default WearableListViewModel;
