import React from 'react';
import ClientModel from '../../dataaccess/models/client';
import GatewayModel from '../../dataaccess/models/gateway';
import DashboardViewModel from '../../presentation/viewModels/DashboardViewModel';
import DashboardController from '../../presentation/controllers/DashboardController';

/**
 * Dashboard Provider - Connects all de MVVM logic together
 * @returns Dashboard Controller
 */
function DashboardProvider() {
    // instantiate dashboard view model with gateway and client model
    const dashboardViewModel = new DashboardViewModel(new GatewayModel(), new ClientModel());

    return <DashboardController viewModel={dashboardViewModel} />;
}

export default DashboardProvider;
