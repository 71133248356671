import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from '@mui/material';

function Idreams(props) {
    return (
        <Grid item md={4} sm={6} xs={12} direction="column">
            <Typography color="textPrimary" gutterBottom variant="h6">
                i-DREAMS
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.idreamsEnable}
                                    onChange={() => props.setFieldValue('idreamsEnable', !props.values.idreamsEnable)}
                                />
                            }
                            label="Enable"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.idreamsEnable}
                                    checked={props.values.allowVisualwarnings}
                                    onChange={() => props.setFieldValue('allowVisualwarnings', !props.values.allowVisualwarnings)}
                                />
                            }
                            label="Visual warnings"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.idreamsEnable}
                                    checked={props.values.allowAuditoryWarnings}
                                    onChange={() => props.setFieldValue('allowAuditoryWarnings', !props.values.allowAuditoryWarnings)}
                                />
                            }
                            label="Auditory warnings"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.idreamsEnable}
                                    checked={props.values.fmsId}
                                    onChange={() => props.setFieldValue('fmsId', !props.values.fmsId)}
                                />
                            }
                            label="FMS driver identification"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.idreamsEnable}
                                    checked={props.values.defaultUpload}
                                    onChange={() => props.setFieldValue('defaultUpload', !props.values.defaultUpload)}
                                />
                            }
                            label="Upload trips without confirmed driver identification"
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

Idreams.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default Idreams;
