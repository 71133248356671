import React from 'react';
import { Box, Button, Container, IconButton, TextField, Typography } from '@mui/material';
import { forgotPasswordSubmit } from 'aws-auth-cardioid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function ForgotPasswordSubmitView() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { state } = useLocation();
    async function onSubmit(formData, actions) {
        const { email, code, newPassword } = formData;
        forgotPasswordSubmit(email, code, newPassword)
            .then(() => {
                navigate('/login', { replace: true });
            })
            .catch((err) => {
                actions.setFieldError('apiError', err.message);
            });
    }

    return (
        <Page title="Reset Password" className={classes.root}>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: state?.email || '',
                            code: '',
                            newPassword: '',
                            showNewPassword: false
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            code: Yup.string().required('Code is required'),
                            newPassword: Yup.string()
                                .min(8, 'New Password must have at least 8 chars')
                                .matches(/[a-z]/, 'New Password must have at least one lowercase char')
                                .matches(/[A-Z]/, 'New Password must have at least one uppercase char')
                                .matches(/[0-9]/, 'New Password must have at least one number')
                                // eslint-disable-next-line
                .matches(/[!@#$%^&*()_\[\]{};':"\\|,.<>\/?~`]/, 'New Password must have at least one of the following special chars ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `')
                                .required('New Password is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Reset Password
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2">
                                        Update password with the code you received
                                    </Typography>
                                </Box>
                                <Typography align="left" color="error" variant="caption">
                                    {errors.apiError}
                                </Typography>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.code && errors.code)}
                                    fullWidth
                                    helperText={touched.code && errors.code}
                                    label="Code"
                                    margin="normal"
                                    name="code"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.code}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.newPassword && errors.newPassword)}
                                    fullWidth
                                    helperText={touched.newPassword && errors.newPassword}
                                    label="New Password"
                                    margin="normal"
                                    name="newPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={values.showNewPassword ? 'text' : 'password'}
                                    value={values.newPassword}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setFieldValue('showNewPassword', !values.showNewPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )
                                    }}
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Reset password
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

export default ForgotPasswordSubmitView;
