const columns = [
    {
        name: 'uuid',
        label: 'UUID',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'name',
        label: 'Name',
        options: {
            filter: false,
            sort: false
        }
    }
];

export default columns;
