import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

function Toolbar({ className, ...rest }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Restrict User Clients
                    </Typography>
                </Box>
            </Box>
        </div>
    );
}

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
