import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import { addCardioWheel } from '../../../actions/actions';
import AddCardioWheelView from '../../views/cardioWheel/AddWearableView';

/**
 * Add Wearable View Controller - View related logic
 * @returns Add Wearable View
 */
function AddCardioWheelController() {
    // initiate local variables
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();

    /**
     * Add new wearable
     * @param {*} values Form fields
     */
    const onSubmit = async (values) => {
        const { mac, hardware, firmware } = values;
        const { status } = await addCardioWheel(mac, hardware, firmware);
        if (status === 200) {
            enqueueSnackbar(`CardioWheel was successfully added`, { variant: 'success' });
            navigate(location.state || '/', { replace: true });
        } else {
            enqueueSnackbar(`Error adding new cardioWheel`, { variant: 'error' });
            navigate(location.state || '/', { replace: true });
        }
    };

    // return view
    return <AddCardioWheelView onSubmit={onSubmit} />;
}

export default AddCardioWheelController;
