import React from 'react';
import FirmwareModel from '../../dataaccess/models/firmware';
import FirmwareListViewModel from '../../presentation/viewModels/firmware/FirmwareListViewModel';
import FirmwareListController from '../../presentation/controllers/firmware/FirmwareListController';

/**
 * Firmware List Provider - Connects all de MVVM logic together
 * @returns Firmware List Controller
 */
function FirmwareListProvider() {
    // instantiate firmware list view model with firmware model
    const firmwareListViewModel = new FirmwareListViewModel(new FirmwareModel());

    return <FirmwareListController viewModel={firmwareListViewModel} />;
}

export default FirmwareListProvider;
