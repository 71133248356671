import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
// material
import { Box, Card, CardHeader, Divider, Grid, Container, Alert } from '@mui/material';
// components
import Page from 'presentation/components/Page';
// utils
import config from 'utils/config.json';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function TicketView(props) {
    const classes = useStyles();

    // get props
    const { onSubmit, email } = props;

    return (
        <Page className={classes.root} title="Support">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                topic: '',
                                gwUUID: '',
                                userEmail: email,
                                description: ''
                            }}
                            validationSchema={Yup.object().shape({
                                topic: Yup.string()
                                    .oneOf(
                                        config.CRM.topics.map((topic) => topic),
                                        'Topic must be one of the values in the list'
                                    )
                                    .required('Topic is required'),
                                gwUUID: Yup.string().max(50, 'Gateway UUID must be at most 50 characters'),
                                userEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                description: Yup.string()
                                    .max(200, 'Description must be at most 200 characters')
                                    .required('Description is required')
                            })}
                            onSubmit={onSubmit}
                        >
                            {/* {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => ( */}
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader title="Support" />
                                        {/* <CardHeader subheader="Create a ticket" title="Ticket" /> */}
                                        {/* <Divider /> */}
                                        {/* <CardContent> */}
                                        {/*    <Grid container spacing={3}> */}
                                        {/*        <Grid item md={6} xs={12}> */}
                                        {/*            <Autocomplete */}
                                        {/*                id="topic" */}
                                        {/*                options={config.CRM.topics} */}
                                        {/*                autoHighlight */}
                                        {/*                onChange={(e, value) => { */}
                                        {/*                    setFieldValue('topic', value || ''); */}
                                        {/*                }} */}
                                        {/*                value={values.topic} */}
                                        {/*                renderInput={(params) => ( */}
                                        {/*                    <TextField */}
                                        {/*                        {...params} */}
                                        {/*                        error={Boolean(touched.topic && errors.topic)} */}
                                        {/*                        fullWidth */}
                                        {/*                        helperText={touched.topic && errors.topic} */}
                                        {/*                        label="Topic" */}
                                        {/*                        name="topic" */}
                                        {/*                        onBlur={handleBlur} */}
                                        {/*                        value={values.topic} */}
                                        {/*                        variant="outlined" */}
                                        {/*                    /> */}
                                        {/*                )} */}
                                        {/*            /> */}
                                        {/*        </Grid> */}
                                        {/*        <Grid item md={6} xs={12}> */}
                                        {/*            <TextField */}
                                        {/*                error={Boolean(touched.gwUUID && errors.gwUUID)} */}
                                        {/*                fullWidth */}
                                        {/*                helperText={touched.gwUUID && errors.gwUUID} */}
                                        {/*                label="Gateway UUID" */}
                                        {/*                name="gwUUID" */}
                                        {/*                onBlur={handleBlur} */}
                                        {/*                onChange={handleChange} */}
                                        {/*                value={values.gwUUID} */}
                                        {/*                variant="outlined" */}
                                        {/*            /> */}
                                        {/*        </Grid> */}
                                        {/*        <Grid item md={12} xs={12}> */}
                                        {/*            <TextField */}
                                        {/*                error={Boolean(touched.description && errors.description)} */}
                                        {/*                fullWidth */}
                                        {/*                helperText={touched.description && errors.description} */}
                                        {/*                label="Description" */}
                                        {/*                name="description" */}
                                        {/*                placeholder="Please describe your issue as clear and detailed as possible." */}
                                        {/*                onBlur={handleBlur} */}
                                        {/*                onChange={handleChange} */}
                                        {/*                value={values.description} */}
                                        {/*                variant="outlined" */}
                                        {/*                multiline */}
                                        {/*                rowsMax={4} */}
                                        {/*            /> */}
                                        {/*        </Grid> */}
                                        {/*    </Grid> */}
                                        {/* </CardContent> */}
                                        <Divider />
                                        <Box display="flex" p={2}>
                                            <Box flexGrow={2} mr={2}>
                                                <Alert severity="info" variant="outlined">
                                                    CardioID support service is available Monday to Friday, from 9 AM to 5 PM (Lisbon time),
                                                    except on Portuguese national holidays. Contact us via email to support@cardio-id.com
                                                </Alert>
                                            </Box>
                                            {/* <Button color="primary" variant="contained" disabled={isSubmitting} type="submit"> */}
                                            {/*    Create Ticket */}
                                            {/* </Button> */}
                                        </Box>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

TicketView.propTypes = {
    // className: PropTypes.string,
    // controller state variables
    email: PropTypes.string.isRequired,
    // controller functions
    onSubmit: PropTypes.func.isRequired
};

export default TicketView;
