import React from 'react';
import CardioWheelListController from '../../presentation/controllers/cardioWheel/CardioWheelListController';

/**
 * CardioWheel List Provider - Connects all de MVVM logic together
 * @returns CardioWheel List Controller
 */
function CardioWheelListProvider() {
    return <CardioWheelListController />;
}

export default CardioWheelListProvider;
