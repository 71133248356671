/**
 * Intervention List View Model - Producer
 */
class InterventionListViewModel {
    constructor(interventionModel) {
        // owns intervention model
        this.interventionModel = interventionModel;
    }

    /**
     * Get interventions
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @returns Intervention table data
     */
    getInterventionTable(token, user) {
        return this.interventionModel.getInterventionTable(token, user);
    }
}

export default InterventionListViewModel;
