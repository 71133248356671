import React, { useState, useEffect } from 'react';
import { getCurrentSession } from 'aws-auth-cardioid';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
// utils
import config from 'utils/config.json';
import InterventionListView from '../../views/intervention/InterventionListView';
import { getInterventions } from '../../../actions/actions';

/**
 * Intervention List View Controller - View related logi
 * @returns Intervention List View
 */
function InterventionListController() {
    // initiate local variables
    const session = getCurrentSession();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    // state variables
    const [interventions, setInterventions] = useState([]);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [loading, setLoading] = useState(true);

    const getInterventionsTable = async () => {
        const user = session.getIdToken().payload['cognito:username'];
        const { data } = await getInterventions(user);
        if (data) {
            const newInterventions = [
                ...interventions,
                ...data.map((int) => {
                    int.info = int.uuid;
                    return int;
                })
            ];
            setInterventions(newInterventions);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * Custom sort by date
     * @param {*} data Table data
     * @param {*} colIndex Column index
     * @param {*} order Order
     * @returns New data ordered by date
     */
    const handleCustomSort = (data, colIndex, order) => {
        if (colIndex === 0) {
            return data.sort((a, b) => {
                const dateA = new Date(a.data[colIndex]).getTime();
                const dateB = new Date(b.data[colIndex]).getTime();
                return (dateA < dateB ? -1 : 1) * (order === 'desc' ? 1 : -1);
            });
        }
        return data.sort((a, b) => (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1));
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getInterventionsTable().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <InterventionListView
            interventions={interventions}
            rowsPerPageValue={rowsPerPageValue}
            loading={loading}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleCustomSort={handleCustomSort}
        />
    );
}

InterventionListController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default InterventionListController;
