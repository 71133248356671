import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// view
// utils
import config from 'utils/config.json';
import { getCurrentSession } from 'aws-auth-cardioid';
import { getClients, setClientFromGateway } from 'actions/actions';
import GatewayClientView from '../../views/gateway/GatewayClientView';
import decodeJwt from '../../../utils/jwt';

/**
 * Gateway Client Set View Controller - View related logic
 * @returns Gateway Client View
 */
function GatewayClientController() {
    // initiate local variables
    const session = getCurrentSession();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const { uuid } = useParams();
    const nClientsPerCall = config.tables.TABLE_ROWS_PER_CALL;
    // state variables
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);

    /**
     * Get clients
     */
    const getClientsTable = async () => {
        const user = session.getIdToken().payload['cognito:username'];
        const {
            data: { Owners, PaginationToken }
        } = await getClients(user, nClientsPerCall, pagToken);
        if (Owners) {
            const newClients = [...clients, ...Owners.map((client) => client)];
            setClients(newClients);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Owners.length === nClientsPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nClientsPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of clients
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Set client that gateway belongs to
     * @param {*} gw Gateway UUID
     * @param {*} uuid Client UUID
     */
    const setClientGateway = async (gw, uuid) => {
        setClientFromGateway(gw, uuid, true)
            .then((data) => {
                if (data?.status === 200) {
                    enqueueSnackbar(`Gateway client was successfully set`, { variant: 'success' });
                    navigate('/app/gateways');
                } else enqueueSnackbar(`Error setting gateway client`, { variant: 'error' });
            })
            .catch(() => enqueueSnackbar(`Error setting gateway client`, { variant: 'error' }));
    };

    /**
     * Change rows per page
     * @param {*} newRowsPerPage Rows per page number
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * Change page
     * @param {*} currentPage Current page
     */
    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of hosts
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getClientsTable().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    /**
     * On select row - set client
     * @param {*} currentRowsSelected
     * @param {*} allRowsSelected
     * @param {*} rowsSelected
     */
    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        if (rowsSelected.length === 1) setSelectedClient(clients[rowsSelected[0]].uuid);
        else setSelectedClient('');
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getClientsTable().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <GatewayClientView
            uuid={uuid}
            clients={clients}
            selectedClient={selectedClient}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleOnRowSelected={handleOnRowSelected}
            setClientGateway={setClientGateway}
        />
    );
}

export default GatewayClientController;
