import React from 'react';
import PropTypes from 'prop-types';
// material
import {
    ImageList,
    ImageListItem,
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    IconButton
} from '@mui/material';
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import Pagination from '@mui/lab/Pagination';
// components
import { makeStyles } from '@mui/styles';
import ImageInList from '../../../components/intervention/ImageInList';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        overflow: 'hidden',
        marginTop: 30,
        backgroundColor: theme.palette.background.paper
    },
    rootTitle: {
        margin: 0,
        padding: theme.spacing(2)
    },
    imageList: {
        flexWrap: 'nowrap'
    },
    image: {
        // height: '55vh',
        // width: '28.8vw',
        // objectFit: 'cover',
        // height: '100%',
        // width: '100%',
        maxWidth: '90%',
        maxHeight: '80vh',
        minHeight: '70vh'
    },
    closeButton: {
        position: 'absolute',
        // left: '95%',
        // top: '-3%',
        right: theme.spacing(1),
        top: theme.spacing(1),
        backgroundColor: 'lightgray',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: 'lightgray',
            color: theme.palette.primary.main
        }
    },
    beforeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: 'calc(50% - 12px)',
        backgroundColor: 'lightgray',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: 'lightgray',
            color: theme.palette.primary.main
        }
    },
    afterButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: 'calc(50% - 12px)',
        backgroundColor: 'lightgray',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: 'lightgray',
            color: theme.palette.primary.main
        }
    },
    dialogPaper: {
        // allow close button to show outside dialog
        // overflowY: 'unset',
        // height: '100%',
        width: '100%',
        minHeight: '30%'
        // maxHeight: '80vh',
    },
    dialogActions: {
        justifyContent: 'center'
    }
}));

function InterventionImageListView(props) {
    const classes = useStyles();

    const {
        cols,
        rowHeight,
        data,
        images,
        currentImage,
        leftDisable,
        rightDisable,
        open,
        loading,
        fullScreen,
        handleClickOpen,
        handleClose,
        onChangeImage
    } = props;

    return (
        <>
            <Box flexGrow={1}>
                <Typography color="textPrimary" variant="h3" align="center">
                    {data.length > 0 ? 'Images' : 'Intervention has no images'}
                </Typography>
            </Box>

            <Box className={classes.root}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <ImageList rowHeight={rowHeight} gap={6} cols={cols} className={classes.imageList}>
                        {images.map((item, index) => (
                            <ImageListItem key={index} style={{ width: null }}>
                                <ImageInList image={item} height={rowHeight} number={index + 1} handleClickOpen={handleClickOpen} />
                            </ImageListItem>
                        ))}
                    </ImageList>
                )}
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                maxWidth="md"
                fullWidth
                fullScreen={fullScreen}
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle disableTypography className={classes.rootTitle}>
                    {handleClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent
                    style={{
                        // overflow: "hidden",
                        height: fullScreen ? null : '90vh',
                        width: fullScreen ? null : '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <img src={currentImage.image} alt="Intervention" loading="lazy" className={classes.image} />
                </DialogContent>
                {!fullScreen ? (
                    <DialogActions>
                        {!leftDisable && (
                            <IconButton onClick={() => onChangeImage('l')} className={classes.beforeButton}>
                                <NavigateBefore />
                            </IconButton>
                        )}
                        {!rightDisable && (
                            <IconButton onClick={() => onChangeImage('r')} className={classes.afterButton}>
                                <NavigateNext />
                            </IconButton>
                        )}
                    </DialogActions>
                ) : (
                    <DialogActions className={classes.dialogActions}>
                        <Pagination color="primary" count={images.length} page={currentImage.number} onChange={onChangeImage} />
                    </DialogActions>
                )}
            </Dialog>
        </>
    );
}

InterventionImageListView.propTypes = {
    // controller state variables
    cols: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    images: PropTypes.array.isRequired,
    currentImage: PropTypes.exact({
        image: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired
    }).isRequired,
    leftDisable: PropTypes.bool.isRequired,
    rightDisable: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    // controler functions
    handleClickOpen: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired
};

export default InterventionImageListView;
