import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import routes from 'routes';
import GlobalStyles from 'presentation/components/GlobalStyles';
import { theme } from 'presentation/theme';
import { AppContextProvider } from 'utils/contextLib';
import { APIProvider } from './actions/actions';

function App() {
    const Routing = () => useRoutes(routes);
    return (
        <AppContextProvider>
            <APIProvider>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <Routing />
                </ThemeProvider>
            </APIProvider>
        </AppContextProvider>
    );
}

export default App;
