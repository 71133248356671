import React from 'react';
import PropTypes from 'prop-types';
//  material
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, Typography, CircularProgress } from '@mui/material';
// utils
import { createImageFromInitials, colors } from 'utils/profilePictureGenerator';
// components
import { styled } from '@mui/material/styles';
import DropzonePicture from '../../../components/account/DropzonePicture';

const UserAvatar = styled(Avatar)(() => ({
    height: 140,
    width: 140
}));

function Profile(props) {
    // get props
    const { userInfo, open, openImage, closeImage, onSavePicture } = props;

    return (
        <>
            <Card>
                <CardContent>
                    <Box alignItems="center" display="flex" flexDirection="column">
                        {userInfo.loading ? (
                            <CircularProgress size={225} />
                        ) : (
                            <>
                                <UserAvatar
                                    src={
                                        userInfo.picture
                                            ? `data:image/*;base64,${userInfo.picture}`
                                            : createImageFromInitials(257, userInfo.name || userInfo.email, colors.color1)
                                    }
                                    name="picture"
                                />
                                <Typography color="textSecondary" variant="body1">
                                    {userInfo.role}
                                </Typography>
                                {/* <Typography color="textSecondary" variant="body1"> */}
                                {/*     {userInfo.timezone} */}
                                {/* </Typography> */}
                                <Typography color="textPrimary" gutterBottom variant="h3">
                                    {userInfo.name}
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    {userInfo.email}
                                </Typography>
                            </>
                        )}
                    </Box>
                </CardContent>
                <Divider />
                <CardActions>
                    <Button color="primary" fullWidth variant="text" component="label" type="submit" size="large" onClick={openImage}>
                        Change picture
                    </Button>
                </CardActions>
            </Card>
            <DropzonePicture
                open={open}
                onClose={closeImage}
                onSave={onSavePicture}
                acceptedFiles={['image/jpeg', 'image/png']}
                title="Upload Profile Picture"
            />
        </>
    );
}

Profile.propTypes = {
    // Account View variable props
    userInfo: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    // Account View functions props
    openImage: PropTypes.func.isRequired,
    closeImage: PropTypes.func.isRequired,
    onSavePicture: PropTypes.func.isRequired
};

export default Profile;
