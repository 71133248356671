/**
 * Gateway List View Model - Producer
 */
class GatewayListViewModel {
    constructor(gatewayModel) {
        // owns gateway model
        this.gatewayModel = gatewayModel;
    }

    /**
     * Get gateways
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nGatewaysPerCall Number of gateways per call
     * @param {*} pagToken Pagination token
     * @returns Gateways table data
     */
    getGatewaysTable(token, user, nGatewaysPerCall, pagToken) {
        return this.gatewayModel.getGatewaysTable(token, user, nGatewaysPerCall, pagToken);
    }

    /**
     * Dissociate gateway with host
     * @param {*} gw Gateway UUID
     * @param {*} host Host UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    dissociateGatewayHost(gw, host) {
        return this.gatewayModel.dissociateGatewayHost(gw, host);
    }

    /**
     * Unset the client a gateway belongs to
     * @param {*} gw Gateway UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    unsetClientGateway(gw, owner) {
        return this.gatewayModel.unsetClientGateway(gw, owner);
    }

    /**
     * Get gateway available commands
     * @returns Gateway available commands
     */
    getAvailableCommands() {
        return this.gatewayModel.getAvailableCommands();
    }

    /**
     * Send management command to a specific or multiple gateways
     * @param {*} gateway Gateway UUID
     * @param {*} command Command
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    sendCommand(gateway, command) {
        return this.gatewayModel.sendCommand(gateway, command);
    }
}

export default GatewayListViewModel;
