import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone';
import { ThemeProvider } from '@mui/styles';
import shadows from 'presentation/theme/shadows';
import { colors, createTheme, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const theme = createTheme({
    palette: {
        background: {
            dark: '#f7f2ea',
            paper: '#f7f2ea'
        },
        primary: {
            main: '#d65e0e'
        },
        secondary: {
            main: '#d65e0e'
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: '#273943'
        }
    },
    shadows
});

function DropzonePicture({ open, onClose, onSave, acceptedFiles, title }) {
    const dialogTitle = () => (
        <>
            <span>{title}</span>
            <IconButton style={{ right: '12px', top: '8px', position: 'absolute' }} onClick={onClose}>
                <Close />
            </IconButton>
        </>
    );

    return (
        <ThemeProvider theme={theme}>
            <DropzoneDialog
                dialogTitle={dialogTitle()}
                acceptedFiles={acceptedFiles}
                cancelButtonText="cancel"
                submitButtonText="upload"
                maxFileSize={5000000}
                filesLimit={1}
                open={open}
                onClose={onClose}
                onSave={onSave}
                showPreviews
                showFileNamesInPreview
            />
        </ThemeProvider>
    );
}

DropzonePicture.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    acceptedFiles: PropTypes.array,
    title: PropTypes.string
};

export default DropzonePicture;
