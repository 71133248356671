import React from 'react';
import AddCardioWheelController from '../../presentation/controllers/cardioWheel/AddCardioWheelController';

/**
 * Add Wearable Provider - Connects all de MVVM logic together
 * @returns Add Wearable Controller
 */
function AddCardioWheelProvider() {
    return <AddCardioWheelController />;
}

export default AddCardioWheelProvider;
