import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import SparkMD5 from 'spark-md5';
// view
import { confirmFirmwareUpload, registerFirmwareFile, uploadRegisterFirmware } from 'actions/actions';
import UploadFirmwareView from '../../views/firmware/UploadFirmwareView';

/**
 * Upload Firmware Controller - View related logic
 * @returns Upload Firmware View
 */
function UploadFirmwareController() {
    // initiate local variables
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    // state variables
    const [file, setFile] = useState(null);
    const [checksum, setChecksum] = useState(null);
    const [firmwareUuid, setFirmwareUuid] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * Register firmware file
     * @param {*} values Submit values
     */
    const registerFirmware = async (values) => {
        const { name, extension, checksum, notes } = values;
        const register = await registerFirmwareFile(name, extension, checksum, notes, firmwareUuid);
        if (register?.status === 200) {
            return register.data;
        }
        throw {
            msg: 'Error uploading firmware',
            variant: 'error',
            url: location.state || '/',
            arg: {
                replace: true
            }
        };
    };

    /**
     * Upload Firmware file
     * @param {*} data Register Firmware data
     */
    const uploadFirmware = async (data) => {
        const { fields, url } = data;
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
            formData.append(key, fields[key]);
        });
        formData.append('file', file);
        const upload = await uploadRegisterFirmware(formData, url);
        if (upload?.status !== 204) {
            throw {
                msg: 'Error uploading firmware',
                variant: 'error',
                url: location.state || '/',
                arg: {
                    replace: true
                }
            };
        }
    };

    /**
     * Confirm Register firmware file
     * @param {*} uuid Register Firmware uuid
     */
    const confirmFirmware = async (uuid) => {
        const confirm = await confirmFirmwareUpload(uuid);
        if (confirm?.status === 200) {
            enqueueSnackbar(`Firmware was successfully uploaded`, { variant: 'success' });
            navigate(location.state || '/', { replace: true });
        } else if (confirm?.response.status === 500 && confirm?.response.data.description === 'Checksum did not match.') {
            setFirmwareUuid(confirm.response.data.title.split(' ')[1]);
            enqueueSnackbar('Error, upload file again', { variant: 'error' });
        } else {
            throw {
                msg: 'Error uploading firmware',
                variant: 'error',
                url: location.state || '/',
                arg: {
                    replace: true
                }
            };
        }
    };

    /**
     * Uppdate file complete process
     * @param {*} values Form fields
     * @param {*} actions Form actions
     */
    const onSubmit = async (values, actions) => {
        if (file && checksum) {
            const submitValues = {
                name: values.Name,
                extension: file.name.split('.').pop(),
                checksum,
                notes: values.Notes
            };
            setIsSubmitting(true);
            setIsLoading(true);
            registerFirmware(submitValues)
                .then((data) => {
                    uploadFirmware(data)
                        .then(() => {
                            confirmFirmware(data.firmware)
                                .then(() => {
                                    setIsLoading(false);
                                    setIsSubmitting(false);
                                })
                                .catch((err) => {
                                    enqueueSnackbar(err.msg, { variant: err.variant });
                                    navigate(err.url, err.arg);
                                });
                        })
                        .catch((err) => {
                            enqueueSnackbar(err.msg, { variant: err.variant });
                            navigate(err.url, err.arg);
                        });
                })
                .catch((err) => {
                    enqueueSnackbar(err.msg, { variant: err.variant });
                    navigate(err.url, err.arg);
                });
        } else actions.setFieldError('fileError', 'Firmware file is required');
    };

    /**
     * Calulate MD5 file checksum
     * @param {*} firmware File
     */
    const calculateChecksum = (firmware) => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const file = firmware;
        const chunkSize = 2097152; // Read in chunks of 2MB
        const chunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        function loadNext() {
            const start = currentChunk * chunkSize;
            const end = start + chunkSize >= file.size ? file.size : start + chunkSize;
            fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
        }

        fileReader.onload = (e) => {
            spark.append(e.target.result); // Append array buffer
            currentChunk += 1;
            if (currentChunk < chunks) {
                loadNext();
            } else {
                const hash = spark.end(); // Compute hash
                setChecksum(hash);
            }
        };

        fileReader.onerror = () => {
            enqueueSnackbar(`Error calculating file checksum`, { variant: 'error' });
        };

        loadNext();
    };

    /**
     * On change - insert file event
     * @param {*} files Firmware files - Only one file
     */
    const onChange = (files) => {
        setFile(files[0]);
        if (files[0]) calculateChecksum(files[0]);
    };

    // return view
    return <UploadFirmwareView isSubmitting={isSubmitting} isLoading={isLoading} onChange={onChange} onSubmit={onSubmit} />;
}

UploadFirmwareController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default UploadFirmwareController;
