/**
 * Gateway Client Set View Model - Producer
 */
class GatewayClientViewModel {
    constructor(gatewayModel, clientModel) {
        // owns gateway and client model
        this.gatewayModel = gatewayModel;
        this.clientModel = clientModel;
    }

    /**
     * Get clients
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nClientsPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Clients table data
     */
    getClientsTable(token, user, nClientsPerCall, pagToken) {
        return this.clientModel.getClientsTable(token, user, nClientsPerCall, pagToken);
    }

    /**
     * Set the client a gateway belongs to
     * @param {*} gw Gateway UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    setClientGateway(gw, owner) {
        return this.gatewayModel.setClientGateway(gw, owner);
    }
}

export default GatewayClientViewModel;
