/**
 * Add Wearable View Model - Producer
 */
class AddWearableViewModel {
    constructor(wearableModel) {
        // owns wearable model
        this.wearableModel = wearableModel;
    }

    /**
     * Add new wearable
     * @param {*} user User UUID
     * @param {*} mac Wearable Mac Address
     * @param {*} hardware Wearable Hardware Name
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    addNewWearable(user, mac, hardware) {
        return this.wearableModel.addNewWearable(user, mac, hardware);
    }
}

export default AddWearableViewModel;
