const columns = [
    {
        name: 'rank',
        label: '#',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'client',
        label: 'Client',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'gateway',
        label: 'Gateways',
        options: {
            filter: false
        }
    }
    // Activate when query ready
    // {
    //   name: 'online',
    //   label: 'Online',
    //   options: {
    //     filter: false,
    //   }
    // }
];
export default columns;
