import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography, colors, CircularProgress } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    differenceIcon: {
        color: colors.red[900]
    },
    differenceValue: {
        color: colors.red[900],
        marginRight: theme.spacing(1)
    }
}));

const StockAvatar = styled(Avatar)(() => ({
    backgroundColor: '#ed174d',
    height: 56,
    width: 56
}));

function GatewaysStock({ className, value, loading, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">
                            GATEWAYS IN STOCK
                        </Typography>
                        <Typography color="textPrimary" variant="h3">
                            {loading ? <CircularProgress size={22} /> : value}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StockAvatar>
                            <StoreIcon />
                        </StockAvatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

GatewaysStock.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired
};

export default GatewaysStock;
