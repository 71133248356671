import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MUIDataTable from 'mui-datatables';
// material
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Grid,
    Card,
    CardHeader,
    Divider,
    CardContent,
    CircularProgress,
    ListItem,
    ListItemText
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';
import columns from './columns';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(1)': {
                        width: '5vw'
                    },
                    '&:nth-child(2)': {
                        width: '5vw'
                    }
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    height: 90
                }
            }
        }
    },
    theme
);

function WearableClientSetView(props) {
    const classes = useStyles();

    // get props
    const {
        wearables,
        clients,
        selectedClient,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleOnRowSelected,
        setClientWearable
    } = props;

    // table options
    const options = {
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        selectableRows: 'single',
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        resizableColumns: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onRowSelectionChange: handleOnRowSelected
    };

    return (
        <Page className={classes.root} title="Set Client">
            <Container maxWidth={false}>
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Set Client
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                client: selectedClient
                            }}
                            validationSchema={Yup.object().shape({
                                client: Yup.string()
                                    .max(50, 'Client UUID must be at most 50 characters')
                                    .required('Client UUID is required')
                            })}
                            onSubmit={async () => {
                                setClientWearable(selectedClient);
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form onSubmit={handleSubmit} className={classes.root}>
                                    <Card>
                                        <CardHeader subheader="" title="" />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: 300
                                                    }}
                                                >
                                                    <Grid container spacing={1}>
                                                        {wearables.map((wearable) => (
                                                            <Grid item xs={12} sm={12} md={12} key={wearable}>
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={`Wearable: ${wearable}`}
                                                                        style={{ whiteSpace: 'nowrap' }}
                                                                    />
                                                                </ListItem>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.client && errors.client)}
                                                        fullWidth
                                                        helperText={touched.client && errors.client}
                                                        placeholder="Select the client from the list or write the UUID yourself."
                                                        label="Client UUID"
                                                        name="client"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.client}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <Box display="flex" justifyContent="flex-end" p={2}>
                                            <Button color="primary" variant="contained" disabled={isSubmitting} type="submit">
                                                Set
                                            </Button>
                                        </Box>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Box mt={3}>
                            <ThemeProvider theme={tableTheme}>
                                <MUIDataTable title="Available Clients" data={clients} columns={columns} options={options} />
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

WearableClientSetView.propTypes = {
    // controller state variables
    clients: PropTypes.array.isRequired,
    wearables: PropTypes.array.isRequired,
    selectedClient: PropTypes.string.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    // controller functions
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleOnRowSelected: PropTypes.func.isRequired,
    setClientWearable: PropTypes.func.isRequired
};

export default WearableClientSetView;
