import { confirmFirmwareUpload } from '../../../actions/actions';

/**
 * Upload Firmware View Model - Producer
 */
class UploadFirmwareViewModel {
    constructor(firmwareModel) {
        // owns firmware model
        this.firmwareModel = firmwareModel;
    }

    /**
     * Register firmware
     * @param {*} name Firmware Name
     * @param {*} extension Firmware File extension
     * @param {*} checksum Firmware File checksum
     * @param {*} notes Firmware realease notes
     * @param {*} uuid Firmware UUID
     * @returns Firmware UUID, URL and fields
     */
    registerFirmware(name, extension, checksum, notes, uuid) {
        return this.firmwareModel.registerFirmware(name, extension, checksum, notes, uuid);
    }

    /**
     * Upload firmware
     * @param {*} fields Register fields
     * @param {*} firmware Firmware UUID
     * @param {*} url Firmware Register URL
     * @param {*} file Firmware File
     * @returns HTTP Response
     */
    uploadFirmware(fields, firmware, url, file) {
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
            formData.append(key, fields[key]);
        });
        formData.append('file', file);
        return this.firmwareModel.uploadFirmware(formData, firmware, url);
    }

    /**
     * Confirm firmware
     * @param {*} uuid Firmware UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    confirmFirmware(uuid) {
        return confirmFirmwareUpload(uuid);
    }
}

export default UploadFirmwareViewModel;
