import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, TextField, Typography, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Page from 'presentation/components/Page';
import moment from 'moment-timezone';
import { completeNewPassword, getCurrentUser, signIn, signOut } from 'aws-auth-cardioid';
import { useSnackbar } from 'notistack';
import { useAppContext } from 'utils/contextLib';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function handleErrors(error) {
    if (error.name === 'NotAuthorizedException') {
        if (error.message === 'Incorrect username or password.') return 'Incorrect email or password.';
        return error.message;
    }
    if (error.name === 'PasswordResetRequiredException') return 'reset';
    return null;
}

function LoginView() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { setUserInfo, setNavBarSelected } = useAppContext();
    const [changePassword, setChangePassword] = useState(false);

    const user = getCurrentUser();

    const logout = async () => {
        try {
            await signOut({ global: false });
            setUserInfo({
                uuid: '',
                email: '',
                name: '',
                timezone: moment.tz.guess(),
                picture: null,
                role: '',
                loading: true
            });
        } catch (e) {
            enqueueSnackbar(`Unable to sign out current session`, { variant: 'error' });
            navigate('/login');
        }
    };

    const onSubmit = async (formData, actions) => {
        const { email, password, newPassword } = formData;
        try {
            await logout();
            const res = await signIn(email, password);
            localStorage.setItem('email', email);
            setUserInfo((prevInfo) => ({ ...prevInfo, email }));
            setNavBarSelected(0);
            if (res === 'NEW_PASSWORD_REQUIRED') {
                if (changePassword) {
                    await completeNewPassword(user, newPassword);
                    // const status = await updateStatus(email);
                    // if (status.isUpdated) navigate(location.state || '/');
                    navigate(location.state || '/');
                } else setChangePassword(true);
            } else {
                setChangePassword(false);
                navigate(location.state || '/');
            }
        } catch (e) {
            const errorMessage = handleErrors(e);
            if (errorMessage === 'reset') navigate('/resetPassword');
            else actions.setFieldError('apiError', errorMessage || e.message);
        }
    };

    return (
        <Page className={classes.root} title="Login">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            newPassword: '',
                            showPassword: false,
                            showNewPassword: false
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            password: Yup.string().required('Password is required'),
                            newPassword: Yup.lazy((value) => {
                                switch (typeof value) {
                                    case 'string':
                                        return (
                                            Yup.string()
                                                .min(8, 'New Password must have at least 8 chars')
                                                .matches(/[a-z]/, 'New Password must have at least one lowercase char')
                                                .matches(/[A-Z]/, 'New Password must have at least one uppercase char')
                                                .matches(/[0-9]/, 'New Password must have at least one number')
                                                // eslint-disable-next-line
                      .matches(/[!@#$%^&*()_\[\]{};':"\\|,.<>\/?~`]/, 'New Password must have at least one of the following special chars ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `')
                                                .required('New Password is required')
                                        );
                                    default:
                                        return Yup.mixed().notRequired();
                                }
                            })
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Sign in
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2">
                                        Sign in with your email address
                                    </Typography>
                                </Box>
                                <Typography align="left" color="error" variant="caption">
                                    {errors.apiError}
                                </Typography>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    autoComplete="on"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    variant="outlined"
                                    autoComplete="on"
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setFieldValue('showPassword', !values.showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                                edge="end"
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        )
                                    }}
                                />
                                {changePassword && (
                                    <TextField
                                        error={Boolean(touched.newPassword && errors.newPassword)}
                                        fullWidth
                                        helperText={touched.newPassword && errors.newPassword}
                                        label="New Password"
                                        margin="normal"
                                        name="newPassword"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type={values.showNewPassword ? 'text' : 'password'}
                                        value={values.newPassword}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setFieldValue('showNewPassword', !values.showNewPassword)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            )
                                        }}
                                    />
                                )}
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign in now
                                    </Button>
                                </Box>
                                <Link component={RouterLink} to="/forgotPassword" variant="h6" state={{ email: values.email }}>
                                    Forgot password
                                </Link>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

export default LoginView;
