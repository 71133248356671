import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Card, CardContent, CardHeader, Divider, TextField, Typography, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function Password(props) {
    // get props
    const { changePassword } = props;

    return (
        <Formik
            initialValues={{
                previousPassword: '',
                newPassword: '',
                showPreviousPassword: false,
                showNewPassword: false
            }}
            validationSchema={Yup.object().shape({
                previousPassword: Yup.string().required('Current Password is required'),
                newPassword: Yup.string()
                    .min(8, 'New Password must have at least 8 chars')
                    .matches(/[a-z]/, 'New Password must have at least one lowercase char')
                    .matches(/[A-Z]/, 'New Password must have at least one uppercase char')
                    .matches(/[0-9]/, 'New Password must have at least one number')
                    // eslint-disable-next-line
            .matches(/[!@#$%^&*()_\[\]{};':"\\|,.<>\/?~`]/, 'New Password must have at least one of the following special chars ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `')
                    .required('New Password is required')
            })}
            onSubmit={changePassword}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader subheader="Change Password" title="Password" />
                        <Divider />
                        <Box mb={3}>
                            <Typography color="textSecondary" gutterBottom variant="body2" />
                        </Box>
                        <CardContent>
                            <Typography align="left" color="error" variant="caption">
                                {errors.apiError}
                            </Typography>
                            <TextField
                                error={Boolean(touched.previousPassword && errors.previousPassword)}
                                fullWidth
                                helperText={touched.previousPassword && errors.previousPassword}
                                label="Current Password"
                                margin="normal"
                                name="previousPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={values.showPreviousPassword ? 'text' : 'password'}
                                value={values.previousPassword}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setFieldValue('showPreviousPassword', !values.showPreviousPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {values.showPreviousPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                            <TextField
                                error={Boolean(touched.newPassword && errors.newPassword)}
                                fullWidth
                                helperText={touched.newPassword && errors.newPassword}
                                label="New Password"
                                margin="normal"
                                name="newPassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type={values.showNewPassword ? 'text' : 'password'}
                                value={values.newPassword}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setFieldValue('showNewPassword', !values.showNewPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    )
                                }}
                            />
                        </CardContent>
                        <Divider />
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                                Change Password
                            </Button>
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
}

Password.propTypes = {
    // Account View functions props
    changePassword: PropTypes.func.isRequired
};
export default Password;
