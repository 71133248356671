import React from 'react';
import { Box, Container, Typography, CircularProgress } from '@mui/material';
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 200
    }
}));

function LoadingLayout() {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="loading">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="md">
                    <Typography align="center" color="textPrimary" variant="h1">
                        Myfleet Back-Office
                    </Typography>
                    <Box textAlign="center">
                        <img alt="cardio_logo" className={classes.image} src="/static/loading-logo.png" />
                    </Box>
                    <Box textAlign="center">
                        <CircularProgress size={55} />
                    </Box>
                </Container>
            </Box>
        </Page>
    );
}

export default LoadingLayout;
