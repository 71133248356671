import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Card, CardContent } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { makeStyles } from '@mui/styles';

// delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 30
    }
}));

function InterventionMap(props) {
    const classes = useStyles();
    const { position, zoom, scrollWheelZoom } = props;
    const [updatedPosition, setUpdatedPosition] = useState(position);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (position !== updatedPosition) {
            setUpdatedPosition(position);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [position]);

    return (
        <Card>
            <CardContent>
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h3" align="center">
                        Location
                    </Typography>
                </Box>
                <Box className={classes.root}>
                    {loading ? (
                        <CircularProgress style={{ position: 'relative', marginLeft: '50%', left: -20 }} />
                    ) : (
                        <MapContainer
                            center={updatedPosition}
                            zoom={zoom}
                            scrollWheelZoom={scrollWheelZoom}
                            style={{ height: '30rem' }}
                            className={classes.root}
                        >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={updatedPosition}>
                                <Popup>Intervention Location</Popup>
                            </Marker>
                        </MapContainer>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

InterventionMap.propTypes = {
    position: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    scrollWheelZoom: PropTypes.bool.isRequired
};

export default InterventionMap;
