import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import FirmwareListView from '../../views/firmware/FirmwareListView';
// components
import NotesColumn from '../../components/firmware/NotesColumn';
import decodeJwt from '../../../utils/jwt';
import { getAllFirmwares } from '../../../actions/actions';
// utils

/**
 * Firmware List View Controller - View related logic
 * @returns Firmwares List View
 */
function FirmwareListController() {
    // initiate local variables
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const nFirmwaresPerCall = config.tables.TABLE_ROWS_PER_CALL;
    // state variables
    const [firmwares, setFirmwares] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const isAdmin = userInfo.role === 'Administrator';

    /**
     * Get firmwares
     */
    const getFirmwares = async () => {
        const {
            data: { Firmwares, PaginationToken }
        } = await getAllFirmwares(nFirmwaresPerCall, pagToken);
        if (Firmwares) {
            const newFirmwares = [...firmwares, ...Firmwares.map((firmware) => firmware)];
            setFirmwares(newFirmwares);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Firmwares.length === nFirmwaresPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nFirmwaresPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of clients
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * On change page - Next page and previous
     * @param {*} currentPage Current table page
     */
    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of gws
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getFirmwares().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    /**
     * Custom sort by date
     * @param {*} data Table data
     * @param {*} colIndex Column index
     * @param {*} order Order
     * @returns New data ordered by date
     */
    const handleCustomSort = (data, colIndex, order) => {
        if (colIndex === 3) {
            return data.sort((a, b) => {
                const dateA = new Date(a.data[colIndex]).getTime();
                const dateB = new Date(b.data[colIndex]).getTime();
                return (dateA < dateB ? -1 : 1) * (order === 'desc' ? 1 : -1);
            });
        }
        return data.sort((a, b) => (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1));
    };

    /**
     * Click on upload firmware button
     */
    const uploadFirmware = () => {
        navigate('upload', { state: location });
    };

    /**
     * Notes column
     * @param {*} value Notes column value
     * @returns Notes column component
     */
    const notesColumn = (value) => <NotesColumn notes={value} />;

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getFirmwares().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <FirmwareListView
            firmwares={firmwares}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            isAdmin={isAdmin}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleCustomSort={handleCustomSort}
            uploadFirmware={uploadFirmware}
            notesColumn={notesColumn}
        />
    );
}

FirmwareListController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default FirmwareListController;
