import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from '@mui/material';
import ChipsArray from 'utils/chipsArray';

function CardioCloud(props) {
    return (
        <Grid item md={4} sm={6} xs={12} direction="column">
            <Typography color="textPrimary" gutterBottom variant="h6">
                Cardio Cloud
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.cardioCloudEnable}
                                    onChange={() => props.setFieldValue('cardioCloudEnable', !props.values.cardioCloudEnable)}
                                />
                            }
                            label="Enable"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox disabled checked={props.values.trackUpload} />} label="Trip upload tracking" />
                    </Grid>
                    <ChipsArray title="Data types" chipData={props.values.cardioCloudDtype} onDelete={undefined} size="small" />
                </>
            )}
        </Grid>
    );
}

CardioCloud.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default CardioCloud;
