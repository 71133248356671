import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Container, TextField, Typography, colors, LinearProgress, Grid } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';
import shadows from 'presentation/theme/shadows';
// components
import Page from 'presentation/components/Page';
import { createTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    dropzone: {
        height: 295
    },
    bar: {
        height: 6
    }
}));

const theme = createTheme({
    palette: {
        background: {
            dark: '#f7f2ea',
            paper: '#f7f2ea'
        },
        primary: {
            main: '#d65e0e'
        },
        secondary: {
            main: '#d65e0e'
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: '#273943'
        }
    },
    shadows
});

function UploadFirmwareView(props) {
    const classes = useStyles();

    // get props
    const { isSubmitting, isLoading, onChange, onSubmit } = props;

    return (
        <Page className={classes.root} title="Upload Firmware">
            <Box display="flex" flexDirection="column" height="100%">
                <Container maxWidth="lg">
                    <Formik
                        initialValues={{
                            Name: '',
                            Notes: ''
                        }}
                        validationSchema={Yup.object().shape({
                            Name: Yup.string().max(30).required('Name is required'),
                            Notes: Yup.string().max(5000, 'Release Notes must be at most 5000 characters')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Typography color="textPrimary" variant="h2" align="center">
                                    Upload Firmware
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box mt={6}>
                                            <TextField
                                                error={Boolean(touched.Name && errors.Name)}
                                                fullWidth
                                                helperText={touched.Name && errors.Name}
                                                label="Name"
                                                name="Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                value={values.Name}
                                                variant="outlined"
                                            />
                                            <TextField
                                                error={Boolean(touched.Notes && errors.Notes)}
                                                fullWidth
                                                helperText={touched.Notes && errors.Notes}
                                                multiline
                                                label="Release Notes"
                                                margin="normal"
                                                name="Notes"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="text"
                                                rows={6}
                                                value={values.Notes}
                                                variant="outlined"
                                            />
                                        </Box>
                                        {isLoading && <LinearProgress className={classes.bar} />}
                                        <Box my={2}>
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                            >
                                                Upload Firmware
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Box mt={6}>
                                            <ThemeProvider theme={theme}>
                                                <DropzoneArea
                                                    dropzoneText="Drag and drop firmware file here or click"
                                                    acceptedFiles={['.zip']}
                                                    maxFileSize={104857600}
                                                    filesLimit={1}
                                                    onChange={onChange}
                                                    showFileNames
                                                    dropzoneClass={classes.dropzone}
                                                />
                                            </ThemeProvider>
                                        </Box>
                                        <Typography align="left" color="error" variant="caption" style={{ marginLeft: 18 }}>
                                            {errors.fileError}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

UploadFirmwareView.propTypes = {
    // controller functions
    isSubmitting: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default UploadFirmwareView;
