import React from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { forgotPassword } from 'aws-auth-cardioid';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function ForgotPasswordView() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { state } = useLocation();
    function onSubmit(formData, actions) {
        const { email } = formData;
        forgotPassword(email)
            .then(() => {
                navigate('/resetPassword', { replace: false, state: { email } });
            })
            .catch((err) => {
                actions.setFieldError('apiError', err.message);
            });
    }

    return (
        <Page title="Forgot Password" className={classes.root}>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: state?.email || ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Forgot Password
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2">
                                        Use your e-mail to get a code
                                    </Typography>
                                </Box>
                                <Typography align="left" color="error" variant="caption">
                                    {errors.apiError}
                                </Typography>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Get code
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

export default ForgotPasswordView;
