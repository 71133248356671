import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import JSONPretty from 'react-json-pretty';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { configGateway, updateGatewayConfig } from 'actions/actions';
import { makeStyles } from '@mui/styles';
import Idreams from './idreams';
import DashCam from './dashcam';
import GeoFencing from './geoFencing';
import Gps from './gps';
import Mobileye from './mobileye';
import Storage from './storage';
import CardioCloud from './cardioCloud';
import CardioWheel from './cardioWheel';
import Wearable from './wearable';
import Ecg from './ecg';
import Drowsiness from './drowsiness';
import FmsCan from './fmsCan';
import Motion from './motion';

const useStyles = makeStyles(() => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    jsonButton: {
        marginRight: '16px'
    }
}));

function GatewayConfig({ className, ...rest }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { gw } = useParams();
    const [openJSON, setOpenJSON] = useState(false);
    const [disableJSON, setDisableJSON] = useState(true);
    const descriptionElementRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [gatewayConfig, setGatewayConfig] = useState({
        data: {
            idreams: {
                enable: false,
                allow_visual_warnings: false,
                allow_auditory_warnings: false,
                fms_id: false,
                default_upload: false
            },
            dashcam: {
                enable: false,
                upload: false,
                camera: '',
                event_period: 0,
                triggers: []
            },
            'geo-fencing': {
                enable: false,
                max_dist: 0.5,
                max_delta: 30
            },
            gps: {
                enable: false,
                spd_filter: false
            },
            mobileye_can: {
                enable: false,
                interface: ''
            },
            fms_can: {
                enable: false,
                interface: ''
            },
            motion: {
                enable: false,
                interface: 1,
                sensor: '',
                thresholds: {
                    ha: [0.12, 0.22, 0.3],
                    hb: [-0.15, -0.35, -0.5],
                    hc: [0.2, 0.3, 0.45]
                },
                durations: {
                    ha: [2, 0.5, 0.3],
                    hb: [2, 0.5, 0.3],
                    hc: [2, 0.5, 0.3]
                }
            },
            storage: {
                enable: false,
                dtype: []
            },
            cardiocloud: {
                enable: false,
                track_upload: false,
                dtype: []
            },
            cardiowheel: {
                enable: false,
                mac: ''
            },
            wearable: {
                enable: false,
                mac: ''
            },
            ecg: {
                enable: false
            },
            drowsiness: {
                enable: false
            }
        }
    });

    useEffect(() => {
        const getConfig = async () => {
            const { data } = await configGateway(gw);
            if (data) {
                setGatewayConfig(data);
                setDisableJSON(false);
            } else {
                enqueueSnackbar(`Server error`, { variant: 'error' });
                navigate('/login');
            }
            setLoading(false);
        };
        getConfig().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (formData) => {
        const data = {
            idreams: {
                enable: formData.idreamsEnable,
                allow_visual_warnings: formData.allowVisualwarnings,
                allow_auditory_warnings: formData.allowAuditoryWarnings,
                fms_id: formData.fmsId,
                default_upload: formData.defaultUpload
            },
            dashcam: {
                enable: formData.dashcamEnable,
                upload: formData.upload,
                camera: formData.camera,
                event_period: Number(formData.eventPeriod),
                triggers: formData.triggers
            },
            'geo-fencing': {
                enable: formData.geoFencingEnable,
                max_dist: Number(formData.maxDist),
                max_delta: Number(formData.maxDelta)
            },
            gps: {
                enable: formData.gpsEnable,
                spd_filter: formData.spdFilter
            },
            mobileye_can: {
                enable: formData.mobileyeEnable,
                interface: formData.mobileyeInterface
            },
            fms_can: {
                enable: formData.fmsCanEnable,
                interface: formData.fmsCanInterface
            },
            motion: {
                enable: formData.motionEnable,
                interface: Number(formData.motionInterface),
                sensor: formData.sensor,
                thresholds: {
                    ha: [formData.thresholds_ha_0, formData.thresholds_ha_1, formData.thresholds_ha_2].map(Number),
                    hb: [formData.thresholds_hb_0, formData.thresholds_hb_1, formData.thresholds_hb_2].map(Number),
                    hc: [formData.thresholds_hc_0, formData.thresholds_hc_1, formData.thresholds_hc_2].map(Number)
                },
                durations: {
                    ha: [formData.durations_ha_0, formData.durations_ha_1, formData.durations_ha_2].map(Number),
                    hb: [formData.durations_hb_0, formData.durations_hb_1, formData.durations_hb_2].map(Number),
                    hc: [formData.durations_hc_0, formData.durations_hc_1, formData.durations_hc_2].map(Number)
                }
            },
            storage: {
                enable: formData.storageEnable,
                dtype: formData.storageDtype
            },
            cardiocloud: {
                enable: formData.cardioCloudEnable,
                track_upload: formData.trackUpload,
                dtype: formData.cardioCloudDtype
            },
            cardiowheel: {
                enable: formData.cardioWheelEnable,
                mac: formData.cardioWheelMac
            },
            wearable: {
                enable: formData.wearableEnable,
                mac: formData.wearableMac
            },
            ecg: {
                enable: formData.ecgEnable
            },
            drowsiness: {
                enable: formData.drowsinessEnable
            }
        };
        const {
            data: { updated }
        } = await updateGatewayConfig(gw, data);
        if (updated) {
            enqueueSnackbar('Gateway was successfully updated', { variant: 'success' });
            navigate('/app/gateways', { replace: true });
        } else {
            enqueueSnackbar('Error updating gateway', { variant: 'false' });
        }
    };

    const handleClickOpen = () => {
        setOpenJSON(true);
    };

    const handleClose = () => {
        setOpenJSON(false);
    };

    useEffect(() => {
        if (openJSON) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openJSON]);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    idreamsEnable: gatewayConfig.data.idreams.enable,
                    allowVisualwarnings: gatewayConfig.data.idreams.allow_visual_warnings,
                    allowAuditoryWarnings: gatewayConfig.data.idreams.allow_auditory_warnings,
                    fmsId: gatewayConfig.data.idreams.fms_id,
                    defaultUpload: gatewayConfig.data.idreams.default_upload,
                    dashcamEnable: gatewayConfig.data.dashcam.enable,
                    upload: gatewayConfig.data.dashcam.upload,
                    camera: gatewayConfig.data.dashcam.camera,
                    eventPeriod: gatewayConfig.data.dashcam.event_period,
                    triggers: gatewayConfig.data.dashcam.triggers,
                    geoFencingEnable: gatewayConfig.data['geo-fencing'].enable,
                    maxDist: gatewayConfig.data['geo-fencing'].max_dist,
                    maxDelta: gatewayConfig.data['geo-fencing'].max_delta,
                    gpsEnable: gatewayConfig.data.gps.enable,
                    spdFilter: gatewayConfig.data.gps.spd_filter,
                    mobileyeEnable: gatewayConfig.data.mobileye_can.enable,
                    mobileyeInterface: gatewayConfig.data.mobileye_can.interface,
                    fmsCanEnable: gatewayConfig.data.fms_can.enable,
                    fmsCanInterface: gatewayConfig.data.fms_can.interface,
                    motionEnable: gatewayConfig.data.motion.enable,
                    motionInterface: gatewayConfig.data.motion.interface,
                    sensor: gatewayConfig.data.motion.sensor,
                    thresholds_ha_0: gatewayConfig.data.motion.thresholds.ha[0],
                    thresholds_hb_0: gatewayConfig.data.motion.thresholds.hb[0],
                    thresholds_hc_0: gatewayConfig.data.motion.thresholds.hc[0],
                    thresholds_ha_1: gatewayConfig.data.motion.thresholds.ha[1],
                    thresholds_hb_1: gatewayConfig.data.motion.thresholds.hb[1],
                    thresholds_hc_1: gatewayConfig.data.motion.thresholds.hc[1],
                    thresholds_ha_2: gatewayConfig.data.motion.thresholds.ha[2],
                    thresholds_hb_2: gatewayConfig.data.motion.thresholds.hb[2],
                    thresholds_hc_2: gatewayConfig.data.motion.thresholds.hc[2],
                    durations_ha_0: gatewayConfig.data.motion.durations.ha[0],
                    durations_hb_0: gatewayConfig.data.motion.durations.hb[0],
                    durations_hc_0: gatewayConfig.data.motion.durations.hc[0],
                    durations_ha_1: gatewayConfig.data.motion.durations.ha[1],
                    durations_hb_1: gatewayConfig.data.motion.durations.hb[1],
                    durations_hc_1: gatewayConfig.data.motion.durations.hc[1],
                    durations_ha_2: gatewayConfig.data.motion.durations.ha[2],
                    durations_hb_2: gatewayConfig.data.motion.durations.hb[2],
                    durations_hc_2: gatewayConfig.data.motion.durations.hc[2],
                    storageEnable: gatewayConfig.data.storage.enable,
                    storageDtype: gatewayConfig.data.storage.dtype,
                    cardioCloudEnable: gatewayConfig.data.cardiocloud.enable,
                    trackUpload: gatewayConfig.data.cardiocloud.track_upload,
                    cardioCloudDtype: gatewayConfig.data.cardiocloud.dtype,
                    cardioWheelEnable: gatewayConfig.data.cardiowheel.enable,
                    cardioWheelMac: gatewayConfig.data.cardiowheel.mac,
                    wearableEnable: gatewayConfig.data.wearable.enable,
                    wearableMac: gatewayConfig.data.wearable.mac,
                    ecgEnable: gatewayConfig.data.ecg.enable,
                    drowsinessEnable: gatewayConfig.data.drowsiness.enable
                }}
                validationSchema={Yup.object().shape({
                    maxDist: Yup.number().min(0.5, 'MaxDist must be at least 0.5'),
                    maxDelta: Yup.number().min(30, 'MaxDelta must be at least 30'),
                    thresholds_ha_0: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    thresholds_hb_0: Yup.number().negative('hb value must be negative').typeError('A number is required'),
                    thresholds_hc_0: Yup.number().positive('hc value must be positive').typeError('A number is required'),
                    thresholds_ha_1: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    thresholds_hb_1: Yup.number().negative('hb value must be negative').typeError('A number is required'),
                    thresholds_hc_1: Yup.number().positive('hc value must be positive').typeError('A number is required'),
                    thresholds_ha_2: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    thresholds_hb_2: Yup.number().negative('hb value must be negative').typeError('A number is required'),
                    thresholds_hc_2: Yup.number().positive('hc value must be positive').typeError('A number is required'),
                    durations_ha_0: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    durations_hb_0: Yup.number().positive('hb value must be positive').typeError('A number is required'),
                    durations_hc_0: Yup.number().positive('hc value must be positive').typeError('A number is required'),
                    durations_ha_1: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    durations_hb_1: Yup.number().positive('hb value must be positive').typeError('A number is required'),
                    durations_hc_1: Yup.number().positive('hc value must be positive').typeError('A number is required'),
                    durations_ha_2: Yup.number().moreThan(0, 'ha value must be positive').typeError('A number is required'),
                    durations_hb_2: Yup.number().positive('hb value must be positive').typeError('A number is required'),
                    durations_hc_2: Yup.number().positive('hc value must be positive').typeError('A number is required')
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleSubmit, touched, values, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
                        <Card>
                            <CardHeader subheader="Manage Gateway Configuration" title={`Gateway ${gw}`} />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={6} wrap="wrap">
                                    <Idreams values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <DashCam
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        loading={loading}
                                    />
                                    <GeoFencing values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Gps values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Mobileye values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <FmsCan values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Storage values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <CardioCloud values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <CardioWheel values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Wearable values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Ecg values={values} setFieldValue={setFieldValue} loading={loading} />
                                    <Drowsiness values={values} setFieldValue={setFieldValue} loading={loading} />
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Grid container spacing={4}>
                                    <Motion
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        touched={touched}
                                        loading={loading}
                                    />
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box display="flex" justifyContent="flex-end" p={2}>
                                <Button sx={{ mr: 1 }} color="primary" variant="contained" onClick={handleClickOpen} disabled={disableJSON}>
                                    Show JSON
                                </Button>
                                <Button color="primary" variant="contained" type="submit" m={2}>
                                    Save
                                </Button>
                            </Box>
                        </Card>
                    </form>
                )}
            </Formik>
            <Dialog
                open={openJSON}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Gateway Configuration JSON</DialogTitle>
                <DialogContent dividers={false}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} component="span">
                        <JSONPretty id="json-pretty" data={gatewayConfig} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        CLose
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

GatewayConfig.propTypes = {
    className: PropTypes.string
};

export default GatewayConfig;
