import React, { useContext, createContext, useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export const AppContext = createContext(null);

export function AppContextProvider({ children }) {
    const [navBarSelected, setNavSelected] = useState(Number(localStorage.getItem('navBarSelected') || 0));
    const setNavBarSelected = (index) => {
        localStorage.setItem('navBarSelected', index);
        setNavSelected(index);
    };
    const [userInfo, setUserInfo] = useState({
        uuid: '',
        email: localStorage.getItem('email') || '',
        name: '',
        timezone: moment.tz.guess(),
        picture: null,
        role: '',
        loading: true
    });
    const value = useMemo(
        () => ({
            userInfo,
            setUserInfo,
            navBarSelected,
            setNavBarSelected
        }),
        [navBarSelected, userInfo]
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('`useAppContext` must be within a `AppContextProvider`');
    }
    return context;
};
