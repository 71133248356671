// actions
import { createTicket } from 'actions/actions';

/**
 * Ticket Model - Ticket data source
 */
class TicketModel {
    /**
     * Create a new ticket
     * @param {*} topic Ticket topic
     * @param {*} userEmail User email
     * @param {*} gwUUID Gateway UUID
     * @param {*} description Ticket description
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async createNewTicket(topic, userEmail, gwUUID, description) {
        return createTicket(topic, userEmail, gwUUID, description);
    }
}

export default TicketModel;
