import React, { useState } from 'react';
import {
    Button,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@mui/material';

import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';

function GatewayColumn({ value, tableMeta, updateValue, dissociateCardioWheelHost }) {
    // open dialog
    const [open, setOpen] = useState(false);
    return (
        <Box>
            {!value?.uuid ? (
                <Button
                    color="primary"
                    href={`associateCardioWheel/${tableMeta.rowData[0]}`}
                    disabled={false}
                    variant="contained"
                    size="small"
                >
                    Set Gateway
                </Button>
            ) : (
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{value.uuid}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpen(true)}>
                            <Cancel />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" component="div">
                                <Typography variant="h4">Unset Gateway</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to unset the gateway from CardioWheel?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    No
                                </Button>
                                <Button
                                    onClick={async () => {
                                        dissociateCardioWheelHost(tableMeta, value.uuid, updateValue);
                                    }}
                                    color="primary"
                                    autoFocus
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

GatewayColumn.propTypes = {
    value: PropTypes.object,
    tableMeta: PropTypes.object.isRequired,
    updateValue: PropTypes.func,
    dissociateCardioWheelHost: PropTypes.func.isRequired
};

export default GatewayColumn;
