import React from 'react';
import moment from 'moment';
import { Button } from '@mui/material';

const columns = [
    {
        name: 'date',
        label: 'Date',
        options: {
            filter: true,
            filterType: 'dropdown',
            sort: true,
            sortThirdClickReset: true,
            customBodyRender: (value) => moment.utc(value * 1000).format('DD/MM/YYYY HH:mm')
        }
    },
    {
        name: 'device',
        label: 'Gateway UUID',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'installer',
        label: 'Installer',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'type',
        label: 'Type',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'info',
        label: 'Information',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (
                <Button color="primary" variant="contained" size="small" href={`interventions/${value}`}>
                    Show
                </Button>
            )
        }
    }
];

export default columns;
