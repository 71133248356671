// actions
import {
    getAllFirmwares,
    getAvailableFirmwares,
    updateGatewayFirmware,
    registerFirmwareFile,
    uploadRegisterFirmware,
    confirmFirmwareUpload
} from 'actions/actions';

/**
 * Firmware Model - Firmware data source
 */
class FirmwareModel {
    /**
     * Get all firmwares
     * @param {*} token AWS token
     * @param {*} nFirmwaresPerCall Number of firmwares per call
     * @param {*} pagToken Pagination token
     * @returns Firmewares table data
     */
    async getFirmwaresTable(token, nFirmwaresPerCall, pagToken) {
        return getAllFirmwares(token, nFirmwaresPerCall, pagToken);
    }

    /**
     * Get firmwares available
     * @param {*} token AWS token
     * @param {*} nFirmwaresPerCall Number of firmwares per call
     * @param {*} pagToken Pagination token
     * @returns Available Firmewares table data
     */
    async getFirmwaresAvailable(token, nFirmwaresPerCall, pagToken) {
        return getAvailableFirmwares(token, nFirmwaresPerCall, pagToken);
    }

    /**
     * Update firmware version to target
     * @param {*} gateways Gateways firmware to be updated
     * @param {*} firmware firmware version
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async deployGatewayFirmware(gateways, firmware) {
        return updateGatewayFirmware(gateways, firmware);
    }

    /**
     * Register firmware
     * @param {*} name Firmware Name
     * @param {*} extension Firmware File extension
     * @param {*} checksum Firmware File checksum
     * @param {*} notes Firmware realease notes
     * @param {*} uuid Firmware UUID
     * @returns Firmware UUID, URL and fields
     */
    async registerFirmware(name, extension, checksum, notes, uuid) {
        return registerFirmwareFile(name, extension, checksum, notes, uuid);
    }

    /**
     * Upload firmware
     * @param {*} formData Register fields
     * @param {*} firmware Firmware UUID
     * @param {*} url Firmware Register URL
     * @returns HTTP Response
     */
    async uploadFirmware(formData, firmware, url) {
        return uploadRegisterFirmware(formData, firmware, url);
    }

    /**
     * Confirm firmware
     * @param {*} uuid Firmware UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async confirmFirmware(formData, firmware, url) {
        return confirmFirmwareUpload(formData, firmware, url);
    }
}

export default FirmwareModel;
