/**
 * Account View Model - Producer
 */
class AccountViewModel {
    constructor(userModel) {
        // owns user model
        this.userModel = userModel;
    }

    /**
     * Update profile picture
     * @param {*} formData Form fields data
     * @param {*} uuid User UUID
     * @returns Updated profile picture
     */
    updateProfilePicture(formData, uuid) {
        return this.userModel.updateProfilePicture(formData, uuid);
    }

    /**
     * Update profile details
     * @param {*} uuid User UUID
     * @param {*} name User name
     * @param {*} timezone User timezone
     * @returns Updated profile details
     */
    updateProfileDetails(uuid, name, timezone) {
        return this.userModel.updateProfileDetails(uuid, name, timezone);
    }

    /**
     * Change Password
     * @param {*} currentPassword Current Password
     * @param {*} newPassword New Password
     * @returns
     */
    changePassword(currentPassword, newPassword) {
        return this.userModel.changePassword(currentPassword, newPassword);
    }
}

export default AccountViewModel;
