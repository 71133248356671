import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import {
    getCardioWheels,
    getCardioWheelsQuery,
    getGWAvailableCommands,
    removeClientFromCardioWheel,
    removeGatewayFromCardioWheel,
    sendGWCommand
} from 'actions/actions';
// components
import ClientColumn from '../../components/cardioWheel/ClientColumn';
import CustomToolbarSelect from '../../views/gateway/GatewayListView/CustomToolbarSelect';
import CardioWheelListView from '../../views/cardioWheel/cardioWheelListView';
import GatewayColumn from '../../components/cardioWheel/GatewayColumn';
// utils

/**
 * Gateway List View Controller - View related logic
 * @returns Gateway List View
 */
function CardioWheelListController() {
    // initiate local variables
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const [pagesSelected, setPagesSelected] = useState([]);
    // state variables
    const [cardioWheel, setCardioWheel] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagination, setPagination] = useState({ 0: null });
    const [query, setQuery] = useState('');
    // const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const isAdmin = userInfo.role === 'Administrator';
    const [selectedRows, setSelectedRows] = useState([]);
    const [vehicleWidth] = useState('0vw');
    const [clientWidth] = useState('0vw');

    /**
     * Get gateways
     */
    const getCardioWheelsTable = async (page, limit, query) => {
        const { data, headers } =
            query?.length > 0 ? await getCardioWheelsQuery(limit, pagination[page], query) : await getCardioWheels(limit, pagination[page]);
        const { 'pagination-token': paginationToken, 'pagination-count': paginationCount } = headers;
        if (data) {
            setCardioWheel(data);
            setTablePage(page);
            pagination[page + 1] = paginationToken;
            setPagination(pagination);
            setTableMaxPage(Number(paginationCount));
            // set pagination to true -> able to call next set of hosts
            // setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        getCardioWheelsTable(tablePage, newRowsPerPage, query).then();
    };

    /**
     * On change page - Next page and previous
     * @param {*} currentPage Current table page
     */
    const handleOnChangePage = (currentPage) => {
        getCardioWheelsTable(currentPage, rowsPerPageValue, query).then();
    };

    const handleOnSearch = (searchText) => {
        setQuery(searchText);
        getCardioWheelsTable(0, rowsPerPageValue, searchText).then();
    };

    /**
     * Handle on row selected
     * @param {*} currentRowsSelected Current rows selected
     * @param {*} allRowsSelected All rows selected
     * @param {*} rowsSelected Rows selected
     */
    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        // on select
        if (rowsSelected.length > selectedRows.length) {
            setPagesSelected((prevPages) => [...prevPages, tablePage]);
        } else if (!currentRowsSelected.length) {
            setPagesSelected([]);
        } else {
            const index = selectedRows.indexOf(currentRowsSelected[0].dataIndex);
            pagesSelected.splice(index, 1);
            setPagesSelected(pagesSelected);
        }
        setSelectedRows(rowsSelected);
    };

    /**
     * On change gateways firmware target version
     */
    const onChangeFirmwareTarget = () => {
        const gatewaysSelected = [];
        const requests = selectedRows
            .map((row) => {
                const gw = {
                    uuid: cardioWheel[row].uuid,
                    version: cardioWheel[row].version
                };
                gatewaysSelected.push(gw);
                return gatewaysSelected;
            })
            .flat();
        const uniqueGateways = Array.from(new Set(requests));
        setSelectedRows([]);
        navigate('/app/gateways/updateFirmware', {
            replace: false,
            state: {
                gateways: uniqueGateways
            }
        });
    };

    /**
     * Get gateway available commands
     * @returns Gateway available commands
     */
    const getAvailableCommands = async () => {
        const { data } = await getGWAvailableCommands();
        if (data) {
            return data;
        }
        enqueueSnackbar(`Server error`, { variant: 'error' });
        navigate('/login', { replace: true, state: location });
        return null;
    };

    /**
     * Send management command to a specific or multiple gateways
     * @param {*} command Gateway command
     */
    const sendCommand = async (command) => {
        const requests = selectedRows.map(async (row) => {
            const gw = cardioWheel[row].uuid;
            const { status } = await sendGWCommand(gw, command);
            if (status === 200) {
                enqueueSnackbar(`Command was successfully sent to gateway ${gw}`, { variant: 'success' });
            } else {
                enqueueSnackbar(`Server error`, { variant: 'error' });
                navigate('/login', { replace: true, state: location });
            }
        });
        Promise.all(requests).then(() => {
            setSelectedRows([]);
        });
    };

    /**
     * Custom toolbar
     * @returns Custom toolbar component
     */
    const handleCustomToolbarSelect = () => (
        <CustomToolbarSelect
            isAdmin={isAdmin}
            onChangeFirmwareTarget={onChangeFirmwareTarget}
            getAvailableCommands={getAvailableCommands}
            sendCommand={sendCommand}
        />
    );

    /**
     * Unset client that cardioWheel belongs to
     * @param {*} tableMeta Table meta
     * @param {*} uuid Client uuid
     * @param {*} updateValue MUI Datatable function
     */
    const unsetClientCardioWheel = async ({ rowData, rowIndex }, uuid, updateValue) => {
        removeClientFromCardioWheel(rowData[0]).then(({ status }) => {
            if (status === 200) {
                cardioWheel[rowIndex].client = {
                    name: '',
                    uuid: ''
                };
                setCardioWheel([...cardioWheel]);
                updateValue({
                    name: '',
                    uuid: ''
                });
                enqueueSnackbar(`CardioWheel client was successfully unset`, { variant: 'success' });
            } else enqueueSnackbar(`Error unsetting client from CardioWheel`, { variant: 'error' });
        });
    };

    /**
     * Dissociate gateway with host
     * @param {*} tableMeta Table meta
     * @param {*} uuid Vehicle uuid
     * @param {*} updateValue MUI Datatable function
     */
    const dissociateCardioWheel = async ({ rowData, rowIndex }, uuid, updateValue) => {
        removeGatewayFromCardioWheel(rowData[0]).then(({ status }) => {
            if (status === 200) {
                cardioWheel[rowIndex].gateway = {
                    name: '',
                    uuid: ''
                };
                setCardioWheel([...cardioWheel]);
                updateValue({
                    name: '',
                    uuid: ''
                });
                enqueueSnackbar(`Gateway was successfully dissociated with gateway`, { variant: 'success' });
            } else enqueueSnackbar(`Error dissociating CardioWheel with gateway`, { variant: 'error' });
        });
    };

    /**
     * Vehicle column
     * @param {*} value Vehicle column value
     * @param {*} tableMeta Gateways table Meta data
     * @param {*} updateValue MUI Datatable funtion
     * @returns Vehicle column component
     */
    const gatewayColumn = (value, tableMeta, updateValue) => (
        <GatewayColumn value={value} tableMeta={tableMeta} updateValue={updateValue} dissociateCardioWheelHost={dissociateCardioWheel} />
    );

    /**
     * Client column
     * @param {*} value Client column value
     * @param {*} tableMeta Gateways table Meta data
     * @param {*} updateValue MUI Datatable funtion
     * @returns Client column component
     */
    const clientColumn = (value, tableMeta, updateValue) => (
        <ClientColumn value={value} tableMeta={tableMeta} updateValue={updateValue} unsetClientGateway={unsetClientCardioWheel} />
    );

    /**
     * Click on add Cardiowheel button
     */
    const handleClick = () => {
        navigate('addCardioWheel', { state: location });
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getCardioWheelsTable(tablePage, rowsPerPageValue, query).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <CardioWheelListView
            cardioWheel={cardioWheel}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            isAdmin={isAdmin}
            selectedRows={selectedRows}
            vehicleColumnWidth={vehicleWidth}
            clientColumnWidth={clientWidth}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleOnRowSelected={handleOnRowSelected}
            handleCustomToolbarSelect={handleCustomToolbarSelect}
            gatewayColumn={gatewayColumn}
            clientColumn={clientColumn}
            handleClick={handleClick}
            handleOnSearch={handleOnSearch}
        />
    );
}

export default CardioWheelListController;
