import React from 'react';
import GatewayModel from '../../dataaccess/models/gateway';
import GatewayListViewModel from '../../presentation/viewModels/gateway/GatewayListViewModel';
import GatewayListController from '../../presentation/controllers/gateway/GatewayListController';

/**
 * Gateway List Provider - Connects all de MVVM logic together
 * @returns Gateway List Controller
 */
function GatewayListProvider() {
    // instantiate gateway list view model with gateway model
    const gatewayListViewModel = new GatewayListViewModel(new GatewayModel());

    return <GatewayListController viewModel={gatewayListViewModel} />;
}

export default GatewayListProvider;
