// actions
import { updatePicture, updateUser } from 'actions/actions';

/**
 * User Model - User data source
 */
class UserModel {
    /**
     * Update profile picture
     * @param {*} formData Form fields data
     * * @param {*} uuid User UUID
     * @returns Updated profile picture
     */
    async updateProfilePicture(formData, uuid) {
        return updatePicture(formData, uuid);
    }

    /**
     * Update profile details
     * @param {*} uuid User UUID
     * @param {*} name User name
     * @param {*} timezone User timezone
     * @returns Updated profile details
     */
    async updateProfileDetails(uuid, name, timezone) {
        return updateUser(uuid, name, timezone);
    }
}

export default UserModel;
