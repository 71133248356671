/**
 * Gateway Firmware View Model - Producer
 */
class GatewayFirmwareViewModel {
    constructor(firmwareModel) {
        // owns firmware model
        this.firmwareModel = firmwareModel;
    }

    /**
     * Update firmware version to target
     * @param {*} gws Gateways UUID
     * @param {*} version Firmware target version
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    updateFirmware(gw, owner) {
        return this.firmwareModel.updateFirmware(gw, owner);
    }

    /**
     * Get firmwares available
     * @param {*} token AWS token
     * @param {*} nFirmwaresPerCall Number of firmwares per call
     * @param {*} pagToken Pagination token
     * @returns Available Firmewares table data
     */
    getFirmwaresAvailable(token, nFirmwaresPerCall, pagToken) {
        return this.firmwareModel.getFirmwaresAvailable(token, nFirmwaresPerCall, pagToken);
    }

    /**
     * Updated gateways firmware
     * @param {*} gateways Gateways firmware to be updated
     * @param {*} firmware firmware version
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    deployGatewayFirmware(gateways, firmware) {
        return this.firmwareModel.deployGatewayFirmware(gateways, firmware);
    }
}

export default GatewayFirmwareViewModel;
