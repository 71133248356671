import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingLayout from 'presentation/layouts/LoadingLayout';
import { getIdToken, isAuthenticated } from 'aws-auth-cardioid';
import { useAppContext } from './contextLib';

function PrivateRoute({ component: Component, redirectTo, path }) {
    const isAuth = isAuthenticated();
    const idToken = getIdToken();
    const [isLoading, setIsLoading] = useState(false);
    const { setNavBarSelected } = useAppContext();
    const [role, setRole] = useState(null);
    useEffect(() => {
        setIsLoading(true);
        setRole(idToken.payload['cognito:groups'][0]);
        setIsLoading(false);
    }, [idToken.payload, isAuth]);

    if (isLoading) {
        return <LoadingLayout />;
    }

    if ((role === 'User' || role === 'Installer') && path === 'register') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'clients/addClient') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'gateways/updateFirmware') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'wearables/addWearable') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'app/users') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'firmwares/upload') return <Navigate to={redirectTo} />;
    if ((role === 'User' || role === 'Installer') && path === 'app/dashboard') {
        setNavBarSelected(2);
        return <Navigate to={redirectTo} />;
    }
    return <Component />;
}

PrivateRoute.propTypes = {
    component: PropTypes.func,
    redirectTo: PropTypes.string,
    path: PropTypes.string
};

export default PrivateRoute;
