import React from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Container, Grid } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Password from './Password';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function AccountView(props) {
    const classes = useStyles();

    // get props
    const { userInfo, open, openImage, closeImage, onSavePicture, updateProfileDetails, changePassword } = props;

    return (
        <Page className={classes.root} title="Account">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item lg={4} md={6} xs={12}>
                        <Profile
                            userInfo={userInfo}
                            open={open}
                            openImage={openImage}
                            closeImage={closeImage}
                            onSavePicture={onSavePicture}
                        />
                    </Grid>
                    <Grid item lg={8} md={6} xs={12}>
                        <ProfileDetails userInfo={userInfo} updateProfileDetails={updateProfileDetails} />
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Password changePassword={changePassword} />
                </Box>
            </Container>
        </Page>
    );
}

AccountView.propTypes = {
    // controller state variables
    userInfo: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    // controller functions
    openImage: PropTypes.func.isRequired,
    closeImage: PropTypes.func.isRequired,
    onSavePicture: PropTypes.func.isRequired,
    updateProfileDetails: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired
};

export default AccountView;
