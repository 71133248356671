import React from 'react';
import GatewayModel from '../../dataaccess/models/gateway';
import ClientModel from '../../dataaccess/models/client';
import GatewayClientViewModel from '../../presentation/viewModels/gateway/GatewayClientViewModel';
import GatewayClientController from '../../presentation/controllers/gateway/GatewayClientController';

/**
 * Gateway Client Set Provider - Connects all de MVVM logic together
 * @returns Gateway Client Set Controller
 */
function GatewayClientProvider() {
    // instantiate gateway client set view model with gateway and client model
    const gatewayClientViewModel = new GatewayClientViewModel(new GatewayModel(), new ClientModel());

    return <GatewayClientController viewModel={gatewayClientViewModel} />;
}

export default GatewayClientProvider;
