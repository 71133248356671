import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
// material
import {
    Box,
    Button,
    Container,
    Typography,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    CircularProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const columns = [
    {
        name: 'uuid',
        label: 'UUID',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'name',
        label: 'Name',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'created',
        label: 'Creation date',
        options: {
            filter: true,
            filterType: 'dropdown',
            sort: true,
            sortThirdClickReset: true,
            customBodyRender: (value) => moment.utc(value).format('DD/MM/YYYY HH:mm')
        }
    }
];

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(2)': {
                        width: '15vw'
                    },
                    '&:nth-child(3)': {
                        width: '8vw'
                    }
                }
            }
        }
    },
    theme
);

function GatewayFirmwareView(props) {
    const classes = useStyles();

    // get props
    const {
        gateways,
        firmwares,
        selectedFirmware,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleOnRowSelected,
        handleCustomSort,
        onSubmit
    } = props;

    // table options
    const options = {
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        selectableRows: 'single',
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        resizableColumns: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onRowSelectionChange: handleOnRowSelected,
        customSort: handleCustomSort
    };

    return (
        <Page className={classes.root} title="Update Firmware">
            <Container maxWidth={false}>
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Update Gateway Firmware
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                firmware: selectedFirmware
                            }}
                            validationSchema={Yup.object().shape({
                                firmware: Yup.string()
                                    .max(50, 'Firmware must be at most 50 characters')
                                    .required('Firmware UUID is required')
                            })}
                            onSubmit={onSubmit}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                                <form onSubmit={handleSubmit} className={classes.root}>
                                    <Card>
                                        <CardHeader title="Gateways to be updated" />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: 300
                                                    }}
                                                >
                                                    <Grid container>
                                                        {gateways.map((gateway, index) => (
                                                            <Grid item xs={12} sm={12} md={12} key={index}>
                                                                <ListItem>
                                                                    <ListItemText
                                                                        primary={`UUID: ${gateway.uuid}`}
                                                                        secondary={`Version: ${
                                                                            gateway.version ? gateway.version : 'No version available'
                                                                        }`}
                                                                        style={{ whiteSpace: 'nowrap' }}
                                                                    />
                                                                </ListItem>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.firmware && errors.firmware)}
                                                        fullWidth
                                                        helperText={touched.firmware && errors.firmware}
                                                        placeholder="Select the firmware from the list or write the UUID yourself."
                                                        label="Firmware UUID"
                                                        name="firmware"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.firmware}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <Box display="flex" p={2}>
                                            <Box p={1} flexGrow={1} />
                                            <Button color="primary" variant="contained" onClick={onSubmit}>
                                                Update Firmware
                                            </Button>
                                        </Box>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Box mt={3}>
                            <ThemeProvider theme={tableTheme}>
                                <MUIDataTable title="Available Firmwares" data={firmwares} columns={columns} options={options} />
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

GatewayFirmwareView.propTypes = {
    // controller state variables
    gateways: PropTypes.array.isRequired,
    firmwares: PropTypes.array.isRequired,
    selectedFirmware: PropTypes.string.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    // controller functions
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleOnRowSelected: PropTypes.func.isRequired,
    handleCustomSort: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default GatewayFirmwareView;
