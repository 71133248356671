import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Card, CardContent, CardHeader, Divider, Grid, Button, TextField, Checkbox, Autocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';
import Page from 'presentation/components/Page';
import { updateLimitedClients, getLimitedClients, getUniqueOwners } from 'actions/actions';
import { useAppContext } from 'utils/contextLib';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),

        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}));

function LimitUserGateways() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { userInfo } = useAppContext();
    const { uuid } = useParams();
    const [owners, setOwners] = useState([]);
    const [value, setValue] = useState([]);

    const onSubmit = async () => {
        const { status } = await updateLimitedClients(userInfo.uuid, uuid, value);
        if (status === 200) {
            enqueueSnackbar(`User ${uuid} gateway permission list was successfully`, { variant: 'success' });
        } else enqueueSnackbar(`Error changing permissions`, { variant: 'error' });
    };

    const getUniqueClients = async () => {
        const { data: result } = await getUniqueOwners();
        const { data: userLimitedClients } = await getLimitedClients(uuid);
        if (result) setOwners(result);
        else {
            navigate('/login');
            enqueueSnackbar(`Unknown User`, { variant: 'error' });
        }
        if (userLimitedClients) setValue(userLimitedClients || []);
        else {
            enqueueSnackbar(`Unknown User 1`, { variant: 'error' });
            navigate('/login');
        }
    };

    useEffect(() => {
        getUniqueClients().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page className={classes.root} title="Restricted User">
            <Container maxWidth={false}>
                <Toolbar />
                <Box mt={3}>
                    <Card>
                        <CardHeader subheader="Accessible Clients" title={`User ${uuid}`} />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={6} wrap="wrap">
                                <Grid className={classes.item} item md={12} sm={6} xs={12}>
                                    <Autocomplete
                                        id="owners"
                                        multiple
                                        autoHighlight
                                        disableCloseOnSelect
                                        value={value}
                                        onChange={(event, newValue) => {
                                            setValue([...newValue]);
                                        }}
                                        options={owners}
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                    checkedIcon={<CheckBox fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Current Accessible Clients"
                                                name="owners"
                                                variant="outlined"
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box display="flex" justifyContent="flex-end" p={2}>
                            <Button color="primary" variant="contained" type="submit" m={2} onClick={onSubmit}>
                                Save
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default LimitUserGateways;
