import React from 'react';
import CardioWheelClientController from '../../presentation/controllers/cardioWheel/CardioWheelClientController';

/**
 * Gateway Client Set Provider - Connects all de MVVM logic together
 * @returns CardioWheel Client Set Controller
 */
function CardioWheelClientProvider() {
    return <CardioWheelClientController />;
}

export default CardioWheelClientProvider;
