import React from 'react';
import InterventionModel from '../../dataaccess/models/intervention';
import InterventionListViewModel from '../../presentation/viewModels/intervention/InterventionListViewModel';
import InterventionListController from '../../presentation/controllers/intervention/InterventionListController';

/**
 * Intervention List Provider - Connects all de MVVM logic together
 * @returns Intervention List Controller
 */
function InterventionListProvider() {
    // instantiate intervention list view model with intervention model
    const interventionListViewModel = new InterventionListViewModel(new InterventionModel());

    return <InterventionListController viewModel={interventionListViewModel} />;
}

export default InterventionListProvider;
