import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import WearableListView from '../../views/wearable/WearableListView';
// components
import { getWearables, getWearablesQuery, removeClientFromWearable, removeDriverFromWearable } from '../../../actions/actions';
import ClientColumn from '../../components/wearable/ClientColumn';
import DriverColumn from '../../components/wearable/DriverColumn';
// utils

/**
 * Wearable List View Controller - View related logic
 * @returns Wearable List View
 */
function WearableListController() {
    // initiate local variables
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = userInfo.role === 'Administrator';
    // state variables
    const [wearables, setWearables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagination, setPagination] = useState({ 0: null });
    const [tablePage, setTablePage] = useState(0);
    const [query, setQuery] = useState('');

    /**
     * Get wearables
     */
    const getWearablesTable = async (page, limit, query) => {
        setLoading(true);
        const {
            data,
            headers: { 'pagination-token': paginationToken, 'pagination-count': pCount }
        } = query?.length > 0 ? await getWearablesQuery(limit, pagination[page], query) : await getWearables(limit, pagination[page]);
        if (data) {
            setWearables(data);
            setTablePage(page);
            pagination[page + 1] = paginationToken;
            setPagination(pagination);
            setTableMaxPage(Number(pCount));
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        getWearablesTable(tablePage, newRowsPerPage, query).then();
    };

    /**
     * On change page - Next page and previous
     * @param {*} currentPage Current table page
     */
    const handleOnChangePage = (currentPage) => {
        getWearablesTable(currentPage, rowsPerPageValue, query).then();
    };

    const handleOnSearch = (searchText) => {
        setQuery(searchText);
        getWearablesTable(0, rowsPerPageValue, searchText).then();
    };

    /**
     * Click on add clients button
     */
    const handleClick = () => {
        navigate('addWearable', { state: location });
    };

    const unsetClientWearable = async ({ rowData, rowIndex }, uuid, updateValue) => {
        removeClientFromWearable(rowData[0]).then(({ status }) => {
            if (status === 200) {
                wearables[rowIndex].client = {
                    name: '',
                    uuid: ''
                };
                setWearables([...wearables]);
                updateValue({
                    name: '',
                    uuid: ''
                });
                enqueueSnackbar(`Wearable client was successfully unset`, { variant: 'success' });
            } else enqueueSnackbar(`Error unsetting client from Wearable`, { variant: 'error' });
        });
    };

    const unsetDriverWearable = async ({ rowData, rowIndex }, uuid, updateValue) => {
        removeDriverFromWearable(rowData[0]).then(({ status }) => {
            if (status === 200) {
                wearables[rowIndex].driver = null;
                setWearables([...wearables]);
                updateValue(null);
                enqueueSnackbar(`Wearable driver was successfully unset`, { variant: 'success' });
            } else enqueueSnackbar(`Error unsetting driver from Wearable`, { variant: 'error' });
        });
    };

    const clientColumn = (value, tableMeta, updateValue) => (
        <ClientColumn value={value} tableMeta={tableMeta} updateValue={updateValue} unsetClientWearable={unsetClientWearable} />
    );

    const driverColumn = (value, tableMeta, updateValue) => (
        <DriverColumn value={value} tableMeta={tableMeta} updateValue={updateValue} unsetDriverWearable={unsetDriverWearable} />
    );

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getWearablesTable(tablePage, rowsPerPageValue, query).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <WearableListView
            wearables={wearables}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            isAdmin={isAdmin}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleClick={handleClick}
            clientColumn={clientColumn}
            driverColumn={driverColumn}
            handleOnSearch={handleOnSearch}
        />
    );
}

export default WearableListController;
