import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography, CircularProgress, Toolbar } from '@mui/material';
import {
    DriveEta,
    HelpOutline,
    LocalMallOutlined,
    PeopleOutlined,
    AccountCircleOutlined,
    DashboardOutlined,
    BuildOutlined,
    Watch,
    Memory,
    Input,
    MonitorHeart
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { getIdToken, signOut } from 'aws-auth-cardioid';
import moment from 'moment-timezone';
import { useAppContext } from 'utils/contextLib';
import { createImageFromInitials, colors } from 'utils/profilePictureGenerator';
import { getUser, getPicture } from 'actions/actions';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import NavItem from './NavItem';

const items = [
    {
        type: 'item',
        href: '/app/dashboard',
        icon: DashboardOutlined,
        title: 'Dashboard'
    },
    {
        type: 'item',
        href: '/app/clients',
        icon: LocalMallOutlined,
        title: 'Clients'
    },
    {
        type: 'divider',
        title: 'Devices'
    },
    {
        type: 'item',
        href: '/app/gateways',
        icon: DriveEta,
        title: 'Gateways'
    },
    {
        type: 'item',
        href: '/app/wearables',
        icon: Watch,
        title: 'Wearables'
    },
    {
        type: 'item',
        href: '/app/cardioWheel',
        icon: MonitorHeart,
        title: 'CardioWheel'
    },
    {
        type: 'divider',
        title: 'Firmwares'
    },
    {
        type: 'item',
        href: '/app/firmwares',
        icon: Memory,
        title: 'Gateway'
    },
    {
        type: 'divider',
        title: 'Management'
    },
    {
        type: 'item',
        href: '/app/interventions',
        icon: BuildOutlined,
        title: 'Interventions'
    },
    {
        type: 'item',
        href: '/app/users',
        icon: PeopleOutlined,
        title: 'Users'
    },
    {
        type: 'item',
        href: '/app/account',
        icon: AccountCircleOutlined,
        title: 'Account'
    },
    {
        type: 'item',
        href: '/app/support',
        icon: HelpOutline,
        title: 'Support'
    }
];

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 300,
        height: 300
    }
}));

const UserAvatar = styled(Avatar)(() => ({
    cursor: 'pointer',
    width: 64,
    height: 64
}));

function NavBar({ onMobileClose, openMobile }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { userInfo, setUserInfo, navBarSelected, setNavBarSelected } = useAppContext();
    const idToken = getIdToken();
    async function logout() {
        try {
            await signOut();
            setUserInfo({
                uuid: '',
                email: '',
                name: '',
                timezone: moment.tz.guess(),
                picture: null,
                role: '',
                loading: true
            });
            navigate('/login', { replace: false });
        } catch (e) {
            navigate('/login', { replace: false });
        }
    }

    useEffect(() => {
        const uuid = idToken.payload['cognito:username'];
        getUser(uuid)
            .then(({ data: user }) =>
                getPicture(uuid).then((pic) => {
                    setUserInfo((prevInfo) => ({
                        ...prevInfo,
                        uuid: user.uuid,
                        name: user.name ? user.name : '',
                        timezone: user.timezone || moment.tz.guess(),
                        role: user.role,
                        picture: pic,
                        loading: false
                    }));
                })
            )
            .catch(() => {
                enqueueSnackbar(`Server error`, { variant: 'error' });
                navigate('/login');
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Toolbar />
            <Box alignItems="center" display="flex" flexDirection="column" p={2}>
                {userInfo.loading ? (
                    <CircularProgress size={105} />
                ) : (
                    <>
                        <UserAvatar
                            component={RouterLink}
                            src={
                                userInfo.picture
                                    ? `data:image/*;base64,${userInfo.picture}`
                                    : createImageFromInitials(257, userInfo.name || userInfo.email, colors.color1)
                            }
                            to="/app/account"
                        />
                        <Typography className={classes.name} color="textPrimary" variant="h5">
                            {userInfo.name !== '' ? `${userInfo.name}` : userInfo.email}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {userInfo.role}
                        </Typography>
                    </>
                )}
            </Box>
            <Divider />
            <Box p={0}>
                <List>
                    {items
                        .filter((item) => !((item.title === 'Users' || item.title === 'Dashboard') && userInfo.role !== 'Administrator'))
                        .map((item, index) =>
                            item.type === 'item' ? (
                                <NavItem
                                    sx={{ px: 2, py: 1 }}
                                    href={item.href}
                                    key={index}
                                    title={item.title}
                                    icon={item.icon}
                                    selected={navBarSelected === index}
                                    onClick={() => setNavBarSelected(index)}
                                />
                            ) : (
                                <Divider textAlign="left" key={index}>
                                    {item.title && (
                                        <Typography color="textSecondary" variant="body2" sx={{ fontSize: '14px' }}>
                                            {item.title}
                                        </Typography>
                                    )}
                                </Divider>
                            )
                        )}
                </List>
            </Box>
            <Box flexGrow={1} />
            <Box p={2} m={2} bgcolor="background.dark">
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button color="primary" component="a" size="large" variant="contained" onClick={logout} startIcon={<Input />}>
                        Logout
                    </Button>
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                    sx={{
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' }
                    }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default NavBar;
