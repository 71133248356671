import React from 'react';
import { useSnackbar } from 'notistack';
// view
import { useAppContext } from 'utils/contextLib';
import { createTicket } from 'actions/actions';
import TicketView from '../views/support/TicketView';
// utils

/**
 * Ticket View Controller - View related logic
 * @returns Ticket View
 */
function TicketController() {
    // initiate local variables
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Create ticket
     * @param {*} formData Form fields data
     */
    const onSubmit = async (formData) => {
        const { topic, gwUUID, userEmail, description } = formData;
        const { status } = await createTicket(topic, userEmail, gwUUID, description);
        if (status === 200) {
            enqueueSnackbar(`Ticket was successfully created`, { variant: 'success' });
        } else {
            enqueueSnackbar(`Error creating ticket`, { variant: 'error' });
        }
    };

    // return view
    return <TicketView email={userInfo.email} onSubmit={onSubmit} />;
}

TicketController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default TicketController;
