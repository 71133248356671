import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography, colors, CircularProgress } from '@mui/material';
import { LocalShipping, Event, Person, Build } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    differenceIcon: {
        color: colors.green[900]
    },
    differenceValue: {
        color: colors.green[900],
        marginRight: theme.spacing(1)
    }
}));

function InterventionInfoCard({ className, name, value, color, ...rest }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [updatedValue, setUpdatedValue] = useState(value);
    const avatar = {
        backgroundColor: color,
        height: 56,
        width: 56
    };

    useEffect(() => {
        if (value !== updatedValue) {
            setUpdatedValue(value);
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item align="center" zeroMinWidth>
                        <Typography color="textSecondary" gutterBottom variant="h6" noWrap>
                            {name}
                        </Typography>
                        <Typography color="textPrimary" variant="h5" style={{ overflowWrap: 'break-word' }}>
                            {loading ? <CircularProgress size={22} /> : value}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Avatar style={avatar}>
                            {name === 'DATE' ? (
                                <Event />
                            ) : name === 'GATEWAY' ? (
                                <LocalShipping />
                            ) : name === 'INSTALLER' ? (
                                <Person />
                            ) : (
                                <Build />
                            )}
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

InterventionInfoCard.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
};

export default InterventionInfoCard;
