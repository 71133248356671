import React from 'react';
import PropTypes from 'prop-types';
import semver from 'semver';
import humanizeDuration from 'humanize-duration';
import MUIDataTable from 'mui-datatables';
// material
import { Box, Container, CircularProgress, Button, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function GatewayListView(props) {
    const classes = useStyles();
    // get props
    const {
        gateways,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        isAdmin,
        selectedRows,
        vehicleColumnWidth,
        clientColumnWidth,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleOnRowSelected,
        handleCustomToolbarSelect,
        vehicleColumn,
        clientColumn
    } = props;

    const columnsSize = isAdmin
        ? {
              '&:nth-child(2)': {
                  width: '6vw'
              },
              '&:nth-child(3)': {
                  width: '13vw'
              },
              '&:nth-child(4)': {
                  width: clientColumnWidth
              },
              '&:nth-child(5)': {
                  width: vehicleColumnWidth
              },
              '&:nth-child(6)': {
                  width: '5vw'
              }
          }
        : {
              '&:nth-child(1)': {
                  width: '6vw'
              },
              '&:nth-child(2)': {
                  width: '13vw'
              },
              '&:nth-child(3)': {
                  width: clientColumnWidth
              },
              '&:nth-child(4)': {
                  width: vehicleColumnWidth
              },
              '&:nth-child(5)': {
                  width: '5vw'
              }
          };

    // table columns and rows size
    const tableTheme = createTheme(
        {
            overrides: {
                MUIDataTableHeadCell: {
                    root: columnsSize
                },
                MUIDataTableBodyRow: {
                    root: {
                        height: 121
                    }
                }
            }
        },
        theme
    );

    // table options
    const options = {
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        resizableColumns: false,
        selectableRows: 'multiple',
        // selectableRowsHeader: isAdmin,
        selectableRowsOnClick: true,
        rowsSelected: selectedRows,
        print: false,
        download: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        // customSearchRender: debounceSearchRender(500),
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onRowSelectionChange: handleOnRowSelected,
        customToolbarSelect: handleCustomToolbarSelect,
        // isRowSelectable: () => { return isAdmin },
        customSearch: (searchQuery, currentRow) => {
            let isFound = false;
            currentRow.forEach((col) => {
                if (typeof col === 'object' && !Array.isArray(col) && col !== null) {
                    if (col.name.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                        isFound = true;
                    }
                } else if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
                    isFound = true;
                }
            });
            return isFound;
        }
    };

    // table columns
    const columns = [
        {
            name: 'active',
            label: 'Active',
            options: {
                filter: true,
                filterType: 'dropdown',
                sort: true,
                sortThirdClickReset: true,
                customBodyRender: (value) => {
                    const color = value ? '#10b03b' : '#de1507';
                    return (
                        <Box
                            style={{
                                backgroundColor: color,
                                height: 20,
                                width: 20,
                                borderRadius: '50%'
                            }}
                            ml={1.5}
                        />
                    );
                },
                filterOptions: {
                    names: ['Active', 'Inactive'],
                    logic(active, filterVal) {
                        const show = (filterVal.indexOf('Active') >= 0 && active) || (filterVal.indexOf('Inactive') >= 0 && !active);
                        return !show;
                    }
                }
            }
        },
        {
            name: 'uuid',
            label: 'UUID',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'client',
            label: 'Client',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: clientColumn
            }
        },
        {
            name: 'vehicle',
            label: 'Vehicle',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: vehicleColumn
            }
        },
        {
            name: 'version',
            label: 'Firmware',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'deltaActive',
            label: 'Last Time Active',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const timeMiliseconds = value * 1000;
                    if (value === -1) {
                        return <Typography variant="body2">No Information Available</Typography>;
                    }
                    return humanizeDuration(timeMiliseconds, { maxDecimalPoints: 3, units: ['d', 'h', 'm'], round: true });
                }
            }
        },
        {
            name: 'config',
            label: 'Config',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: (value) => {
                    const uuid = value[0];
                    const version = value[1];
                    let isTestVersionValid = false;
                    let isVersionValid = false;

                    // if version is not empty or test
                    if (semver.valid(version)) {
                        isVersionValid = semver.gte(version, process.env.REACT_APP_GW_VERSION);
                        // check if test version is valid (only for test build)
                        if (semver.valid(process.env.REACT_APP_GW_VERSION_TEST)) {
                            isTestVersionValid = semver.gte(version, process.env.REACT_APP_GW_VERSION_TEST);
                        } else isTestVersionValid = version === process.env.REACT_APP_GW_VERSION_TEST;
                    } else if (version !== '') {
                        isTestVersionValid = version === process.env.REACT_APP_GW_VERSION_TEST;
                    }

                    return (
                        <Button
                            color="primary"
                            href={`config/${uuid}`}
                            variant="contained"
                            size="small"
                            disabled={!(isVersionValid || isTestVersionValid)}
                        >
                            Show
                        </Button>
                    );
                }
            }
        }
    ];

    return (
        <Page className={classes.root} title="Gateways">
            <Container maxWidth={false}>
                <div>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography color="textPrimary" variant="h2">
                                Gateways
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <Box mt={3}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable data={gateways} columns={columns} options={options} />
                    </ThemeProvider>
                </Box>
            </Container>
        </Page>
    );
}

GatewayListView.propTypes = {
    // controller state variables
    gateways: PropTypes.array.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectedRows: PropTypes.array.isRequired,
    vehicleColumnWidth: PropTypes.string.isRequired,
    clientColumnWidth: PropTypes.string.isRequired,
    // controller functions
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleOnRowSelected: PropTypes.func.isRequired,
    handleCustomToolbarSelect: PropTypes.func.isRequired,
    vehicleColumn: PropTypes.func.isRequired,
    clientColumn: PropTypes.func.isRequired
};

export default GatewayListView;
