/**
 * Wearable Client Set View Model - Producer
 */
class WearableClientViewModel {
    constructor(wearableModel, clientModel) {
        // owns wearable and client model
        this.wearableModel = wearableModel;
        this.clientModel = clientModel;
    }

    /**
     * Get clients
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nClientsPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Clients table data
     */
    getClientsTable(token, user, nClientsPerCall, pagToken) {
        return this.clientModel.getClientsTable(token, user, nClientsPerCall, pagToken);
    }

    /**
     * Set the client a wearable belongs to
     * @param {*} wearable Wearable UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    setClientWearable(wearable, owner) {
        return this.wearableModel.setClientWearable(wearable, owner);
    }
}

export default WearableClientViewModel;
