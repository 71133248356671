import React, { useState } from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

function NotesColumn({ notes }) {
    // open dialog
    const [open, setOpen] = useState(false);

    return notes ? (
        <>
            <Button onClick={() => setOpen(true)} color="primary" variant="contained" size="small">
                Show
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4">Release Notes</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'pre-line' }}>
                        {notes}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : (
        <Typography variant="body2">No notes available</Typography>
    );
}

NotesColumn.propTypes = {
    notes: PropTypes.string
};

export default NotesColumn;
