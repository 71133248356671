/**
 * Client List View Model - Producer
 */
class ClientListViewModel {
    constructor(clientModel) {
        // owns client model
        this.clientModel = clientModel;
    }

    /**
     * Get clients
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nClientsPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Clients table data
     */
    getClientsTable(token, user, nClientsPerCall, pagToken) {
        return this.clientModel.getClientsTable(token, user, nClientsPerCall, pagToken);
    }
}

export default ClientListViewModel;
