// actions
import { summarizeGateways, getClients, getClientsConfig, addClient } from 'actions/actions';

/**
 * Client Model - Client data source
 */
class ClientModel {
    /**
     * Get top clients
     * @returns Top 5 clients table data
     */
    async getTopClients() {
        const { data } = await summarizeGateways();
        return data?.clients;
    }

    /**
     * Get clients
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nClientsPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Clients table data
     */
    async getClientsTable(token, user, nClientsPerCall, pagToken) {
        return getClients(token, user, nClientsPerCall, pagToken);
    }

    /**
     * Get clients configuration
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @returns Clients configuration
     */
    async getClientsConfiguration(token, user) {
        return getClientsConfig(token, user);
    }

    /**
     * Add new client
     * @param {*} user User UUID
     * @param {*} name Client name
     * @param {*} type Client type
     * @param {*} account Client account
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async addNewClient(user, name, type, account) {
        return addClient(user, name, type, account);
    }
}

export default ClientModel;
