import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Container,
    Typography,
    CircularProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Page from 'presentation/components/Page';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import { associateGatewayHost, getHosts } from 'actions/actions';
import config from 'utils/config.json';
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';
import { getCurrentSession } from 'aws-auth-cardioid';
import decodeJwt from '../../../../utils/jwt';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(1)': {
                        width: '5vw'
                    },
                    '&:nth-child(2)': {
                        width: '5vw'
                    },
                    '&:nth-child(3)': {
                        width: '5vw'
                    }
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    height: 90
                }
            }
        }
    },
    theme
);

function GatewayListView({ className, ...rest }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { uuid } = useParams();
    const session = getCurrentSession();
    const nHostsPerCall = config.tables.TABLE_ROWS_PER_CALL;
    const [hosts, setHosts] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedVehicle, setSelectedVehicle] = useState('');

    const onSubmit = async (formData) => {
        const { vehicle } = formData;
        const { status } = await associateGatewayHost(uuid, vehicle, true);
        if (status === 200) {
            enqueueSnackbar(`Gateway was successfully associated with host`, { variant: 'success' });
            navigate('/app/gateways', { replace: true });
        } else enqueueSnackbar(`Linking failed: gateway and vehicle must belong to the same client.`, { variant: 'error' });
    };

    const getDissociatedHosts = async () => {
        const user = session.getIdToken().payload['cognito:username'];
        const {
            data: { Hosts, PaginationToken }
        } = await getHosts(nHostsPerCall, pagToken, user);
        if (Hosts) {
            // removes all hosts that are already associated with gateway
            const newhosts = [...hosts, ...Hosts.map((host) => host)];
            setHosts(newhosts);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Hosts.length === nHostsPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nHostsPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of hosts
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of hosts
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getDissociatedHosts().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        if (rowsSelected.length === 1) setSelectedVehicle(hosts[rowsSelected[0]].uuid);
        else setSelectedVehicle('');
    };

    const options = {
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        selectableRows: 'single',
        selectableRowsOnClick: true,
        selectToolbarPlacement: 'none',
        resizableColumns: false,
        print: false,
        download: false,
        filter: false,
        viewColumns: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onRowSelectionChange: handleOnRowSelected
    };

    const columns = [
        {
            name: 'uuid',
            label: 'UUID',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'client',
            label: 'Client',
            options: {
                filter: false,
                sort: false
            }
        }
    ];

    useEffect(() => {
        getDissociatedHosts().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page className={classes.root} title="Set Vehicle">
            <Container maxWidth={false}>
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Set Vehicle
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                vehicle: selectedVehicle
                            }}
                            validationSchema={Yup.object().shape({
                                vehicle: Yup.string()
                                    .max(50, 'Vehicle UUID must be at most 50 characters')
                                    .required('Vehicle UUID is required')
                            })}
                            onSubmit={onSubmit}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form onSubmit={handleSubmit} className={clsx(classes.root, className)} {...rest}>
                                    <Card>
                                        <CardHeader subheader={`Gateway ${uuid}`} title="" />
                                        <Divider />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        error={Boolean(touched.vehicle && errors.vehicle)}
                                                        fullWidth
                                                        helperText={touched.vehicle && errors.vehicle}
                                                        placeholder="Select the vehicle from the list or write the UUID yourself."
                                                        label="Vehicle UUID"
                                                        name="vehicle"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.vehicle}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <Box display="flex" justifyContent="flex-end" p={2}>
                                            <Button color="primary" variant="contained" disabled={isSubmitting} type="submit">
                                                Set
                                            </Button>
                                        </Box>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Box mt={3}>
                            <ThemeProvider theme={tableTheme}>
                                <MUIDataTable title="Available Vehicles" data={hosts} columns={columns} options={options} />
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

GatewayListView.propTypes = {
    className: PropTypes.string
};

export default GatewayListView;
