// actions
import { getInterventions, getIntervention, getInterventionImages, getInterventionImage } from 'actions/actions';

/**
 * Intervention Model - Intervention data source
 */
class InterventionModel {
    /**
     * Get interventions
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @returns Intervention table data
     */
    async getInterventionTable(token, user) {
        return getInterventions(token, user);
    }

    /**
     * Get intervention info
     * @param {*} token AWS token
     * @param {*} int Intervention UUID
     * @returns Intervention info
     */
    async getInterventionInfo(token, int) {
        return getIntervention(token, int);
    }

    /**
     * Get intervention images
     * @param {*} token AWS token
     * @param {*} int Intervention UUID
     * @returns Intervention images
     */
    async getInterventionInfoImages(token, int) {
        return getInterventionImages(token, int);
    }

    /**
     * Get intervention image
     * @param {*} token AWS token
     * @param {*} i Intervention URL
     * @returns Intervention image
     */
    async getInterventionInfoImage(token, i) {
        return getInterventionImage(token, i);
    }
}

export default InterventionModel;
