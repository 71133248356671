import axios from 'axios';
import { refreshToken } from 'aws-auth-cardioid';
import { arrayBufferToBase64 } from 'utils/jwt';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_API_URL } = process.env;

export const api = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
});

const paginationHeaders = (limit, paginationToken) => ({
    'PAGINATION-TOKEN': paginationToken || '',
    'PAGINATION-LIMIT': limit || ''
});

export const updateToken = async (action, navigate) => {
    try {
        const token = await refreshToken();
        api.defaults.headers.common['AUTH-TOKEN-AWS'] = token;
        action.headers['AUTH-TOKEN-AWS'] = token;
    } catch (e) {
        navigate('/login');
    }
    return action;
};

export const APIProvider = ({ children }) => {
    const navigate = useNavigate();
    api.interceptors.request.use(
        async (config) => {
            if (!api.defaults.headers['AUTH-TOKEN-AWS']) {
                config = await updateToken(config, navigate);
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            let originalRequest = error.config;
            if (error?.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                originalRequest = await updateToken(originalRequest, navigate);
                return api(originalRequest);
            }
            return error;
        }
    );
    return children;
};
export const getHosts = async (limit, paginationToken, uuid) =>
    api.post('/mgmt/hosts', {
        limit,
        paginationToken,
        uuid
    });

export const getGateways = async (uuid, limit, paginationToken) =>
    api.post('/mgmt/gateways', {
        limit,
        paginationToken,
        uuid
    });

export const getCardioWheels = async (limit, paginationToken) =>
    api.get('/mgmt/cardiowheels', {
        headers: paginationHeaders(limit, paginationToken)
    });

export const getClients = async (uuid, limit, paginationToken) =>
    api.post('/mgmt/owners', {
        limit,
        paginationToken,
        uuid
    });

export const getWearables = async (limit, paginationToken) =>
    api.get('/mgmt/wearables', {
        headers: { ...paginationHeaders(limit, paginationToken) }
    });

export const getWearablesQuery = async (limit, paginationToken, query) =>
    api.post(
        '/mgmt/wearables/search',
        { query },
        {
            headers: paginationHeaders(limit, paginationToken)
        }
    );

export const getCardioWheelsQuery = async (limit, paginationToken, query) =>
    api.post(
        '/mgmt/cardiowheels/search',
        { query },
        {
            headers: paginationHeaders(limit, paginationToken)
        }
    );

export const getUsersQuery = async (limit, paginationToken, query) =>
    api.post(
        '/mgmt/users/search',
        { query },
        {
            headers: paginationHeaders(limit, paginationToken)
        }
    );

export const getAvailableFirmwares = async (limit, paginationToken) =>
    api.post('/mgmt/firmware', {
        limit,
        paginationToken,
        available: true
    });

export const getAllFirmwares = async (limit, paginationToken) =>
    api.post('/mgmt/firmware', {
        limit,
        paginationToken,
        available: false
    });

/**
 * Get clients configuration (Account Names and Types)
 * @param {*} uuid Admin uuid
 * @returns
 */
export const getClientsConfig = async (uuid) => api.get(`/mgmt/owners/config/${uuid}`);

/**
 * Create new client
 * @param {*} admin Admin UUID
 * @param {*} name Client name
 * @param {*} type Client type name
 * @param {*} account Client account name
 * @returns Endpoint data - 200 OK
 */
export const addClient = async (admin, name, type, account) =>
    api.post('/mgmt/owners/create', {
        admin,
        name,
        type,
        account
    });
/**
 * Create new wearable
 * @param {*} mac Wearable Mac Address
 * @param {*} hardware Wearable Hardware Name
 * @returns Endpoint data - 200 OK
 */
export const addWearable = async (mac, hardware) =>
    api.post('/mgmt/wearable/create', {
        mac,
        hardware
    });

/**
 * Create new CardioWheel
 * @param {*} mac Wearable Mac Address
 * @param {*} hardware Wearable Hardware Name
 * @param {*} firmware Wearable Hardware Name
 * @returns Endpoint data - 200 OK
 */
export const addCardioWheel = async (mac, hardware, firmware) =>
    api.post('/mgmt/cardiowheel/create', {
        mac,
        hardware,
        firmware
    });

export const getUsers = async (limit, paginationToken) =>
    api.get('/mgmt/users', {
        headers: paginationHeaders(limit, paginationToken)
    });

export const createUser = async (name, email, role) =>
    api.post('/mgmt/users', {
        name,
        email,
        role
    });

export const deletePassword = async (uuid) =>
    api.delete('/mgmt/users/password', {
        data: { uuid }
    });

export const getUser = async () => api.get('/mgmt/user');

export const deleteUser = async (uuid) =>
    api.delete('/mgmt/users', {
        data: { uuid }
    });

export const updateUser = async (name, timezone) =>
    api.patch('/mgmt/user', {
        name,
        timezone
    });

export const getPicture = async (uuid) =>
    new Promise((resolve) => {
        api.get(`/mgmt/users/${uuid}/picture`, {
            responseType: 'arraybuffer',
            validateStatus: false
        })
            .then((response) => resolve(response.status === 200 ? arrayBufferToBase64(response.data) : null))
            .catch(() => resolve(null));
    });

export const updatePicture = async (formData, uuid) =>
    api
        .post(`/mgmt/users/${uuid}/picture`, formData, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => arrayBufferToBase64(response.data))
        .catch(async (err) => {
            if (err.response) {
                if (err.response.status === 401) return 0;
            }
            return null;
        });

export const updateRole = async (uuid, role) =>
    api.patch('/mgmt/users', {
        uuid,
        role
    });

export const updateLimitedClients = async (admin, uuid, client) =>
    api.post(`/mgmt/users/${uuid}/clients`, {
        admin,
        client
    });

export const getLimitedClients = async (uuid) => api.get(`/mgmt/users/${uuid}/clients`);

export const getUniqueOwners = async () => api.get('/mgmt/owners');

/**
 * Get dashboard datarole
 * @returns
 */
export const summarizeGateways = async () => api.get('/mgmt/dashboard');

export const configGateway = async (device) =>
    api.post('/mgmt/device/config', {
        device
    });

export const updateGatewayConfig = async (device, data) =>
    api.post('/mgmt/device/config/update', {
        device,
        data
    });

export const associateGatewayHost = async (uuid, host, associate = false) =>
    api.post(`/mgmt/gateways/${uuid}/hosts/${host}`, {
        associate
    });

export const createTicket = async (topic, email, gw, description) =>
    api.post('/mgmt/support/tickets', {
        topic,
        email,
        gw,
        description
    });

export const getInterventions = async (uuid) => api.get(`/mgmt/users/${uuid}/interventions`);

export const getIntervention = async (uuid) =>
    api.get(`/mgmt/interventions/${uuid}`, {
        withCredentials: true,
        responseType: 'json'
    });

export const getInterventionImages = async (uuid) => api.get(`/mgmt/interventions/${uuid}/images`);

export const getInterventionImage = async (link) =>
    api
        .get(link, {
            responseType: 'arraybuffer'
        })
        .then((response) => arrayBufferToBase64(response.data))
        .catch(async (err) => {
            if (err.response) {
                if (err.response.status === 401) return 0;
            }
            return null;
        });

export const setClientFromGateway = async (uuid, owner, associate) =>
    api.put(`/mgmt/gateways/${uuid}/owners/${owner}`, {
        associate
    });

export const putAssignClientToCardioWheel = async (cardioWheelUUID, ownerUUID) =>
    api.put('/mgmt/cardiowheel/owner', {
        cardiowheel: cardioWheelUUID,
        owner: ownerUUID
    });
export const removeClientFromCardioWheel = async (cardioWheelUUID) =>
    api.delete('/mgmt/cardiowheel/owner', {
        data: { cardiowheel: cardioWheelUUID }
    });

export const putAssignGatewayToCardioWheel = async (cardioWheelUUID, gatewayUUID) =>
    api.put('/mgmt/cardiowheel/gateway', {
        cardiowheel: cardioWheelUUID,
        gateway: gatewayUUID
    });
export const removeGatewayFromCardioWheel = async (cardioWheelUUID) =>
    api.delete('/mgmt/cardiowheel/gateway', {
        data: { cardiowheel: cardioWheelUUID }
    });

export const putAssignClientToWearable = async (wearableUUID, ownerUUID) =>
    api.put('/mgmt/wearable/owner', {
        wearable: wearableUUID,
        owner: ownerUUID
    });
export const removeClientFromWearable = async (wearableUUID) =>
    api.delete('/mgmt/wearable/owner', {
        data: { wearable: wearableUUID }
    });

export const removeDriverFromWearable = async (wearableUUID) =>
    api.delete('/mgmt/wearable/driver', {
        data: { wearable: wearableUUID }
    });

export const setClientFromWearable = async (uuid, owner, associate) =>
    api.put(`/mgmt/wearables/${uuid}/owners/${owner}`, {
        associate
    });

/**
 * Update gateway firmware
 * @param {*} gateways gateways
 * @param {*} firmware firmware version
 * @returns Endpoint data - 200 OK
 */
export const updateGatewayFirmware = async (gateways, firmware) =>
    api.post('/mgmt/firmware/deploy', {
        gateways,
        firmware
    });

export const registerFirmwareFile = async (name, extension, checksum, notes, uuid) =>
    api.post('/mgmt/firmware/register', {
        name,
        extension,
        checksum,
        notes,
        uuid
    });

export const uploadRegisterFirmware = async (formData, url) =>
    axios.post(url, formData, {
        responseType: 'json',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

export const confirmFirmwareUpload = async (firmware) =>
    api.post('/mgmt/firmware/register/confirm', {
        firmware
    });

export const getGWAvailableCommands = async () => api.get('/mgmt/commands');

export const sendGWCommand = async (gateway, command) =>
    api.post('/mgmt/command/push', {
        gateway,
        command
    });

export default {};
