import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Box, InputAdornment, CircularProgress } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    input: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#263238'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#263238'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            borderWidth: 1,
            borderColor: '#263238'
        }
    },
    invalid: {
        '&:invalid': {
            border: 'red solid 2px'
        }
    }
});

function Motion({ values, setFieldValue, errors, loading }) {
    const classes = useStyles();

    const handleNumberChange = (event, field) => {
        setFieldValue(field, event.target.value);
    };

    const getTableCellError = (rowIndex, columnIndex, tableName) => {
        if (tableName === 'thresholds') {
            if (rowIndex === 0) {
                if (columnIndex === 0) return errors.thresholds_ha_0;
                if (columnIndex === 1) return errors.thresholds_ha_1;
                if (columnIndex === 2) return errors.thresholds_ha_2;
            } else if (rowIndex === 1) {
                if (columnIndex === 0) return errors.thresholds_hb_0;
                if (columnIndex === 1) return errors.thresholds_hb_1;
                if (columnIndex === 2) return errors.thresholds_hb_2;
            } else {
                if (columnIndex === 0) return errors.thresholds_hc_0;
                if (columnIndex === 1) return errors.thresholds_hc_1;
                if (columnIndex === 2) return errors.thresholds_hc_2;
            }
        } else if (rowIndex === 0) {
            if (columnIndex === 0) return errors.durations_ha_0;
            if (columnIndex === 1) return errors.durations_ha_1;
            if (columnIndex === 2) return errors.durations_ha_2;
        } else if (rowIndex === 1) {
            if (columnIndex === 0) return errors.durations_hb_0;
            if (columnIndex === 1) return errors.durations_hb_1;
            if (columnIndex === 2) return errors.durations_hb_2;
        } else {
            if (columnIndex === 0) return errors.durations_hc_0;
            if (columnIndex === 1) return errors.durations_hc_1;
            if (columnIndex === 2) return errors.durations_hc_2;
        }
        return null;
    };

    const tableTextField = (value, tableMeta, tableName) => {
        const fieldLabel = tableMeta.rowIndex === 0 ? 'ha' : tableMeta.rowIndex === 1 ? 'hb' : 'hc';
        return (
            <TextField
                name={`${tableName}_${fieldLabel}[${tableMeta.columnIndex}]`}
                variant="outlined"
                size="small"
                onChange={(event) => {
                    handleNumberChange(event, `${tableName}_${fieldLabel}_${tableMeta.columnIndex}`);
                }}
                error={Boolean(getTableCellError(tableMeta.rowIndex, tableMeta.columnIndex, tableName))}
                helperText={getTableCellError(tableMeta.rowIndex, tableMeta.columnIndex, tableName)}
                defaultValue={value}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{fieldLabel}</InputAdornment>
                }}
            />
        );
    };

    const dataThresholds = [
        {
            low: values.thresholds_ha_0,
            medium: values.thresholds_ha_1,
            high: values.thresholds_ha_2
        },
        {
            low: values.thresholds_hb_0,
            medium: values.thresholds_hb_1,
            high: values.thresholds_hb_2
        },
        {
            low: values.thresholds_hc_0,
            medium: values.thresholds_hc_1,
            high: values.thresholds_hc_2
        }
    ];

    const dataDurations = [
        {
            low: values.durations_ha_0,
            medium: values.durations_ha_1,
            high: values.durations_ha_2
        },
        {
            low: values.durations_hb_0,
            medium: values.durations_hb_1,
            high: values.durations_hb_2
        },
        {
            low: values.durations_hc_0,
            medium: values.durations_hc_1,
            high: values.durations_hc_2
        }
    ];

    const columns = (table) => [
        {
            name: 'low',
            label: 'Low Severity',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => tableTextField(value, tableMeta, table)
            }
        },
        {
            name: 'medium',
            label: 'Medium Severity',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => tableTextField(value, tableMeta, table)
            }
        },
        {
            name: 'high',
            label: 'High Severity',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => tableTextField(value, tableMeta, table)
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'simple', // responsive type
        resizableColumns: false,
        pagination: false,
        selectableRows: 'none',
        print: false,
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        }
    };

    return (
        <>
            <Grid classes={classes.item} item>
                <Typography color="textPrimary" gutterBottom variant="h6">
                    Motion
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.motionEnable}
                                    onChange={() => setFieldValue('motionEnable', !values.motionEnable)}
                                />
                            }
                            label="Enable"
                        />
                        <Box mt={2}>
                            <TextField
                                disabled={!values.motionEnable}
                                label="I2C interface"
                                name="interface"
                                type="text"
                                value={values.motionInterface}
                                variant="outlined"
                                size="small"
                                classes={classes.input}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                        <Box mt={2.5}>
                            <TextField
                                disabled={!values.motionEnable}
                                label="IMU sensor"
                                name="sensor"
                                type="text"
                                value={values.sensor}
                                variant="outlined"
                                size="small"
                                classes={classes.input}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                        </Box>
                    </>
                )}
            </Grid>
            <Grid classes={classes.item} item md={4}>
                {!loading && <MUIDataTable title="Thresholds" data={dataThresholds} columns={columns('thresholds')} options={options} />}
            </Grid>
            <Grid classes={classes.item} item md={4}>
                {!loading && <MUIDataTable title="Durations" data={dataDurations} columns={columns('durations')} options={options} />}
            </Grid>
        </>
    );
}

Motion.propTypes = {
    // className: PropTypes.string,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    errors: PropTypes.object,
    // touched: PropTypes.object,
    loading: PropTypes.bool
};

export default Motion;
