import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Box, Chip, CircularProgress, Autocomplete } from '@mui/material';
import config from 'utils/config.json';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    chip: {
        margin: theme.spacing(0.3)
    },
    input: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#263238'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#263238'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            borderWidth: 1,
            borderColor: '#263238'
        }
    }
}));

function DashCam(props) {
    const classes = useStyles();

    const handleNumberChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            props.setFieldValue('eventPeriod', event.target.value);
        }
    };

    const chips = props.values.triggers.map((option) => {
        const label = option.title || option;
        return (
            <Chip
                key={label}
                label={label}
                color="primary"
                size="small"
                classes={classes.chip}
                onDelete={() => {
                    props.setFieldValue(
                        'triggers',
                        props.values.triggers.filter((entry) => entry !== option)
                    );
                }}
            />
        );
    });

    return (
        <Grid item md={4} sm={6} xs={12} direction="column">
            <Typography color="textPrimary" gutterBottom variant="h6">
                Dash Cam
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.dashcamEnable}
                                    onChange={() => props.setFieldValue('dashcamEnable', !props.values.dashcamEnable)}
                                />
                            }
                            label="Enable"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.dashcamEnable}
                                    checked={props.values.upload}
                                    onChange={() => props.setFieldValue('upload', !props.values.upload)}
                                />
                            }
                            label="Video uploading"
                        />
                    </Grid>
                    <Box mt={2}>
                        <TextField
                            disabled={!props.values.dashcamEnable}
                            type="text"
                            label="Periodic recording"
                            value={props.values.eventPeriod}
                            variant="outlined"
                            size="small"
                            onChange={handleNumberChange}
                        />
                    </Box>
                    <Box mt={2.5}>
                        <TextField
                            disabled={!props.values.dashcamEnable}
                            label="Camera model"
                            name="camera"
                            type="text"
                            value={props.values.camera}
                            variant="outlined"
                            size="small"
                            className={classes.input}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </Box>
                    <Box mt={0.5}>
                        <Autocomplete
                            disabled={!props.values.dashcamEnable}
                            multiple
                            limitTags={2}
                            autoHighlight
                            id="triggers"
                            size="small"
                            onChange={(e, newValue) => props.setFieldValue('triggers', newValue)}
                            options={Object.keys(config.gateway.triggers)}
                            getOptionLabel={(option) => option}
                            renderTags={() => {}}
                            value={props.values.triggers}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={Boolean(props.touched.triggers && props.errors.triggers)}
                                    helperText={props.touched.triggers && props.errors.triggers}
                                    label="Camera event triggers"
                                    name="triggers"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                />
                            )}
                        />
                        <div>{chips}</div>
                    </Box>
                </>
            )}
        </Grid>
    );
}

DashCam.propTypes = {
    className: PropTypes.string,
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default DashCam;
