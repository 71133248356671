import React from 'react';
import WearableModel from '../../dataaccess/models/wearable';
import WearableListViewModel from '../../presentation/viewModels/wearable/WearableListViewModel';
import WearableListController from '../../presentation/controllers/wearable/WearableListController';

/**
 * Wearable List Provider - Connects all de MVVM logic together
 * @returns Wearable List Controller
 */
function WearableListProvider() {
    // instantiate wearable list view model with wearable model
    const wearableListViewModel = new WearableListViewModel(new WearableModel());

    return <WearableListController viewModel={wearableListViewModel} />;
}

export default WearableListProvider;
