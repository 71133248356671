import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
// utils
import config from 'utils/config.json';
import decodeJwt from '../../../utils/jwt';
import GatewayFirmwareView from '../../views/gateway/GatewayFirmwareView';
import { getAvailableFirmwares, updateGatewayFirmware } from '../../../actions/actions';

/**
 * Gateway Firmware View Controller - View related logic
 * @returns Gateway Firmware View
 */
function GatewayFirmwareController() {
    // initiate local variables
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const nFirmwaresPerCall = config.tables.TABLE_ROWS_PER_CALL;
    // state variables
    const { gateways } = location.state;
    const [firmwares, setFirmwares] = useState([]);
    const [selectedFirmware, setSelectedFirmware] = useState('');
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);

    /**
     * Get firmwares available
     */
    const getFirmwares = async () => {
        const {
            data: { Firmwares, PaginationToken }
        } = await getAvailableFirmwares(nFirmwaresPerCall, pagToken);
        if (Firmwares) {
            const newfirmwares = [...firmwares, ...Firmwares.filter((f) => f.available !== false).map((firmware) => firmware)];
            setFirmwares(newfirmwares);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Firmwares.length === nFirmwaresPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nFirmwaresPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of clients
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Update gateways firmware
     */
    const onSubmit = async () => {
        const newGateways = [...gateways.map((gw) => gw.uuid)];
        const { status, data } = await updateGatewayFirmware(newGateways, selectedFirmware);
        let successMsg = '';
        let success = false;
        if (status === 200) {
            Object.keys(data || []).forEach((id) => {
                const value = data[id];
                if (!value) {
                    successMsg = 'The other ';
                    enqueueSnackbar(`Gateway ${id} firmware was not updated`, { variant: 'error' });
                } else success = true;
            });
            if (success) enqueueSnackbar(`${successMsg}Gateway firmware was successfully updated`, { variant: 'success' });
            navigate('/app/gateways', { replace: true });
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/app/gateways', { replace: true, state: location });
        }
    };

    /**
     * Change rows per page
     * @param {*} newRowsPerPage Rows per page number
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * Change page
     * @param {*} currentPage Current page
     */
    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of hosts
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getFirmwares().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    /**
     * On select row - set firmware
     * @param {*} currentRowsSelected Current rows selected
     * @param {*} allRowsSelected All rows selected
     * @param {*} rowsSelected Rows selected
     */
    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        if (rowsSelected.length === 1) setSelectedFirmware(firmwares[rowsSelected[0]].uuid);
        else setSelectedFirmware('');
    };

    /**
     * Custom sort by date
     * @param {*} data Table data
     * @param {*} colIndex Column index
     * @param {*} order Order
     * @returns New data ordered by date
     */
    const handleCustomSort = (data, colIndex, order) => {
        if (colIndex === 2) {
            return data.sort((a, b) => {
                const dateA = new Date(a.data[colIndex]).getTime();
                const dateB = new Date(b.data[colIndex]).getTime();
                return (dateA < dateB ? -1 : 1) * (order === 'desc' ? 1 : -1);
            });
        }

        return data.sort((a, b) => (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1));
    };

    useEffect(() => {
        getFirmwares().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <GatewayFirmwareView
            gateways={gateways}
            firmwares={firmwares}
            selectedFirmware={selectedFirmware}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleOnRowSelected={handleOnRowSelected}
            handleCustomSort={handleCustomSort}
            onSubmit={onSubmit}
        />
    );
}

export default GatewayFirmwareController;
