import { Button, Chip } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { useAppContext } from 'utils/contextLib';
import PropTypes from 'prop-types';

function Limit({ uuid, role, disable }) {
    const { userInfo } = useAppContext();
    const currentRole = userInfo.role;
    return (
        <Button
            color="secondary"
            size="small"
            style={{ textAlign: 'center' }}
            href={`restrictGateways/${uuid}`}
            disabled={currentRole !== 'Administrator' ? true : role === 'Administrator' || disable}
        >
            Change Permissions
        </Button>
    );
}

Limit.propTypes = {
    uuid: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired
};

const columns = [
    {
        name: 'name',
        label: 'Name',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'email',
        label: 'Email',
        options: {
            filter: false,
            sort: false
        }
    },
    {
        name: 'role',
        label: 'Role',
        options: {
            filter: false,
            sort: false,
            sortThirdClickReset: false
        }
    },
    {
        name: 'created',
        label: 'Registration date',
        options: {
            filter: false,
            filterType: 'dropdown',
            sort: false,
            sortThirdClickReset: false,
            customBodyRender: (value) => moment(value).fromNow()
        }
    },
    {
        name: 'disabled',
        label: 'Enabled',
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => <Chip color="primary" label={value ? 'Disabled' : 'Enabled'} size="small" />
        }
    },
    {
        name: 'uuid',
        options: {
            viewColumns: false,
            display: false,
            filter: false,
            sort: false
        }
    },
    {
        name: 'limit',
        label: 'Limit Clients',
        options: {
            filter: false,
            sort: false,
            viewColumns: false,
            customBodyRender: (value, tableMeta) => {
                const uuid = tableMeta.rowData[5];
                const role = tableMeta.rowData[2];
                const disable = tableMeta.rowData[4];
                return <Limit uuid={uuid} role={role} disable={disable} />;
            }
        }
    }
];

export default columns;
