import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, CircularProgress } from '@mui/material';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { getUsers, updateRole, deleteUser, createUser, getUsersQuery } from 'actions/actions';
import Page from 'presentation/components/Page';
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import { theme } from 'presentation/theme';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/system';
import Toolbar from './Toolbar';
import columns from './columns';
import CustomToolbarSelect from './CustomToolbarSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(6)': {
                        width: 180
                    }
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    height: 90
                }
            }
        }
    },
    theme
);

function UserListView() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [customers, setCustomers] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [pagesSelected, setPagesSelected] = useState([]);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [selectedRowsValue, setSelectedRowsValue] = useState([]);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagination, setPagination] = useState({ 0: null });
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const { userInfo } = useAppContext();
    const navigate = useNavigate();

    const getCustomers = async (page, limit, query) => {
        setLoading(true);
        const {
            data,
            headers: { 'pagination-token': paginationToken, 'pagination-count': paginationCount }
        } = query?.length > 0 ? await getUsersQuery(limit, pagination[page], query) : await getUsers(limit, pagination[page]);
        if (data) {
            setCustomers(data);
            setTablePage(page);
            pagination[page + 1] = paginationToken;
            setPagination(pagination);
            setTableMaxPage(Number(paginationCount));
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login');
        }
        setLoading(false);
    };

    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        getCustomers(tablePage, newRowsPerPage, query).then();
    };

    const handleOnChangePage = (currentPage) => {
        getCustomers(currentPage, rowsPerPageValue, query).then();
    };

    const handleOnSearch = (searchText) => {
        setQuery(searchText);
        getCustomers(0, rowsPerPageValue, searchText).then();
    };

    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        setPagesSelected((prevPages) => [...prevPages, tablePage]);
        setSelectedRowsValue(rowsSelected);
    };

    const disableSelectedUsers = (rowsSelect, displayData) => {
        const customersCopy = customers;
        const requests = rowsSelect.map(async (row) => {
            const email = displayData[row].data[1];
            const uuid = displayData[row].data[5];
            const disabled = displayData[row].data[4].props.label === 'Disabled';
            if (!disabled) {
                deleteUser(uuid)
                    .then(() => {
                        customersCopy[row].disabled = true;
                        enqueueSnackbar(`User ${email} was successfully disabled`, { variant: 'success' });
                        setCustomers([...customersCopy]);
                    })
                    .catch(() => {
                        enqueueSnackbar(`Server Error: User ${email} was not disabled`, { variant: 'error' });
                    });
            } else enqueueSnackbar(`User ${email} is already disabled`, { variant: 'warning' });
        });
        Promise.all(requests).then(() => {
            setCustomers([...customersCopy]);
        });
        setSelectedRowsValue([]);
    };

    const enableSelectedUsers = (rowsSelect, displayData) => {
        const customersCopy = customers;
        const requests = rowsSelect.map(async (row) => {
            const name = displayData[row].data[0];
            const email = displayData[row].data[1];
            const role = displayData[row].data[2];
            const enabled = displayData[row].data[4].props.label === 'Enabled';
            if (!enabled) {
                createUser(name, email, role)
                    .then(() => {
                        customersCopy[row].disabled = false;
                        enqueueSnackbar(`User ${email} was successfully enabled`, { variant: 'success' });
                        setCustomers([...customersCopy]);
                    })
                    .catch(() => enqueueSnackbar(`Server Error: User ${email} was not enabled`, { variant: 'error' }));
            } else enqueueSnackbar(`User ${email} is already enabled`, { variant: 'warning' });
        });
        Promise.all(requests).then(() => {
            setCustomers([...customersCopy]);
        });
        setSelectedRowsValue([]);
    };

    const changeUserRole = (rowsSelected, displayData, event) => {
        const customersCopy = customers;
        const requests = rowsSelected.map(async (row) => {
            const email = displayData[row].data[1];
            const role = displayData[row].data[2];
            const uuid = displayData[row].data[5];
            const roleChange = event.target.innerText;
            if (role === 'Administrator') {
                enqueueSnackbar('Cannot change Administrators role', { variant: 'warning' });
            } else if (role === roleChange) {
                enqueueSnackbar(`${email} already has ${roleChange} role`, { variant: 'warning' });
            } else {
                const { status } = await updateRole(uuid, roleChange);
                if (status === 200) {
                    customersCopy[row].role = roleChange;
                    customersCopy[row].limit = [uuid, roleChange, userInfo.role];
                    enqueueSnackbar(`User ${email} role was successfully updated`, { variant: 'success' });
                } else enqueueSnackbar(`Error updating user role`, { variant: 'error' });
            }
        });
        Promise.all(requests).then(() => {
            setCustomers([]);
            setCustomers(customersCopy);
        });
        setSelectedRowsValue([]);
    };

    const handleCustomToolbarSelect = (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
            selectedRows={selectedRows}
            displayData={displayData}
            rowsPerPage={rowsPerPageValue}
            pagesSelected={pagesSelected}
            setSelectedRows={setSelectedRows}
            onDeleteRows={disableSelectedUsers}
            onChangeRole={changeUserRole}
            onEnable={enableSelectedUsers}
        />
    );

    const handleCustomSort = (data, colIndex, order) => {
        if (colIndex === 3) {
            return data.sort((a, b) => {
                const dateA = new Date(a.data[colIndex]).getTime();
                const dateB = new Date(b.data[colIndex]).getTime();
                return (dateA < dateB ? -1 : 1) * (order === 'desc' ? 1 : -1);
            });
        }
        return data.sort((a, b) => (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1));
    };

    const handleIsRowSelectable = () =>
        // prevents selection if user is not admin
        userInfo.role === 'Administrator';
    const options = {
        serverSide: true,
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        selectableRows: 'multiple',
        rowsSelected: selectedRowsValue,
        selectableRowsHeader: userInfo.role === 'Administrator',
        responsive: 'simple', // responsive type
        resizableColumns: false,
        print: false,
        filter: false,
        download: false,
        selectableRowsOnClick: true,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        customSearchRender: debounceSearchRender(400),
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onSearchChange: handleOnSearch,
        customToolbarSelect: handleCustomToolbarSelect,
        customSort: handleCustomSort,
        isRowSelectable: handleIsRowSelectable,
        onRowSelectionChange: handleOnRowSelected
    };

    useEffect(() => {
        getCustomers(tablePage, rowsPerPageValue, query).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Page className={classes.root} title="Users">
            <Container maxWidth={false}>
                <Toolbar />
                <Box mt={3}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable data={customers} columns={columns} options={options} />
                    </ThemeProvider>
                </Box>
            </Container>
        </Page>
    );
}

export default UserListView;
