import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from '@mui/material';

function Gps(props) {
    return (
        <Grid item md={4} sm={6} xs={12} direction="column">
            <Typography color="textPrimary" gutterBottom variant="h6">
                GPS
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.values.gpsEnable}
                                    onChange={() => props.setFieldValue('gpsEnable', !props.values.gpsEnable)}
                                />
                            }
                            label="Enable"
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!props.values.gpsEnable}
                                    checked={props.values.spdFilter}
                                    onChange={() => props.setFieldValue('spdFilter', !props.values.spdFilter)}
                                />
                            }
                            label="Speed Filtering"
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

Gps.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default Gps;
