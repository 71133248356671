/**
 * Dashboard View Model - Producer
 */
class DashboardViewModel {
    constructor(gatewayModel, clientModel) {
        // owns gateway and client model
        this.gatewayModel = gatewayModel;
        this.clientModel = clientModel;
    }

    /**
     * Get summarized gateways
     * @param {*} token AWS token
     * @returns Gateways sumarized
     */
    getGatewaysSummary(token) {
        return this.gatewayModel.getGatewaysSummary(token);
    }

    /**
     * Get top clients
     * @param {*} token AWS token
     * @returns Top 5 clients table data
     */
    getTopClients(token) {
        return this.clientModel.getTopClients(token);
    }
}

export default DashboardViewModel;
