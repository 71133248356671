import React from 'react';
import FirmwareModel from '../../dataaccess/models/firmware';
import GatewayFirmwareViewModel from '../../presentation/viewModels/gateway/GatewayFirmwareViewModel';
import GatewatFirmwareController from '../../presentation/controllers/gateway/GatewayFirmwareController';

/**
 * Gateway Firmware Provider - Connects all de MVVM logic together
 * @returns Gateway Firmware Controller
 */
function GatewayFirmwareProvider() {
    // instantiate firmware view model with firmware model
    const gatewayFirmwareViewModel = new GatewayFirmwareViewModel(new FirmwareModel());

    return <GatewatFirmwareController viewModel={gatewayFirmwareViewModel} />;
}

export default GatewayFirmwareProvider;
