import React, { useState } from 'react';
import {
    Button,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@mui/material';

import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';

function VehicleColumn(props) {
    // open dialog
    const [open, setOpen] = useState(false);

    // get props
    const { value, tableMeta, updateValue, dissociateGatewayHost } = props;

    return (
        <Box>
            {!value.name ? (
                <Button color="primary" href={`associate/${tableMeta.rowData[1]}`} disabled={false} variant="contained" size="small">
                    Set Vehicle
                </Button>
            ) : (
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{value.name}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpen(true)}>
                            <Cancel />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" component="div">
                                <Typography variant="h4">Unset Vehicle</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to unset the vehicle from gateway?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    No
                                </Button>
                                <Button
                                    onClick={async () => {
                                        dissociateGatewayHost(tableMeta, value.uuid, updateValue);
                                    }}
                                    color="primary"
                                    autoFocus
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

VehicleColumn.propTypes = {
    value: PropTypes.object.isRequired,
    tableMeta: PropTypes.object.isRequired,
    updateValue: PropTypes.func,
    dissociateGatewayHost: PropTypes.func.isRequired
};

export default VehicleColumn;
