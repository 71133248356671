import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Box, Chip, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0),
        margin: 0
    },
    text: {
        marginBottom: theme.spacing(0.6)
    },
    chip: {
        margin: theme.spacing(0.3)
    }
}));

function ChipsArray({ title, chipData, onDelete, size }) {
    const classes = useStyles();

    return (
        <Box mt={1.5}>
            <Typography className={classes.text} align="left">
                {title}
            </Typography>
            <Box component="ul" className={classes.root}>
                {chipData.map((data, index) => (
                    <li key={index}>
                        <Chip label={data} className={classes.chip} onDelete={onDelete} color="primary" size={size} />
                    </li>
                ))}
            </Box>
        </Box>
    );
}

ChipsArray.propTypes = {
    title: PropTypes.string.isRequired,
    chipData: PropTypes.array.isRequired,
    onDelete: PropTypes.func,
    size: PropTypes.string.isRequired
};

export default ChipsArray;
