import React from 'react';
import FirmwareModel from '../../dataaccess/models/firmware';
import UploadFirmwareViewModel from '../../presentation/viewModels/firmware/UploadFirmwareViewModel';
import UploadFirmwareController from '../../presentation/controllers/firmware/UploadFirmwareController';

/**
 * Upload Firmware Provider - Connects all de MVVM logic together
 * @returns Upload Firmware Controller
 */
function UploadFirmwareProvider() {
    // instantiate upload firmware view model with firmware model
    const uploadFirmwareViewModel = new UploadFirmwareViewModel(new FirmwareModel());

    return <UploadFirmwareController viewModel={uploadFirmwareViewModel} />;
}

export default UploadFirmwareProvider;
