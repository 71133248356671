/**
 * Add Client View Model - Producer
 */
class AddClientViewModel {
    constructor(clientModel) {
        // owns client model
        this.clientModel = clientModel;
    }

    /**
     * Get clients configuration
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @returns Clients configuration
     */
    getClientsConfiguration(token, user) {
        return this.clientModel.getClientsConfiguration(token, user);
    }

    /**
     * Add new client
     * @param {*} user User UUID
     * @param {*} name Client name
     * @param {*} type Client type
     * @param {*} account Client account
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    addNewClient(user, name, type, account) {
        return this.clientModel.addNewClient(user, name, type, account);
    }
}

export default AddClientViewModel;
