import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
// utils
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import { getCurrentSession } from 'aws-auth-cardioid';
import ClientListView from '../../views/client/ClientListView';
import decodeJwt from '../../../utils/jwt';
import { getClients } from '../../../actions/actions';

/**
 * Client List View Controller - View related logic
 * @returns Clients List View
 */
function ClientListController() {
    // initiate local variables
    const session = getCurrentSession();
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const nClientsPerCall = config.tables.TABLE_ROWS_PER_CALL;
    // state variables
    const [clients, setClients] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const isAdmin = userInfo.role === 'Administrator';

    /**
     * Get clients
     */
    const getClientsTable = async () => {
        const user = session.getIdToken().payload['cognito:username'];
        const {
            data: { Owners, PaginationToken }
        } = await getClients(user, nClientsPerCall, pagToken);
        if (Owners) {
            const newClients = [...clients, ...Owners.map((client) => client)];
            setClients(newClients);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Owners.length === nClientsPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nClientsPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of clients
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * On change page - Next page and previous
     * @param {*} currentPage Current table page
     */
    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of gws
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getClientsTable().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    /**
     * Click on add clients button
     */
    const handleClick = () => {
        navigate('addClient', { state: location });
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getClientsTable().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <ClientListView
            clients={clients}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            isAdmin={isAdmin}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleClick={handleClick}
        />
    );
}

export default ClientListController;
