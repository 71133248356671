import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// view
import DashboardView from '../views/dashboard/DashboardView';
// utils

/**
 * Dashboard View Controller - View related logic
 * @param {*} props View Model
 * @returns Dashboard View
 */
function DashboardController({ viewModel }) {
    // state variables
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [gateways, setGateways] = useState({
        stock: 0,
        delivered: 0,
        installed: 0,
        online: 0
    });

    /**
     * Get top 5 clients
     */
    const getTopClients = async () => {
        const topClients = await viewModel.getTopClients();
        if (topClients) {
            const newClients = [
                ...clients,
                ...topClients.map((client, i) => {
                    client.rank = i + 1;
                    return client;
                })
            ];
            setClients(newClients);
        }
    };

    /**
     * Get gateways summary
     */
    const getGatewaysSummary = async () => {
        const gatewaysSum = await viewModel.getGatewaysSummary();
        if (gatewaysSum) {
            setGateways(gatewaysSum);
        }
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getTopClients()
            .then(getGatewaysSummary)
            .then(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return <DashboardView gateways={gateways} topClients={clients} loading={loading} />;
}

DashboardController.propTypes = {
    viewModel: PropTypes.object.isRequired
};

export default DashboardController;
