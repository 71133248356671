import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'utils/contextLib';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

function Toolbar({ className, ...rest }) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { userInfo } = useAppContext();

    const handleClick = () => {
        navigate('/register', { state: location });
    };

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Users
                    </Typography>
                </Box>
                <Box>
                    {userInfo.role === 'Administrator' && (
                        <Button color="primary" variant="contained" onClick={handleClick} startIcon={<Add />}>
                            Add user
                        </Button>
                    )}
                </Box>
            </Box>
        </div>
    );
}

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
