import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Container, TextField, Typography, Autocomplete } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function AddClientView(props) {
    const classes = useStyles();

    // get props
    const { config, onSubmit } = props;

    return (
        <Page className={classes.root} title="Add Client">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            Type: '',
                            Account: '',
                            Name: ''
                        }}
                        validationSchema={Yup.object().shape({
                            Type: Yup.string()
                                .oneOf(config.types.map((type) => type))
                                .required('Client type is required'),
                            Account: Yup.string().oneOf(config.accounts.map((a) => a)),
                            Name: Yup.string().max(80).required('Name is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Add a new Client
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.Name && errors.Name)}
                                    fullWidth
                                    helperText={touched.Name && errors.Name}
                                    label="Name"
                                    margin="normal"
                                    name="Name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.Name}
                                    variant="outlined"
                                />
                                <Autocomplete
                                    id="type"
                                    options={config.types}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    style={{ width: 550 }}
                                    onOpen={handleBlur}
                                    onChange={(e, value) => {
                                        setFieldValue('Type', value || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(touched.Type && errors.Type)}
                                            fullWidth
                                            helperText={touched.Type && errors.Type}
                                            label="Client Type"
                                            margin="normal"
                                            name="Type"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Type}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    id="account"
                                    options={config.accounts}
                                    getOptionLabel={(option) => option}
                                    style={{ width: 550 }}
                                    onOpen={handleBlur}
                                    onChange={(e, value) => {
                                        setFieldValue('Account', value || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(touched.Account && errors.Account)}
                                            fullWidth
                                            helperText={touched.Account && errors.Account}
                                            label="Client Account"
                                            margin="normal"
                                            name="Account"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Account}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Add Client
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

AddClientView.propTypes = {
    // controller state variables
    config: PropTypes.exact({
        types: PropTypes.array.isRequired,
        accounts: PropTypes.array.isRequired
    }).isRequired,
    // controller functions
    onSubmit: PropTypes.func.isRequired
};

export default AddClientView;
