import React, { useState } from 'react';
import {
    Button,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import PropTypes from 'prop-types';

function ClientColumn({ value, tableMeta, updateValue, unsetClientWearable }) {
    // open dialog
    const [open, setOpen] = useState(false);
    return (
        <Box>
            {!value?.name ? (
                <Button
                    color="primary"
                    href={`wearable/${tableMeta.rowData[0]}/setClient`}
                    disabled={false}
                    variant="contained"
                    size="small"
                >
                    Set Client
                </Button>
            ) : (
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{value?.name || ''}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpen(true)}>
                            <Cancel />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" component="div">
                                <Typography variant="h4">Unset Client</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {!tableMeta.rowData[4]?.uuid
                                        ? 'Are you sure you want to unset the client from wearable?'
                                        : 'Please unset driver first.'}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    {!tableMeta.rowData[4]?.uuid ? 'No' : 'Close'}
                                </Button>
                                {!tableMeta.rowData[4]?.uuid && (
                                    <Button
                                        onClick={async () => {
                                            unsetClientWearable(tableMeta, value.uuid, updateValue).then(() => {
                                                setOpen(false);
                                            });
                                        }}
                                        color="primary"
                                        autoFocus
                                    >
                                        Yes
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

ClientColumn.propTypes = {
    value: PropTypes.object,
    tableMeta: PropTypes.object.isRequired,
    updateValue: PropTypes.func,
    unsetClientWearable: PropTypes.func.isRequired
};

export default ClientColumn;
