import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
// material
import { Box, Container, CircularProgress, Typography, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(2)': {
                        width: '18vw'
                    },
                    '&:nth-child(3)': {
                        width: '18vw'
                    },
                    '&:nth-child(4)': {
                        width: '18vw'
                    }
                }
            }
        }
    },
    theme
);

function WearableListView(props) {
    const classes = useStyles();

    // get props
    const {
        wearables,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        isAdmin,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleClick,
        clientColumn,
        driverColumn,
        handleOnSearch
    } = props;

    // table options
    const options = {
        serverSide: true,
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        resizableColumns: false,
        print: false,
        download: false,
        filter: false,
        selectableRows: 'none',
        selectableRowsOnClick: true,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        customSearchRender: debounceSearchRender(400),
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onSearchChange: handleOnSearch
    };

    // table columns
    const columns = [
        {
            name: 'uuid',
            label: 'UUID',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'mac',
            label: 'Mac Adress',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'hardware',
            label: 'Hardware',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'owner',
            label: 'Client',
            options: {
                filter: false,
                sort: false,
                customBodyRender: clientColumn
            }
        },
        {
            name: 'driver',
            label: 'Driver',
            options: {
                filter: false,
                sort: false,
                customBodyRender: driverColumn
            }
        }
    ];

    return (
        <Page className={classes.root} title="Wearables">
            <Container maxWidth={false}>
                <div>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography color="textPrimary" variant="h2">
                                Wearables
                            </Typography>
                        </Box>
                        <Box>
                            {isAdmin && (
                                <Button color="primary" variant="contained" onClick={handleClick} startIcon={<Add />}>
                                    Add wearable
                                </Button>
                            )}
                        </Box>
                    </Box>
                </div>
                <Box mt={3}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable data={wearables} columns={columns} options={options} />
                    </ThemeProvider>
                </Box>
            </Container>
        </Page>
    );
}

WearableListView.propTypes = {
    // controller state variables
    wearables: PropTypes.array.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleOnSearch: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    clientColumn: PropTypes.func.isRequired,
    driverColumn: PropTypes.func.isRequired
};

export default WearableListView;
