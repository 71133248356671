import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function ImageInList({ image, height, number, handleClickOpen }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const size = height;

    const onMouseOver = (e) => {
        e.target.style.opacity = 0.7;
    };

    const onMouseOut = (e) => {
        e.target.style.opacity = 1;
    };

    return (
        <>
            {!isLoaded && <CircularProgress size={height} disableShrink />}
            <Box onClick={handleClickOpen}>
                <img
                    src={image}
                    alt={number}
                    onFocus={onMouseOver}
                    onBlur={onMouseOut}
                    style={isLoaded ? { maxWidth: size, maxHeight: size } : { display: 'none' }}
                    loading="lazy"
                    onLoad={() => setIsLoaded(true)}
                />
            </Box>
        </>
    );
}

ImageInList.propTypes = {
    image: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    handleClickOpen: PropTypes.func.isRequired
};

export default ImageInList;
