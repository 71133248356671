import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
// material
import { Box, Container, CircularProgress, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { Add } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const tableTheme = createTheme(
    {
        overrides: {
            MUIDataTableHeadCell: {
                root: {
                    '&:nth-child(1)': {
                        width: '18vw'
                    },
                    '&:nth-child(2)': {
                        width: '18vw'
                    },
                    '&:nth-child(3)': {
                        width: '18vw'
                    },
                    '&:nth-child(4)': {
                        width: '18vw'
                    },
                    '&:nth-child(5)': {
                        width: '18vw'
                    }
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    height: 70
                }
            }
        }
    },
    theme
);

function FirmwareListView(props) {
    const classes = useStyles();

    // get props
    const {
        firmwares,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        isAdmin,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleCustomSort,
        uploadFirmware,
        notesColumn
    } = props;

    // table columns
    const columns = [
        {
            name: 'available',
            label: 'Available',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const color = value ? '#10b03b' : '#de1507';
                    return (
                        <Box
                            style={{
                                backgroundColor: color,
                                height: 20,
                                width: 20,
                                borderRadius: '50%'
                            }}
                            ml={2.2}
                        />
                    );
                }
            }
        },
        {
            name: 'uuid',
            label: 'UUID',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'created',
            label: 'Created',
            options: {
                filter: true,
                filterType: 'dropdown',
                sort: true,
                sortThirdClickReset: true,
                customBodyRender: (value) => moment.utc(value).format('DD/MM/YYYY HH:mm')
            }
        },
        {
            name: 'notes',
            label: 'Release Notes',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: notesColumn
            }
        }
    ];

    // table options
    const options = {
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        resizableColumns: false,
        print: false,
        download: false,
        filter: false,
        selectableRows: 'none',
        selectableRowsOnClick: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        customSort: handleCustomSort
    };

    return (
        <Page className={classes.root} title="Firmwares">
            <Container maxWidth={false}>
                <div>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography color="textPrimary" variant="h2">
                                Firmwares
                            </Typography>
                        </Box>
                        <Box>
                            {isAdmin && (
                                <Button color="primary" variant="contained" onClick={uploadFirmware} startIcon={<Add />}>
                                    Upload Firmware
                                </Button>
                            )}
                        </Box>
                    </Box>
                </div>
                <Box mt={3}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable data={firmwares} columns={columns} options={options} />
                    </ThemeProvider>
                </Box>
            </Container>
        </Page>
    );
}

FirmwareListView.propTypes = {
    // controller state variables
    firmwares: PropTypes.array.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    // controller functions
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleCustomSort: PropTypes.func.isRequired,
    uploadFirmware: PropTypes.func.isRequired,
    notesColumn: PropTypes.func.isRequired
};

export default FirmwareListView;
