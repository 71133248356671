/*
Generated default profile picture
 */

/**
 * Generates a random color
 * @returns {string}
 */
export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const colors = {
    color1: '#d65e0e'
};

const getInitials = (name) => {
    let initials;
    const nameSplit = name.split(' ');
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return false;
    return initials.toUpperCase();
};

export const createImageFromInitials = (size, name, color) => {
    if (name == null) return '';
    name = getInitials(name);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = size;
    canvas.height = size;

    context.fillStyle = `${color}`;
    context.fillRect(0, 0, size, size);

    context.fillStyle = '#f7f2ea';
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    context.font = `${size / 2}px Roboto`;
    context.fillText(name, size / 2, size / 2);

    return canvas.toDataURL();
};

export default {};
