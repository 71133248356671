// actions
import {
    summarizeGateways,
    getGateways,
    associateGatewayHost,
    setClientFromGateway,
    getGWAvailableCommands,
    sendGWCommand
} from 'actions/actions';

/**
 * Gateway Model - Gateway data source
 */
class GatewayModel {
    /**
     * Get summarized gateways
     * @returns Gateways sumarized
     */
    async getGatewaysSummary() {
        const { data } = await summarizeGateways();
        return data?.summary;
    }

    /**
     * Get gateways
     * @param {*} user User UUID
     * @param {*} nGatewaysPerCall Number of gateways per call
     * @param {*} pagToken Pagination token
     * @returns Gateways table data
     */
    async getGatewaysTable(user, nGatewaysPerCall, pagToken) {
        const { data } = getGateways(user, nGatewaysPerCall, pagToken);
        return data;
    }

    /**
     * Dissociate gateway with host
     * @param {*} gw Gateway UUID
     * @param {*} host Host UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async dissociateGatewayHost(gw, host) {
        return associateGatewayHost(gw, host, false);
    }

    /**
     * Unset the client a gateway belongs to
     * @param {*} gw Gateway UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async unsetClientGateway(gw, owner) {
        return setClientFromGateway(gw, owner, false);
    }

    /**
     * Set the client a gateway belongs to
     * @param {*} gw Gateway UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async setClientGateway(gw, owner) {
        return setClientFromGateway(gw, owner, true);
    }

    /**
     * Get gateway available commands
     * @returns Gateway available commands
     */
    async getAvailableCommands() {
        return getGWAvailableCommands();
    }

    /**
     * Send management command to a specific or multiple gateways
     * @param {*} gateway Gateway UUID
     * @param {*} command Command
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async sendCommand(gateway, command) {
        return sendGWCommand(gateway, command);
    }
}

export default GatewayModel;
