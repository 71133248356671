import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '10px 8px',
        textTransform: 'none',
        width: '100%'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto'
    },
    active: {
        color: theme.palette.primary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium
        },
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const ButtonItem = styled(ListItemButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '8px 8px',
    textTransform: 'none',
    borderRadius: 16,
    width: '100%',
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main
        }
    },
    '&:hover': {
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main
        }
    }
}));

const TextList = styled(ListItemText)(() => ({
    marginRight: 'auto'
}));

function NavItem({ href, icon: Icon, title, ...rest }) {
    const classes = useStyles();

    return (
        <ButtonItem component={RouterLink} to={href} {...rest}>
            {Icon && <Icon className={classes.icon} size="20" />}
            <TextList primary={<Typography variant="h5">{title}</Typography>} />
        </ButtonItem>
    );
}

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
