import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, TextField, Box, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
});

function GeoFencing(props) {
    const classes = useStyles();

    const handleNumberChange = (event, field) => {
        const re = /^[0-9.\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            props.setFieldValue(field, event.target.value);
        }
    };

    return (
        <Grid classes={classes.item} item md={4} sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
                Geo-Fencing
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.values.geoFencingEnable}
                                onChange={() => props.setFieldValue('geoFencingEnable', !props.values.geoFencingEnable)}
                            />
                        }
                        label="Enable"
                    />
                    <Box mt={2}>
                        <TextField
                            disabled={!props.values.geoFencingEnable}
                            type="text"
                            label="Max Dist"
                            value={props.values.maxDist}
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleNumberChange(e, 'maxDist')}
                        />
                    </Box>
                    <Box mt={2.5}>
                        <TextField
                            disabled={!props.values.geoFencingEnable}
                            type="text"
                            label="Max Delta"
                            value={props.values.maxDelta}
                            variant="outlined"
                            size="small"
                            onChange={(e) => handleNumberChange(e, 'maxDelta')}
                        />
                    </Box>
                </>
            )}
        </Grid>
    );
}

GeoFencing.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default GeoFencing;
