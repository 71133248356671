import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
import { getCurrentSession } from 'aws-auth-cardioid';
import { addClient, getClientsConfig } from 'actions/actions';
import AddClientView from '../../views/client/AddClientView';
// utils

/**
 * Add Client View Controller - View related logic
 * @returns Add Client View
 */
function AddClientController() {
    // initiate local variables
    const session = getCurrentSession();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    // state variables
    const [config, setConfig] = useState({
        types: [],
        accounts: []
    });

    /**
     * Get clients configuration
     */
    const getConfig = async () => {
        const uuid = session.getIdToken().payload['cognito:username'];
        const { data } = await getClientsConfig(uuid);
        if (data) setConfig(data);
        else {
            enqueueSnackbar(`Error getting client configuration`, { variant: 'error' });
            navigate(location.state || '/', { replace: true });
        }
    };

    /**
     * Add new client
     * @param {*} values Form fields
     */
    const onSubmit = async (values) => {
        const { Name, Type, Account } = values;
        const uuid = session.getIdToken().payload['cognito:username'];
        const result = await addClient(uuid, Name, Type, Account);
        if (result === 0) {
            enqueueSnackbar(`Authentication Token has expired`, { variant: 'info' });
            navigate('/login', { replace: true, state: location });
        } else if (result?.status === 200) {
            enqueueSnackbar(`Client was successfully created`, { variant: 'success' });
            navigate(location.state || '/', { replace: true });
        } else {
            enqueueSnackbar(`Error creating new client`, { variant: 'error' });
            navigate(location.state || '/', { replace: true });
        }
    };

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getConfig().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return <AddClientView config={config} onSubmit={onSubmit} />;
}

export default AddClientController;
