import React from 'react';
import PropTypes from 'prop-types';
import tzdata from 'tzdata';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography, Autocomplete } from '@mui/material';

function ProfileDetails(props) {
    // get props
    const { userInfo, updateProfileDetails } = props;

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: userInfo.name,
                timezone: userInfo.timezone
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().max(25),
                timezone: Yup.string().oneOf(
                    Object.keys(tzdata.zones).map((zone) => zone),
                    'Timezone must be one of the values in the list'
                )
            })}
            onSubmit={updateProfileDetails}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader subheader="The information can be edited" title="Profile" />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name}
                                        label="Name"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        id="timezone"
                                        options={Object.keys(tzdata.zones)}
                                        autoHighlight
                                        onChange={(e, value) => {
                                            setFieldValue('timezone', value || '');
                                        }}
                                        value={values.timezone}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(touched.timezone && errors.timezone)}
                                                fullWidth
                                                helperText={touched.timezone && errors.timezone}
                                                label="Time Zone"
                                                name="timezone"
                                                onBlur={handleBlur}
                                                value={values.timezone}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box display="flex" p={2}>
                            <Box p={1} flexGrow={1}>
                                <Typography color="error" variant="subtitle2">
                                    {errors.apiError}
                                </Typography>
                            </Box>
                            <Button color="primary" variant="contained" disabled={isSubmitting} type="submit">
                                Save details
                            </Button>
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
}

ProfileDetails.propTypes = {
    // Account View variable props
    userInfo: PropTypes.object.isRequired,
    // Account View functions props
    updateProfileDetails: PropTypes.func.isRequired
};

export default ProfileDetails;
