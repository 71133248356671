/**
 * Ticket View Model - Producer
 */
class TicketViewModel {
    constructor(ticketModel) {
        // owns ticket model
        this.ticketModel = ticketModel;
    }

    /**
     * Create a new ticket
     * @param {*} topic Ticket topic
     * @param {*} userEmail User email
     * @param {*} gwUUID Gateway UUID
     * @param {*} description Ticket description
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    createNewTicket(topic, userEmail, gwUUID, description) {
        return this.ticketModel.createNewTicket(topic, userEmail, gwUUID, description);
    }
}

export default TicketViewModel;
