import React from 'react';
import { Container } from '@mui/material';
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';
import GatewayConfig from './GatewayConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function GatewayConfigView() {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Gateway Configuration">
            <Container maxWidth="lg">
                <GatewayConfig />
            </Container>
        </Page>
    );
}

export default GatewayConfigView;
