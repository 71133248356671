import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { AmplifyConfigure } from 'aws-auth-cardioid';
import * as serviceWorker from './serviceWorker';
import App from './App';

const authConfig = {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
};

// AWSCognito.config.update({
//     region: process.env.REACT_APP_COGNITO_REGION,
//     accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
// });
//

ReactDOM.render(
    <AmplifyConfigure config={authConfig}>
        <BrowserRouter>
            <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
                <App />
            </SnackbarProvider>
        </BrowserRouter>
    </AmplifyConfigure>,
    document.getElementById('root')
);

serviceWorker.unregister();
