import React from 'react';
import PropTypes from 'prop-types';
// material
import { Container, Typography, Grid, Box, TextField, Card, CardContent } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';
import InterventionMap from '../../../components/intervention/InterventionMap';
import InterventionInfoCard from '../../../components/intervention/InterventionInfoCard';
// views
import InterventionImageListView from './InterventionImageListView';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    input: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#263238'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#263238'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            outline: 'none',
            borderWidth: 1,
            borderColor: '#263238'
        },
        marginTop: 30
    }
}));

function InterventionInfoView(props) {
    const classes = useStyles();

    // get props
    const {
        info,
        cols,
        images,
        currentImage,
        leftDisable,
        rightDisable,
        open,
        loading,
        fullScreen,
        handleClickOpen,
        handleClose,
        onChangeImage
    } = props;

    return (
        <Page className={classes.root} title="Intervention Information">
            <Container maxWidth="lg">
                <Grid container spacing={9}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <InterventionInfoCard name="DATE" value={info.date} color="#ed174d" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <InterventionInfoCard name="GATEWAY" value={info.gateway} color="#ee303d" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <InterventionInfoCard name="INSTALLER" value={info.installer} color="#f3742b" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <InterventionInfoCard name="TYPE" value={info.type} color="#ffca05" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card>
                            <CardContent>
                                <Box flexGrow={1}>
                                    <Typography color="textPrimary" variant="h3" align="center">
                                        Comments
                                    </Typography>
                                </Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    name="comment"
                                    type="text"
                                    rows={23.3}
                                    maxRows={23.3}
                                    value={info.comment}
                                    className={classes.input}
                                    style={{ height: '30rem' }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InterventionMap position={info.location} zoom={13} scrollWheelZoom />
                    </Grid>
                    <Grid item xs={12}>
                        <InterventionImageListView
                            cols={cols}
                            rowHeight={180}
                            data={info.images}
                            images={images}
                            currentImage={currentImage}
                            leftDisable={leftDisable}
                            rightDisable={rightDisable}
                            open={open}
                            loading={loading}
                            fullScreen={fullScreen}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            onChangeImage={onChangeImage}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}

InterventionInfoView.propTypes = {
    // controller state variables
    info: PropTypes.exact({
        comment: PropTypes.string.isRequired,
        location: PropTypes.array.isRequired,
        images: PropTypes.array.isRequired,
        date: PropTypes.string.isRequired,
        gateway: PropTypes.string.isRequired,
        installer: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired,
    cols: PropTypes.number.isRequired,
    // controller state variables - From InterventionImageListView
    images: PropTypes.array.isRequired,
    currentImage: PropTypes.exact({
        image: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired
    }).isRequired,
    leftDisable: PropTypes.bool.isRequired,
    rightDisable: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    // controler functions - From InterventionImageListView
    handleClickOpen: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    onChangeImage: PropTypes.func.isRequired
};

export default InterventionInfoView;
