import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
});

function Drowsiness(props) {
    const classes = useStyles();

    return (
        <Grid classes={classes.item} item md={4} sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
                Drowsiness
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.values.drowsinessEnable}
                            onChange={() => props.setFieldValue('drowsinessEnable', !props.values.drowsinessEnable)}
                        />
                    }
                    label="Enable"
                />
            )}
        </Grid>
    );
}

Drowsiness.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default Drowsiness;
