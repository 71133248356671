import React from 'react';
import ClientModel from '../../dataaccess/models/client';
import ClientListViewModel from '../../presentation/viewModels/client/ClientListViewModel';
import ClientListController from '../../presentation/controllers/client/ClientListController';

/**
 * Client List Provider - Connects all de MVVM logic together
 * @returns Client List Controller
 */
function ClientListProvider() {
    // instantiate client list view model with client model
    const clientListViewModel = new ClientListViewModel(new ClientModel());

    return <ClientListController viewModel={clientListViewModel} />;
}

export default ClientListProvider;
