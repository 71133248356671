import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
// material
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// view
import InterventionInfoView from '../../views/intervention/InterventionInfoView';
import { getIntervention, getInterventionImage, getInterventionImages } from '../../../actions/actions';
// utils

/**
 * Intervention Info View Controller - View related logic
 * @returns Intervention Info View
 */
function InterventionInfoController() {
    // initiate local variables
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { int } = useParams();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    // state variables
    const [cols, setCols] = useState(2);
    const [info, setInfo] = useState({
        comment: '',
        location: [38.736946, -9.142685],
        images: [],
        date: '',
        gateway: '',
        installer: '',
        type: ''
    });
    // image list state variables
    const [images, setImages] = useState([]);
    const [currentImage, setCurrentImage] = useState({
        image: '',
        number: 0
    });
    const [leftDisable, setLeftDisable] = useState(false);
    const [rightDisable, setRightDisable] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    /**
     * Get current intervention info
     */
    const getInterventionInfo = async () => {
        const { data: result } = await getIntervention(int);
        const { data: images } = await getInterventionImages(int);
        if (result && images) {
            setInfo({
                comment: result.comment ? result.comment : '',
                location: [result.location.lat, result.location.lon],
                images,
                date: moment.utc(result.date * 1000).format('DD/MM/YYYY HH:mm'),
                gateway: result.device,
                installer: result.installer,
                type: result.type
            });
            if (images.length === 1) setCols(1);
            else if (images.length > 2 && images.length <= 5) setCols(3);
            else if (images.length > 5) setCols(Math.floor(images.length / 2));
        } else if (result === null || images === null) {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login');
        }
    };

    /**
     * Get intervention image
     * @param {*} i Intervention image url
     */
    const getImage = async (i) => {
        const image = await getInterventionImage(i);
        if (image) {
            setImages((oldImages) => [...oldImages, `data:image/*;base64,${image}`]);
        } else if (image === 0) {
            enqueueSnackbar(`Authentication Token has expired`, { variant: 'info' });
            navigate('/login');
        }
    };

    /**
     * Handle click on open intervention image
     * @param {*} e
     */
    const handleClickOpen = (e) => {
        setOpen(true);
        setCurrentImage({
            image: e.target.src,
            number: Number(e.target.alt)
        });
        setLeftDisable(Number(e.target.alt) === 1);
        setRightDisable(Number(e.target.alt) === images.length);
    };

    /**
     * Handle close image dialog
     */
    const handleClose = () => {
        setOpen(false);
    };

    /**
     * Handle image change when dialog activated
     * @param {*} leftOrRight Left click or right click
     * @param {*} page Dialog page
     */
    const onChangeImage = (leftOrRight, page) => {
        if (fullScreen) {
            setCurrentImage({
                image: images[page - 1],
                number: page
            });
        } else if (leftOrRight === 'l') {
            setRightDisable(false);
            if (currentImage.number > 1) {
                setCurrentImage({
                    image: images[currentImage.number - 2],
                    number: currentImage.number - 1
                });
            }
            // inside on change current image number does not update (only after)
            // so use de second image instead of first
            if (currentImage.number - 1 === 1) setLeftDisable(true);
        } else {
            setLeftDisable(false);
            if (currentImage.number < images.length) {
                setCurrentImage({
                    image: images[currentImage.number],
                    number: currentImage.number + 1
                });
            }
            // inside on change current image number does not update (only after)
            // so use de penultimate image instead of last
            if (currentImage.number + 1 === images.length) setRightDisable(true);
        }
    };

    /**
     * Get intervention info data asynchronously
     */
    useEffect(() => {
        getInterventionInfo().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Get intervention image asynchronously
     */
    useEffect(() => {
        info.images.map((img) => getImage(img).then());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info.images]);

    /**
     * Update loading if all images are
     * obtained from the endpoint
     */
    useEffect(() => {
        if (images.length === info.images.length) {
            setLoading(false);
        }
    }, [info.images, images]);

    // return view
    return (
        <InterventionInfoView
            info={info}
            cols={cols}
            images={images}
            currentImage={currentImage}
            leftDisable={leftDisable}
            rightDisable={rightDisable}
            open={open}
            loading={loading}
            fullScreen={fullScreen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            onChangeImage={onChangeImage}
        />
    );
}

InterventionInfoController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default InterventionInfoController;
