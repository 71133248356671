import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography, CircularProgress } from '@mui/material';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    }
}));
const InstalledAvatar = styled(Avatar)(() => ({
    backgroundColor: '#f3742b',
    height: 56,
    width: 56
}));
function GatewaysInstalled({ className, value, loading, ...rest }) {
    const classes = useStyles();

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardContent>
                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">
                            GATEWAYS INSTALLED
                        </Typography>
                        <Typography color="textPrimary" variant="h3">
                            {loading ? <CircularProgress size={22} /> : value}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <InstalledAvatar>
                            <BeenhereIcon />
                        </InstalledAvatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

GatewaysInstalled.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired
};

export default GatewaysInstalled;
