import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography, Autocomplete } from '@mui/material';
import Page from 'presentation/components/Page';
import { useSnackbar } from 'notistack';
import { createUser } from 'actions/actions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const roles = [{ group: 'Administrator' }, { group: 'User' }, { group: 'Installer' }];

function handleErrors(errorName) {
    if (errorName === 'UnsupportedUserStateException') return 'User is already confirmed.';
    if (errorName === null) return 'Server error.';
    return null;
}

function RegisterView() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    async function onSubmit(formData, actions) {
        const { email, role } = formData;
        createUser(email, email, role)
            .then(() => {
                navigate(location.state || '/', { replace: true });
                enqueueSnackbar('User was successfully created', { variant: 'success' });
            })
            .catch((e) => {
                const errorMessage = handleErrors(e);
                actions.setFieldError('apiError', errorMessage);
            });
    }

    return (
        <Page className={classes.root} title="Register">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            role: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            role: Yup.string()
                                .oneOf(
                                    roles.map((role) => role.group),
                                    'User Role must be one of the following values: Administrator, User or Installer'
                                )
                                .required('User Role is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Create an account
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2">
                                        Use email to create an account
                                    </Typography>
                                </Box>
                                <Typography align="left" color="error" variant="caption">
                                    {errors.apiError}
                                </Typography>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <Autocomplete
                                    id="role"
                                    options={roles}
                                    getOptionLabel={(option) => option.group}
                                    style={{ width: 550 }}
                                    onOpen={handleBlur}
                                    onChange={(e, value) => {
                                        setFieldValue('role', value?.group || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(touched.role && errors.role)}
                                            fullWidth
                                            helperText={touched.role && errors.role}
                                            label="User Role"
                                            margin="normal"
                                            name="role"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.role}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Add User
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

export default RegisterView;
