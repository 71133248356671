/**
 * Firmware List View Model - Producer
 */
class FirmwareListViewModel {
    constructor(firmwareModel) {
        // owns firmware model
        this.firmwareModel = firmwareModel;
    }

    /**
     * Get firmwares
     * @param {*} token AWS token
     * @param {*} nFirmwaresPerCall Number of firmwares per call
     * @param {*} pagToken Pagination token
     * @returns Firmewares table data
     */
    getFirmwaresTable(token, nFirmwaresPerCall, pagToken) {
        return this.firmwareModel.getFirmwaresTable(token, nFirmwaresPerCall, pagToken);
    }
}

export default FirmwareListViewModel;
