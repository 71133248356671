import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'presentation/components/Logo';

const useStyles = makeStyles(() => ({
    root: {
        background: 'linear-gradient(to right, #ee303d, #ed174d, #f3742b, #ffca05);'
    },
    toolbar: {
        minHeight: 64
    }
}));

function TopBar({ className, ...rest }) {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
            <Toolbar className={classes.toolbar}>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string
};

export default TopBar;
