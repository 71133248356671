/**
 * Intervention Info View Model - Producer
 */
class InterventionInfoViewModel {
    constructor(interventionModel) {
        // owns intervention model
        this.interventionModel = interventionModel;
    }

    /**
     * Get intervention info
     * @param {*} token AWS token
     * @param {*} int Intervention UUID
     * @returns Intervention info
     */
    getInterventionInfo(token, int) {
        return this.interventionModel.getInterventionInfo(token, int);
    }

    /**
     * Get intervention images
     * @param {*} token AWS token
     * @param {*} int Intervention UUID
     * @returns Intervention images
     */
    getInterventionInfoImages(token, int) {
        return this.interventionModel.getInterventionInfoImages(token, int);
    }

    /**
     * Get intervention image
     * @param {*} token AWS token
     * @param {*} i Intervention URL
     * @returns Intervention image
     */
    getInterventionInfoImage(token, i) {
        return this.interventionModel.getInterventionInfoImage(token, i);
    }
}

export default InterventionInfoViewModel;
