import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
import { useAppContext } from 'utils/contextLib';
import config from 'utils/config.json';
import { getCurrentSession } from 'aws-auth-cardioid';
import { associateGatewayHost, getGateways, getGWAvailableCommands, sendGWCommand, setClientFromGateway } from 'actions/actions';
import GatewayListView from '../../views/gateway/GatewayListView';
// components
import VehicleColumn from '../../components/gateway/VehicleColumn';
import ClientColumn from '../../components/gateway/ClientColumn';
import CustomToolbarSelect from '../../views/gateway/GatewayListView/CustomToolbarSelect';
import decodeJwt from '../../../utils/jwt';
// utils

/**
 * Gateway List View Controller - View related logic
 * @returns Gateway List View
 */
function GatewayListController() {
    // initiate local variables
    const session = getCurrentSession();
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const nGatewaysPerCall = config.tables.TABLE_ROWS_PER_CALL;
    const [pagesSelected, setPagesSelected] = useState([]);
    // state variables
    const [gateways, setGateways] = useState([]);
    const [tablePage, setTablePage] = useState(0);
    const [rowsPerPageValue, setRowsPerPage] = useState(config.tables.TABLE_ROWS_PER_PAGE);
    const [tableMaxPage, setTableMaxPage] = useState(0);
    const [pagToken, setPagToken] = useState(null);
    const [isPagination, setIsPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const isAdmin = userInfo.role === 'Administrator';
    const [selectedRows, setSelectedRows] = useState([]);
    const [vehicleWidth, setVehicleWidth] = useState('0vw');
    const [clientWidth, setClientWidth] = useState('0vw');

    /**
     * Get gateways
     */
    const getGatewaysTable = async () => {
        const user = session.getIdToken().payload['cognito:username'];
        const {
            data: { Gateways, PaginationToken }
        } = await getGateways(user, nGatewaysPerCall, pagToken);
        if (Gateways) {
            const newGateways = [
                ...gateways,
                ...Gateways.map((gw) => {
                    gw.config = [gw.uuid, gw.version];
                    // change client and vehicle column size according to text
                    if (gw.client?.name && clientWidth === '0vw') setClientWidth('14vw');
                    if (gw.vehicle?.name && vehicleWidth === '0vw') setVehicleWidth('13vw');
                    return gw;
                })
            ];
            setGateways(newGateways);
            setPagToken(PaginationToken);
            if (PaginationToken !== -1 && Gateways.length === nGatewaysPerCall) {
                const decodeToken = decodeJwt(PaginationToken);
                if (decodeToken.offset + nGatewaysPerCall <= decodeToken.total) {
                    setTableMaxPage(decodeToken.offset);
                } else {
                    setTableMaxPage(decodeToken.total);
                }
            }
            // set pagination to true -> able to call next set of hosts
            setIsPagination(true);
        } else {
            enqueueSnackbar(`Server error`, { variant: 'error' });
            navigate('/login', { replace: true, state: location });
        }
        setLoading(false);
    };

    /**
     * Change rows per page option
     * @param {*} newRowsPerPage
     */
    const handleOnChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
    };

    /**
     * On change page - Next page and previous
     * @param {*} currentPage Current table page
     */
    const handleOnChangePage = (currentPage) => {
        if (currentPage > tablePage) {
            setTablePage((oldPage) => oldPage + 1);
            // only updates if there is still a token for next set of gws
            // and if last call finished
            if (pagToken !== -1 && isPagination) {
                getGatewaysTable().then();
                setLoading(true);
            }
            // reset is pagination to false for next call
            setIsPagination(false);
        }
    };

    /**
     * Handle on row selected
     * @param {*} currentRowsSelected Current rows selected
     * @param {*} allRowsSelected All rows selected
     * @param {*} rowsSelected Rows selected
     */
    const handleOnRowSelected = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        // on select
        if (rowsSelected.length > selectedRows.length) {
            setPagesSelected((prevPages) => [...prevPages, tablePage]);
        } else if (!currentRowsSelected.length) {
            setPagesSelected([]);
        } else {
            const index = selectedRows.indexOf(currentRowsSelected[0].dataIndex);
            pagesSelected.splice(index, 1);
            setPagesSelected(pagesSelected);
        }
        setSelectedRows(rowsSelected);
    };

    /**
     * On change gateways firmware target version
     */
    const onChangeFirmwareTarget = () => {
        const gatewaysSelected = [];
        const requests = selectedRows
            .map((row) => {
                const gw = {
                    uuid: gateways[row].uuid,
                    version: gateways[row].version
                };
                gatewaysSelected.push(gw);
                return gatewaysSelected;
            })
            .flat();
        const uniqueGateways = Array.from(new Set(requests));
        setSelectedRows([]);
        navigate('/app/gateways/updateFirmware', {
            replace: false,
            state: {
                gateways: uniqueGateways
            }
        });
    };

    /**
     * Get gateway available commands
     * @returns Gateway available commands
     */
    const getAvailableCommands = async () => {
        const { data } = await getGWAvailableCommands();
        if (data) {
            return data;
        }
        enqueueSnackbar(`Server error`, { variant: 'error' });
        navigate('/login', { replace: true, state: location });
        return null;
    };

    /**
     * Send management command to a specific or multiple gateways
     * @param {*} command Gateway command
     */
    const sendCommand = async (command) => {
        const requests = selectedRows.map(async (row) => {
            const gw = gateways[row].uuid;
            const { status } = await sendGWCommand(gw, command);
            if (status === 200) {
                enqueueSnackbar(`Command was successfully sent to gateway ${gw}`, { variant: 'success' });
            } else {
                enqueueSnackbar(`Server error`, { variant: 'error' });
                navigate('/login', { replace: true, state: location });
            }
        });
        Promise.all(requests).then(() => {
            setSelectedRows([]);
        });
    };

    /**
     * Custom toolbar
     * @returns Custom toolbar component
     */
    const handleCustomToolbarSelect = () => (
        <CustomToolbarSelect
            isAdmin={isAdmin}
            onChangeFirmwareTarget={onChangeFirmwareTarget}
            getAvailableCommands={getAvailableCommands}
            sendCommand={sendCommand}
        />
    );

    /**
     * Unset client that gateway belongs to
     * @param {*} tableMeta Table meta
     * @param {*} uuid Client uuid
     * @param {*} updateValue MUI Datatable function
     */
    const unsetClientGateway = async (tableMeta, uuid, updateValue) => {
        setClientFromGateway(tableMeta.rowData[1], uuid, false).then(({ status }) => {
            if (status === 200) {
                gateways[tableMeta.rowIndex].client = {
                    name: '',
                    uuid: ''
                };
                const newGateways = [...gateways];
                setGateways([]);
                setGateways(newGateways);
                updateValue({
                    name: '',
                    uuid: ''
                });
                enqueueSnackbar(`Gateway client was successfully unset`, { variant: 'success' });
            } else enqueueSnackbar(`Error unsetting client from gateway`, { variant: 'error' });
        });
    };

    /**
     * Dissociate gateway with host
     * @param {*} tableMeta Table meta
     * @param {*} uuid Vehicle uuid
     * @param {*} updateValue MUI Datatable function
     */
    const dissociateGatewayHost = async (tableMeta, uuid, updateValue) => {
        associateGatewayHost(tableMeta.rowData[1], uuid).then(({ status }) => {
            if (status === 200) {
                gateways[tableMeta.rowIndex].vehicle = {
                    name: '',
                    uuid: ''
                };
                const newGateways = [...gateways];
                setGateways([]);
                setGateways(newGateways);
                updateValue({
                    name: '',
                    uuid: ''
                });
                enqueueSnackbar(`Gateway was successfully dissociated with host`, { variant: 'success' });
            } else enqueueSnackbar(`Error dissociating gateway with host`, { variant: 'error' });
        });
    };

    /**
     * Vehicle column
     * @param {*} value Vehicle column value
     * @param {*} tableMeta Gateways table Meta data
     * @param {*} updateValue MUI Datatable funtion
     * @returns Vehicle column component
     */
    const vehicleColumn = (value, tableMeta, updateValue) => (
        <VehicleColumn value={value} tableMeta={tableMeta} updateValue={updateValue} dissociateGatewayHost={dissociateGatewayHost} />
    );

    /**
     * Client column
     * @param {*} value Client column value
     * @param {*} tableMeta Gateways table Meta data
     * @param {*} updateValue MUI Datatable funtion
     * @returns Client column component
     */
    const clientColumn = (value, tableMeta, updateValue) => (
        <ClientColumn value={value} tableMeta={tableMeta} updateValue={updateValue} unsetClientGateway={unsetClientGateway} />
    );

    /**
     * Get all data asynchronously
     */
    useEffect(() => {
        getGatewaysTable().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return view
    return (
        <GatewayListView
            gateways={gateways}
            rowsPerPageValue={rowsPerPageValue}
            tableMaxPage={tableMaxPage}
            loading={loading}
            isAdmin={isAdmin}
            selectedRows={selectedRows}
            vehicleColumnWidth={vehicleWidth}
            clientColumnWidth={clientWidth}
            handleOnChangeRowsPerPage={handleOnChangeRowsPerPage}
            handleOnChangePage={handleOnChangePage}
            handleOnRowSelected={handleOnRowSelected}
            handleCustomToolbarSelect={handleCustomToolbarSelect}
            vehicleColumn={vehicleColumn}
            clientColumn={clientColumn}
        />
    );
}

export default GatewayListController;
