import React from 'react';
import InterventionModel from '../../dataaccess/models/intervention';
import InterventionInfoViewModel from '../../presentation/viewModels/intervention/InterventionInfoViewModel';
import InterventionInfoController from '../../presentation/controllers/intervention/InterventionInfoController';

/**
 * Intervention Info Provider - Connects all de MVVM logic together
 * @returns Intervention Info Controller
 */
function InterventionInfoProvider() {
    // instantiate intervention info view model with intervention model
    const interventionInfoViewModel = new InterventionInfoViewModel(new InterventionModel());

    return <InterventionInfoController viewModel={interventionInfoViewModel} />;
}

export default InterventionInfoProvider;
