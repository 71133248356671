import React from 'react';
import TicketModel from '../../dataaccess/models/ticket';
import TicketViewModel from '../../presentation/viewModels/TicketViewModel';
import TicketController from '../../presentation/controllers/TicketController';

/**
 * Ticket Provider - Connects all de MVVM logic together
 * @returns Ticket Controller
 */
function TicketProvider() {
    // instantiate ticket view model with ticket model
    const ticketViewModel = new TicketViewModel(new TicketModel());

    return <TicketController viewModel={ticketViewModel} />;
}

export default TicketProvider;
