import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useAppContext } from 'utils/contextLib';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { ChangeHistory, Delete, HowToReg, Send } from '@mui/icons-material';
import { deletePassword } from '../../../../actions/actions';

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: '24px'
    },
    inverseIcon: {
        transform: 'rotate(90deg)'
    },
    snackbar: {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
        }
    }
};

function handleErrors(errorName, email) {
    if (errorName === 'UnsupportedUserStateException') return [`${email} is already confirmed.`, 'warning'];
    if (errorName === 'NotAuthorizedException') return [`${email} password cannot be reset in the current state.`, 'warning'];
    return [null, 'error'];
}

function CustomToolbarSelect(props) {
    const { userInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const { classes } = props;

    // const handleClickInverseSelection = () => {
    //     const nextSelectedRows = props.displayData.reduce((nextSelectedRows, _, index) => {
    //         if (!props.selectedRows.data.find((selectedRow) => selectedRow.index === index)) {
    //             nextSelectedRows.push(index);
    //         }
    //         return nextSelectedRows;
    //     }, []);
    //
    //     props.setSelectedRows(nextSelectedRows);
    // };

    const handleClickDisableUser = () => {
        const rowsSelected = props.selectedRows.data.map((row) => row.index);
        props.onDeleteRows(rowsSelected, props.displayData);
    };

    const handleClickEnableUser = () => {
        const rowsSelected = props.selectedRows.data.map((row) => row.index);
        props.onEnable(rowsSelected, props.displayData);
    };

    // const handleClickResendTemporaryPass = () => {
    //     props.selectedRows.data.map(async (row) => {
    //         const name = props.displayData[row.index].data[0];
    //         const email = props.displayData[row.index].data[1];
    //         const role = props.displayData[row.index].data[2];
    //         createUser(name, email, role)
    //             .then(() => {
    //                 enqueueSnackbar(`An email was sent to ${email}`, { variant: 'success' });
    //             })
    //             .catch(() => {
    //                 const errorMessage = handleErrors(null, email);
    //                 enqueueSnackbar(errorMessage[0], { variant: errorMessage[1] });
    //             });
    //     });
    // };

    const handleClickResetPass = () => {
        props.selectedRows.data.map(async (row) => {
            const uuid = props.displayData[row.index].data[5];
            const email = props.displayData[row.index].data[1];
            const enabled = props.displayData[row.index].data[4].props.label === 'Enabled';
            if (enabled) {
                deletePassword(uuid)
                    .then(() => {
                        enqueueSnackbar(`An email was sent to ${email}`, { variant: 'success' });
                    })
                    .catch(() => {
                        const errorMessage = handleErrors(null, email);
                        enqueueSnackbar(errorMessage[0], { variant: errorMessage[1] });
                    });
            } else enqueueSnackbar(`User needs to be enabled to send password!`, { variant: 'warning' });
        });
    };

    const handleClickChangeRole = (event) => {
        const rowsSelected = props.selectedRows.data.map((row) => row.index);
        props.onChangeRole(rowsSelected, props.displayData, event);
    };

    return (
        <div className={classes.iconContainer}>
            {userInfo.role === 'Administrator' && (
                <>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Tooltip title="Change Role">
                                    <IconButton className={classes.iconButton} {...bindTrigger(popupState)}>
                                        <ChangeHistory className={classes.icon} />
                                    </IconButton>
                                </Tooltip>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem
                                        onClick={(event) => {
                                            handleClickChangeRole(event);
                                            popupState.close();
                                        }}
                                    >
                                        Administrator
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(event) => {
                                            handleClickChangeRole(event);
                                            popupState.close();
                                        }}
                                    >
                                        Installer
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(event) => {
                                            handleClickChangeRole(event);
                                            popupState.close();
                                        }}
                                    >
                                        User
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>
                    <Tooltip title="Resend/Reset Password">
                        <IconButton className={classes.iconButton} onClick={handleClickResetPass}>
                            <Send className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Enable User">
                        <IconButton className={classes.iconButton} onClick={handleClickEnableUser}>
                            <HowToReg className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Disable User">
                        <IconButton className={classes.iconButton} onClick={handleClickDisableUser}>
                            <Delete className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    );
}

CustomToolbarSelect.propTypes = {
    selectedRows: PropTypes.object,
    setSelectedRows: PropTypes.func,
    displayData: PropTypes.array,
    onDeleteRows: PropTypes.func,
    onChangeRole: PropTypes.func,
    onEnable: PropTypes.func,
    pagesSelected: PropTypes.array,
    rowsPerPage: PropTypes.number,
    classes: PropTypes.object
};

export default withStyles(defaultToolbarSelectStyles, { name: 'CustomToolbarSelect' })(CustomToolbarSelect);
