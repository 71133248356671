// actions
import { getWearables, addWearable, setClientFromWearable } from 'actions/actions';

/**
 * Wearable Model - Wearable data source
 */
class WearableModel {
    /**
     * Get wearables
     * @param {*} token AWS token
     * @param {*} user User UUID
     * @param {*} nWearablesPerCall Number of clients per call
     * @param {*} pagToken Pagination token
     * @returns Wearable table data
     */
    async getWearablesTable(token, user, nWearablesPerCall, pagToken) {
        return getWearables(token, user, nWearablesPerCall, pagToken);
    }

    /**
     * Add new wearable
     * @param {*} user User UUID
     * @param {*} mac Wearable Mac Address
     * @param {*} hardware Wearable Hardware Name
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async addNewWearable(user, mac, hardware) {
        return addWearable(user, mac, hardware);
    }

    /**
     * Unset the client a wearable belongs to
     * @param {*} wearable Wearable UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async unsetClientWearable(wearable, owner) {
        return setClientFromWearable(wearable, owner, false);
    }

    /**
     * Set the client a wearable belongs to
     * @param {*} wearable Wearable UUID
     * @param {*} owner Owner UUID
     * @returns HTTP Response || 0 if token expired || null if fail
     */
    async setClientWearable(wearable, owner) {
        return setClientFromWearable(wearable, owner);
    }
}

export default WearableModel;
