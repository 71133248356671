import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Container, TextField, Typography } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function AddCardioWheelView({ onSubmit }) {
    const classes = useStyles();

    return (
        <Page className={classes.root} title="Add Wearable">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            mac: '',
                            hardware: '0.0.0',
                            firmware: '0.0.0'
                        }}
                        validationSchema={Yup.object().shape({
                            mac: Yup.string()
                                .max(17, 'Mac Address must be exactly 17 digits')
                                .min(17, 'Mac Address must be exactly 17 digits')
                                .matches(
                                    /^([0-9a-z]{2}[:]){5}([0-9a-z]{2})$/,
                                    'Mac Address must be in the following format xx:xx:xx:xx:xx:xx with small letters or numbers'
                                )
                                .required('Mac Adress is required'),
                            hardware: Yup.string().required('Hardware is required'),
                            firmware: Yup.string().required('Firmware is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Add a new CardioWheel
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.mac && errors.mac)}
                                    fullWidth
                                    helperText={touched.mac && errors.mac}
                                    label="Mac Address"
                                    margin="normal"
                                    name="mac"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.mac}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.hardware && errors.hardware)}
                                    fullWidth
                                    helperText={touched.hardware && errors.hardware}
                                    label="Hardware"
                                    margin="normal"
                                    name="hardware"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.hardware}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.firmware && errors.firmware)}
                                    fullWidth
                                    helperText={touched.firmware && errors.firmware}
                                    label="Firmware"
                                    margin="normal"
                                    name="firmware"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.firmware}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Add CardioWheel
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

AddCardioWheelView.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default AddCardioWheelView;
