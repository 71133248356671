import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
// material
import { Box, Button, Container, TextField, Typography, Autocomplete } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function AddWearableView(props) {
    const classes = useStyles();

    // get props
    const { hardware, onSubmit } = props;

    return (
        <Page className={classes.root} title="Add Wearable">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            Mac: '',
                            Hardware: ''
                        }}
                        validationSchema={Yup.object().shape({
                            Mac: Yup.string()
                                .max(17, 'Mac Address must be exactly 17 digits')
                                .min(17, 'Mac Address must be exactly 17 digits')
                                .matches(
                                    /^([0-9a-f]{2}[:]){5}([0-9a-f]{2})$/,
                                    'Mac Address must be in the following format xx:xx:xx:xx:xx:xx with small letters or numbers'
                                )
                                .required('Mac Adress is required'),
                            Hardware: Yup.string()
                                .oneOf(hardware.map((h) => h))
                                .required('Hardware Name is required')
                        })}
                        onSubmit={onSubmit}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Add a new Wearable
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.Mac && errors.Mac)}
                                    fullWidth
                                    helperText={touched.Mac && errors.Mac}
                                    label="Mac Address"
                                    margin="normal"
                                    name="Mac"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values.Mac}
                                    variant="outlined"
                                />
                                <Autocomplete
                                    id="hardware"
                                    options={hardware}
                                    autoHighlight
                                    getOptionLabel={(option) => option}
                                    style={{ width: 550 }}
                                    onOpen={handleBlur}
                                    onChange={(e, value) => {
                                        setFieldValue('Hardware', value || '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(touched.Hardware && errors.Hardware)}
                                            fullWidth
                                            helperText={touched.Hardware && errors.Hardware}
                                            label="Hardware Name"
                                            margin="normal"
                                            name="Hardware"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Hardware}
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Add Wearable
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
}

AddWearableView.propTypes = {
    // controller state variables
    hardware: PropTypes.array.isRequired,
    // controller functions
    onSubmit: PropTypes.func.isRequired
};

export default AddWearableView;
