import React from 'react';
import ClientModel from '../../dataaccess/models/client';
import AddClientViewModel from '../../presentation/viewModels/client/AddClientViewModel';
import AddClientController from '../../presentation/controllers/client/AddClientController';

/**
 * Add Client Provider - Connects all de MVVM logic together
 * @returns Add Client Controller
 */
function AddClientProvider() {
    // instantiate add client view model with client model
    const addClientViewModel = new AddClientViewModel(new ClientModel());

    return <AddClientController viewModel={addClientViewModel} />;
}

export default AddClientProvider;
