import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable, { debounceSearchRender } from 'mui-datatables';
// material
import { Box, Container, CircularProgress, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// components
import Page from 'presentation/components/Page';
// local
import { theme } from 'presentation/theme';
import { makeStyles } from '@mui/styles';
import { Add } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function CardioWheelListView(props) {
    const classes = useStyles();
    // get props
    const {
        cardioWheel,
        rowsPerPageValue,
        tableMaxPage,
        loading,
        isAdmin,
        selectedRows,
        vehicleColumnWidth,
        clientColumnWidth,
        handleOnChangeRowsPerPage,
        handleOnChangePage,
        handleOnRowSelected,
        handleCustomToolbarSelect,
        gatewayColumn,
        clientColumn,
        handleClick,
        handleOnSearch
    } = props;

    const columnsSize = isAdmin
        ? {
              '&:nth-child(2)': {
                  width: '6vw'
              },
              '&:nth-child(3)': {
                  width: '13vw'
              },
              '&:nth-child(4)': {
                  width: clientColumnWidth
              },
              '&:nth-child(5)': {
                  width: vehicleColumnWidth
              },
              '&:nth-child(6)': {
                  width: '5vw'
              }
          }
        : {
              '&:nth-child(1)': {
                  width: '6vw'
              },
              '&:nth-child(2)': {
                  width: '13vw'
              },
              '&:nth-child(3)': {
                  width: clientColumnWidth
              },
              '&:nth-child(4)': {
                  width: vehicleColumnWidth
              },
              '&:nth-child(5)': {
                  width: '5vw'
              }
          };

    // table columns and rows size
    const tableTheme = createTheme(
        {
            overrides: {
                MUIDataTableHeadCell: {
                    root: columnsSize
                },
                MUIDataTableBodyRow: {
                    root: {
                        height: 121
                    }
                }
            }
        },
        theme
    );

    // table options https://github.com/gregnb/mui-datatables#usage
    const options = {
        serverSide: true,
        filterType: 'checkbox',
        rowsPerPage: rowsPerPageValue,
        rowsPerPageOptions: [5, 10, 15],
        responsive: 'simple', // responsive type
        resizableColumns: false,
        selectableRows: 'none',
        // selectableRowsHeader: isAdmin,
        selectableRowsOnClick: true,
        rowsSelected: selectedRows,
        print: false,
        download: false,
        jumpToPage: true,
        count: tableMaxPage,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        },
        customSearchRender: debounceSearchRender(400),
        onChangeRowsPerPage: handleOnChangeRowsPerPage,
        onChangePage: handleOnChangePage,
        onRowSelectionChange: handleOnRowSelected,
        customToolbarSelect: handleCustomToolbarSelect,
        onSearchChange: handleOnSearch
        // isRowSelectable: () => { return isAdmin },
        // customSearch: (searchQuery, currentRow) => {
        //     let isFound = false;
        //     currentRow.forEach((col) => {
        //         if (typeof col === 'object' && !Array.isArray(col) && col !== null) {
        //             if (col?.uuid.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
        //                 isFound = true;
        //             }
        //         } else if (col?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
        //             isFound = true;
        //         }
        //     });
        //     return isFound;
        // }
    };

    // table columns
    const columns = [
        {
            name: 'uuid',
            label: 'UUID',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'mac',
            label: 'MAC',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'owner',
            label: 'Client',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: clientColumn
            }
        },
        {
            name: 'gateway',
            label: 'Gateway',
            options: {
                filter: false,
                sort: false,
                viewColumns: false,
                customBodyRender: gatewayColumn
            }
        },
        {
            name: 'firmware',
            label: 'Firmware',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'hardware',
            label: 'Hardware',
            options: {
                filter: false,
                sort: false
            }
        }
    ];

    return (
        <Page className={classes.root} title="Gateways">
            <Container maxWidth={false}>
                <div>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography color="textPrimary" variant="h2">
                                CardioWheel
                            </Typography>
                        </Box>
                        <Box>
                            {isAdmin && (
                                <Button color="primary" variant="contained" onClick={handleClick} startIcon={<Add />}>
                                    Add CardioWheel
                                </Button>
                            )}
                        </Box>
                    </Box>
                </div>
                <Box mt={3}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable data={cardioWheel} columns={columns} options={options} />
                    </ThemeProvider>
                </Box>
            </Container>
        </Page>
    );
}

CardioWheelListView.propTypes = {
    // controller state variables
    cardioWheel: PropTypes.array.isRequired,
    rowsPerPageValue: PropTypes.number.isRequired,
    tableMaxPage: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selectedRows: PropTypes.array.isRequired,
    vehicleColumnWidth: PropTypes.string.isRequired,
    clientColumnWidth: PropTypes.string.isRequired,
    // controller functions
    handleOnChangeRowsPerPage: PropTypes.func.isRequired,
    handleOnChangePage: PropTypes.func.isRequired,
    handleOnSearch: PropTypes.func.isRequired,
    handleOnRowSelected: PropTypes.func.isRequired,
    handleCustomToolbarSelect: PropTypes.func.isRequired,
    gatewayColumn: PropTypes.func.isRequired,
    clientColumn: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired
};

export default CardioWheelListView;
