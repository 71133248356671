import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// view
import { useAppContext } from 'utils/contextLib';
import { changePassword, getCurrentSession, getCurrentUser } from 'aws-auth-cardioid';
import AccountView from '../views/account/AccountView';
import { updatePicture, updateUser } from '../../actions/actions';
// utils

/**
 * Account View Controller - View related logic
 * @returns Account View
 */
function AccountController() {
    // initiate local variables
    const user = getCurrentUser();
    const session = getCurrentSession();
    const { userInfo, setUserInfo } = useAppContext();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // state variables
    const [open, setOpen] = useState(false);

    /**
     * Update profile picture
     * @param {*} file Picture
     */
    const updateProfilePicture = async (file) => {
        const formData = new FormData();
        formData.append('picture', file);
        const uuid = session.getIdToken().payload['cognito:username'];
        const picture = await updatePicture(formData, uuid);
        if (picture) {
            setUserInfo((prevInfo) => ({
                ...prevInfo,
                picture
            }));
        } else if (picture === 0) {
            enqueueSnackbar(`Authentication Token has expired`, { variant: 'info' });
            navigate('/login');
        } else enqueueSnackbar('Error uploading profile picture', { variant: 'error' });
        setUserInfo((prevInfo) => ({
            ...prevInfo,
            loading: false
        }));
    };

    /**
     * Update profile details
     * @param {*} formData Form fields data
     * @param {*} actions Field actions
     */
    const updateProfileDetails = async (formData, actions) => {
        const { name, timezone } = formData;
        const resp = await updateUser(name, timezone);
        if (resp) {
            const tz = timezone.zone ? timezone.zone : timezone;
            actions.setFieldValue('name', name);
            actions.setFieldValue('timezone', tz || userInfo.timezone);
            setUserInfo((prevInfo) => ({
                ...prevInfo,
                name,
                timezone: tz !== '' ? tz : moment.tz.guess()
            }));
            enqueueSnackbar(`Details were updated`, { variant: 'success' });
        } else if (resp === 0) {
            enqueueSnackbar(`Authentication Token has expired`, { variant: 'info' });
            navigate('/login');
        } else actions.setFieldError('apiError', 'Server Error: Values were not updated.');
    };

    /**
     * Change password
     * @param {*} formData Form fields data
     * @param {*} actions Field actions
     */
    const updatePassword = (formData, actions) => {
        const { previousPassword, newPassword } = formData;
        changePassword(user, previousPassword, newPassword)
            .then(() => {
                enqueueSnackbar(`Password was successfully changed`, { variant: 'success' });
                actions.resetForm();
            })
            .catch((err) => {
                actions.setFieldError('apiError', err.message);
            });
    };

    /**
     * Click change picture button
     */
    const openImage = () => {
        setOpen(true);
    };

    /**
     * Close change picture button
     */
    const closeImage = () => {
        setOpen(false);
    };

    /**
     * Uppdate picture complete process
     * @param {*} files Pictures
     */
    const onSavePicture = (files) => {
        updateProfilePicture(files[0]).then();
        setOpen(false);
        setUserInfo({
            ...userInfo,
            loading: true
        });
    };

    // return view
    return (
        <AccountView
            // profile props
            userInfo={userInfo}
            open={open}
            openImage={openImage}
            closeImage={closeImage}
            onSavePicture={onSavePicture}
            // profile details props
            updateProfileDetails={updateProfileDetails}
            changePassword={updatePassword}
        />
    );
}

export default AccountController;
