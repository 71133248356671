import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid, Typography, CircularProgress } from '@mui/material';
import ChipsArray from 'utils/chipsArray';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
});

function Storage(props) {
    const classes = useStyles();

    return (
        <Grid classes={classes.item} item md={4} sm={6} xs={12}>
            <Typography color="textPrimary" gutterBottom variant="h6">
                Storage
            </Typography>
            {props.loading ? (
                <CircularProgress />
            ) : (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.values.storageEnable}
                                onChange={() => props.setFieldValue('storageEnable', !props.values.storageEnable)}
                            />
                        }
                        label="Enable"
                    />
                    <ChipsArray title="Data types" chipData={props.values.storageDtype} onDelete={undefined} size="small" />
                </>
            )}
        </Grid>
    );
}

Storage.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    loading: PropTypes.bool
};

export default Storage;
