import React, { useState } from 'react';
import {
    Button,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import PropTypes from 'prop-types';

function ClientColumn(props) {
    // open dialog
    const [open, setOpen] = useState(false);

    // get props
    const { value, tableMeta, updateValue, unsetClientGateway } = props;

    return (
        <Box>
            {!value.name ? (
                <Button
                    color="primary"
                    href={`gateways/${tableMeta.rowData[1]}/setClient`}
                    disabled={false}
                    variant="contained"
                    size="small"
                >
                    Set Client
                </Button>
            ) : (
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{value.name}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpen(true)}>
                            <Cancel />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" component="div">
                                <Typography variant="h4">Unset Client</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {tableMeta.rowData[3].name === ''
                                        ? 'Are you sure you want to unset the client from gateway?'
                                        : 'Please unset vehicle first.'}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)} color="primary">
                                    {tableMeta.rowData[3].name === '' ? 'No' : 'Close'}
                                </Button>
                                {tableMeta.rowData[3].name === '' && (
                                    <Button
                                        onClick={async () => {
                                            unsetClientGateway(tableMeta, value.uuid, updateValue).then(() => {
                                                setOpen(false);
                                            });
                                        }}
                                        color="primary"
                                        autoFocus
                                    >
                                        Yes
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}

ClientColumn.propTypes = {
    value: PropTypes.object.isRequired,
    tableMeta: PropTypes.object.isRequired,
    updateValue: PropTypes.func,
    unsetClientGateway: PropTypes.func.isRequired
};

export default ClientColumn;
