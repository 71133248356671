import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Typography, Hidden, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from 'presentation/components/Logo';
import { Menu } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        background: 'linear-gradient(to right, #ee303d, #ed174d, #f3742b, #ffca05);'
    },
    avatar: {
        width: 60,
        height: 60
    },
    font: {
        fontFamily: `"Poppins", sans-serif`
    }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box flexGrow={1} />

                <Hidden mdDown>
                    <Typography variant="h4">MYFLEET BACK-OFFICE</Typography>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen}>
                        <Menu open={false} />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
