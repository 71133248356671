import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
// view
// utils
import config from 'utils/config.json';
import AddWearableView from '../../views/wearable/AddWearableView';
import { addWearable } from '../../../actions/actions';

/**
 * Add Wearable View Controller - View related logic
 * @returns Add Wearable View
 */
function AddWearableController() {
    // initiate local variables
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    // state variables
    const { hardware } = config.wearable;

    /**
     * Add new wearable
     * @param {*} values Form fields
     */
    const onSubmit = async (values) => {
        const { Mac, Hardware } = values;
        const result = await addWearable(Mac, Hardware);
        if (result === 0) {
            enqueueSnackbar(`Authentication Token has expired`, { variant: 'info' });
            navigate('/login', { replace: true, state: location });
        } else if (result?.status === 200) {
            enqueueSnackbar(`Wearable was successfully added`, { variant: 'success' });
            navigate(location.state || '/', { replace: true });
        } else {
            enqueueSnackbar(`Error adding new wearable`, { variant: 'error' });
            navigate(location.state || '/', { replace: true });
        }
    };

    // return view
    return <AddWearableView hardware={hardware} onSubmit={onSubmit} />;
}

AddWearableController.propTypes = {
    // viewModel: PropTypes.object.isRequired
};

export default AddWearableController;
