import React from 'react';
import { Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from 'presentation/layouts/DashboardLayout';
import MainLayout from 'presentation/layouts/MainLayout';
// import AccountView from 'presentation/views/account/AccountView';
import UserListView from 'presentation/views/user/UserListView';
// import GatewayListView from 'presentation/views/gateway/GatewayListView';
// import ClientListView from 'presentation/views/client/ClientListView';
// import AddClientView from 'presentation/views/client/AddClientView';
// import InterventionsListView from 'presentation/views/interventions/InterventionsListView';
// import InterventionsInfoView from 'presentation/views/intervention/InterventionInfoView';
// import TicketView from 'presentation/views/support/TicketView';
import AssociateGatewayVehicleView from 'presentation/views/associate/AssociateGatewayVehicleView';
import AssociateCardioWheelVehicleView from 'presentation/views/associate/AssociateCardioWheelVehicleView';
import LoginView from 'presentation/views/auth/LoginView';
import NotFoundView from 'presentation/views/errors/NotFoundView';
import RegisterView from 'presentation/views/auth/RegisterView';
import GatewayConfigView from 'presentation/views/settings/GatewayConfigView';
import ForgotPasswordView from 'presentation/views/auth/ForgotPasswordView';
import ForgotPasswordSubmitView from 'presentation/views/auth/ForgotPasswordSubmitView';
import LimitUserGateways from 'presentation/views/user/LimitUserGateways';
import PrivateRoute from 'utils/PrivateRoute';
// providers
import { RequireAuth } from 'aws-auth-cardioid';
import DashboardProvider from './domain/providers/DashboardProvider';
import GatewayListProvider from './domain/providers/GatewayListProvider';
import ClientListProvider from './domain/providers/ClientListProvider';
import AddClientProvider from './domain/providers/AddClientProvider';
import InterventionListProvider from './domain/providers/InterventionListProvider';
import InterventionInfoProvider from './domain/providers/InterventionInfoProvider';
import TicketProvider from './domain/providers/TicketProvider';
import AccountProvider from './domain/providers/AccountProvider';
import GatewayClientProvider from './domain/providers/GatewayClientProvider';
import WearableListProvider from './domain/providers/WearableListProvider';
import AddWearableProvider from './domain/providers/AddWearableProvider';
import WearableClientProvider from './domain/providers/WearableClientProvider';
import FirmwareListProvider from './domain/providers/FirmwareListProvider';
import UploadFirmwareProvider from './domain/providers/UploadFirmwareProvider';
import GatewayFirmwareProvider from './domain/providers/GatewayFirmwareProvider';
import CardioWheelListProvider from './domain/providers/CardioWheelListProvider';
import CardioWheelClientProvider from './domain/providers/CardioWheelClientProvider';
import AddCardioWheelProvider from './domain/providers/AddCardioWheelProvider';

function NavigateLogin() {
    return <Navigate to="/login" replace />;
}

function AuthRouteLayout() {
    return (
        <RequireAuth action={NavigateLogin}>
            <DashboardLayout />
        </RequireAuth>
    );
}

const routes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: 'login', element: <LoginView /> },
            { path: 'register', element: <PrivateRoute path="register" component={RegisterView} redirectTo="/" /> },
            { path: 'forgotPassword', element: <ForgotPasswordView /> },
            { path: 'resetPassword', element: <ForgotPasswordSubmitView /> },
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/app/dashboard" /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    },
    {
        path: 'app',
        element: <AuthRouteLayout />,
        children: [
            { path: 'account', element: <AccountProvider /> },
            {
                path: 'users',
                element: <PrivateRoute path="app/users" component={UserListView} redirectTo="/login" />
            },
            { path: 'dashboard', element: <PrivateRoute path="app/dashboard" component={DashboardProvider} redirectTo="/app/gateways" /> },
            { path: 'config/:gw', element: <GatewayConfigView /> },
            { path: 'gateways', element: <GatewayListProvider /> },
            {
                path: 'gateways/:uuid/setClient',
                element: <PrivateRoute path="gateways/:uuid/setClient" component={GatewayClientProvider} redirectTo="/" />
            },
            {
                path: 'gateways/updateFirmware',
                element: <PrivateRoute path="gateways/updateFirmware" component={GatewayFirmwareProvider} redirectTo="/" />
            },
            { path: 'clients', element: <ClientListProvider /> },
            {
                path: 'clients/addClient',
                element: <PrivateRoute path="clients/addClient" component={AddClientProvider} redirectTo="/" />
            },
            { path: 'wearables', element: <WearableListProvider /> },
            {
                path: 'wearables/addWearable',
                element: <PrivateRoute path="wearables/addWearable" component={AddWearableProvider} redirectTo="/" />
            },
            {
                path: 'wearable/:uuid/setClient',
                element: <PrivateRoute path="wearables/setClient" component={WearableClientProvider} redirectTo="/" />
            },
            {
                path: 'firmwares',
                element: <FirmwareListProvider />
            },
            {
                path: 'firmwares/upload',
                element: <PrivateRoute path="firmwares/upload" component={UploadFirmwareProvider} redirectTo="/" />
            },
            {
                path: 'cardioWheel',
                element: <PrivateRoute path="cardioWheel" component={CardioWheelListProvider} redirectTo="/" />
            },
            {
                path: 'cardioWheel/:uuid/setClient',
                element: <PrivateRoute path="cardioWheel/:uuid/setClient" component={CardioWheelClientProvider} redirectTo="/" />
            },
            {
                path: 'cardioWheel/addCardioWheel',
                element: <PrivateRoute path="wearables/addCardioWheel" component={AddCardioWheelProvider} redirectTo="/" />
            },
            { path: 'interventions', element: <InterventionListProvider /> },
            { path: 'interventions/:int', element: <InterventionInfoProvider /> },
            { path: 'restrictGateways/:uuid', element: <LimitUserGateways /> },
            { path: 'support', element: <TicketProvider /> },
            { path: 'associate/:uuid', element: <AssociateGatewayVehicleView /> },
            { path: 'associateCardioWheel/:uuid', element: <AssociateCardioWheelVehicleView /> },
            { path: '*', element: <Navigate to="/404" replace /> }
        ]
    }
];

export default routes;
