import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
// material
import { Box, Container, Grid, Typography, CircularProgress } from '@mui/material';
// components
import Page from 'presentation/components/Page';
import { makeStyles } from '@mui/styles';
import GatewaysStock from '../../components/dashboard/GatewaysStock';
import GatewaysInstalled from '../../components/dashboard/GatewaysInstalled';
import GatewaysDelivered from '../../components/dashboard/GatewaysDelivered';
import columns from './columns';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function DashboardView({ gateways, topClients, loading }) {
    const classes = useStyles();

    // table options
    const options = {
        filterType: 'checkbox',
        responsive: 'simple', // responsive type
        resizableColumns: false,
        pagination: false,
        selectableRows: 'none',
        print: false,
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: loading ? <CircularProgress /> : 'Sorry, there is no matching data to display'
            }
        }
    };

    return (
        <Page className={classes.root} title="Dashboard">
            <Container maxWidth={false}>
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h2">
                        Dashboard
                    </Typography>
                </Box>

                <Box mt={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Typography color="textPrimary" variant="h3">
                                Top Clients
                            </Typography>
                            <Box mt={3}>
                                <MUIDataTable data={topClients} columns={columns} options={options} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Typography color="textPrimary" variant="h3">
                                Gateways
                            </Typography>
                            <Box mt={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <GatewaysStock value={gateways.stock} loading={loading} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GatewaysDelivered value={gateways.delivered} loading={loading} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GatewaysInstalled value={gateways.installed} loading={loading} />
                                    </Grid>
                                    {/* Activate when query ready */}
                                    {/* <Grid
                    item
                    xs={12}
                  >
                    <GatewaysOnline value={gateways.online} loading={loading} />
                  </Grid> */}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

DashboardView.propTypes = {
    // controller state variables
    gateways: PropTypes.object.isRequired,
    topClients: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default DashboardView;
