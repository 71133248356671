import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

/**
 * cardio colors:
 * #ee303d
 * #ed174d
 * #f3742b
 * #ffca05
 */

export const theme = createTheme({
    palette: {
        background: {
            dark: '#FFFAEB',
            default: colors.common.grey,
            paper: '#FFFAEB'
        },
        primary: {
            main: '#d65e0e'
        },
        secondary: {
            main: '#d65e0e'
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: '#273943'
        }
    },
    overrides: {},
    shadows,
    typography
});

theme.overrides.MuiDropzoneSnackbar = {
    errorAlert: {
        backgroundColor: 'error',
        color: '#000'
    },
    successAlert: {
        backgroundColor: '#FAA',
        color: '#000'
    }
};

export default {};
